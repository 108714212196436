import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "live_seat_plan_up_to_date",
        "live_seat_plan_up_to_date_improvement",
        "constant_communication",
        "constant_communication_improvement",
        "event_day_matters",
        "guest_feedback",
        "event_day_process_improvement"
    ];

    const [liveSeatPlanUpToDateImprovement, setLiveSeatPlanUpToDateImprovement] = useState({ required: false });
    const [constantCommunicationImprovement, setConstantCommunicationImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "live_seat_plan_up_to_date": (value) => setLiveSeatPlanUpToDateImprovement(value),
                "constant_communication": (value) => setConstantCommunicationImprovement(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* live_seat_plan_up_to_date */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is the live seat plan always kept up to date with any changes received from the attendees?&nbps;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_seat_plan_up_to_date_yes"
                            type='radio'
                            {...register("live_seat_plan_up_to_date", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_seat_plan_up_to_date_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_seat_plan_up_to_date_no"
                            type='radio'
                            {...register("live_seat_plan_up_to_date", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_seat_plan_up_to_date_no">
                            No 
                        </Label>
                    </p>
                    {errors.live_seat_plan_up_to_date ? <p role="alert" className="invalid-feedback mb-0">{errors.live_seat_plan_up_to_date.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {liveSeatPlanUpToDateImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("live_seat_plan_up_to_date_improvement", {
                            ...liveSeatPlanUpToDateImprovement
                        })}
                        className={`form-control ${errors.live_seat_plan_up_to_date_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.live_seat_plan_up_to_date_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.live_seat_plan_up_to_date_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* constant_communication */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was there constant communication with the live event team for updates received from the attendees?&nbps;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="constant_communication_yes"
                            type='radio'
                            {...register("constant_communication", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="constant_communication_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="constant_communication_no"
                            type='radio'
                            {...register("constant_communication", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="constant_communication_no">
                            No 
                        </Label>
                    </p>
                    {errors.constant_communication ? <p role="alert" className="invalid-feedback mb-0">{errors.constant_communication.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {constantCommunicationImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("constant_communication_improvement", {
                            ...constantCommunicationImprovement
                        })}
                        className={`form-control ${errors.constant_communication_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.constant_communication_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.constant_communication_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* event_day_matters */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered during the event day? &nbsp;
                    </Label>
                    <textarea
                        {...register("event_day_matters", {
                            required: false
                        })}
                        className={`form-control ${errors.event_day_matters ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_day_matters ? <p role="alert" className="invalid-feedback mb-0">{errors.event_day_matters.message}</p> : null}
                </CardBody>
            </Card>

            {/* guest_feedback */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is there any feedback that you received from the guests while you are at the venue?&nbsp;
                    </Label>
                    <textarea
                        {...register("guest_feedback", {
                            required: false
                        })}
                        className={`form-control ${errors.guest_feedback ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.guest_feedback ? <p role="alert" className="invalid-feedback mb-0">{errors.guest_feedback.message}</p> : null}
                </CardBody>
            </Card>

            {/* event_day_process_improvement */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes during the event day?&nbsp;
                    </Label>
                    <textarea
                        {...register("event_day_process_improvement", {
                            required: false
                        })}
                        className={`form-control ${errors.event_day_process_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_day_process_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.event_day_process_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;