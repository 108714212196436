import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'utils/propTypes';
import {
    Card,
    CardBody,
    Col,
  } from 'reactstrap';
import { FaCheckCircle } from 'react-icons/fa';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import Placeholder from "assets/img/logo/placeholder.png";

const AwardsCard = ({ awardsData, props, ...restProps }) => {
    let history = useHistory();

    return (
        <>
            {awardsData.map(({ id, name, start, joined, support_email, status, category_count, logo, event }, index) => (
                <Col lg={4} key={index}>
                    <a href={`/award/details?award_id=${id}`} className="text-secondary text-decoration-none">
                        <Card className="mb-4">
                            <CardBody className="awards">
                                <div className="text-center">
                                    <div className="check-icon text-right">
                                        {joined ?
                                            <FaCheckCircle type="radio" size="22px"/>
                                            :
                                            <FaCheckCircle type="radio" className="not-visible" size="22px"/>
                                        }
                                    </div>
                                    <div className="awards-container">
                                        {logo ?
                                            <img src={logo} className="awards-img" alt={name} />
                                            :
                                            <img src={Placeholder} className="awards-img" alt={name} />
                                        }
                                    </div>
                                    <h3 className="title-award">{name}</h3>
                                    <div className="awards-booking">
                                        <div className="categories">
                                            <p>Categories: {category_count}</p>
                                        </div>
                                        <div className="award-status text-right">
                                            <p>{status}</p>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </a>
                </Col>
            ))}

        </>
    );
};

AwardsCard.propTypes = {
  headers: PropTypes.node,
  awardsData: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      name: PropTypes.string,
      date: PropTypes.date,
    })
  ),
};

AwardsCard.defaultProps = {
  headers: [],
  awardsData: [],
};

export default AwardsCard;
