import React, { useEffect, useState, createRef } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import EmptyPage from 'components/EmptyPage';
import Iframe from 'components/Iframe';
import { Container, Col, Row, Card, CardBody, Modal, Button, ModalBody, FormGroup, Input, Label, ModalHeader, CardFooter, Progress, Tooltip, UncontrolledTooltip } from 'reactstrap';
import { BsFillJournalBookmarkFill, BsFillGearFill } from 'react-icons/bs';
import { useCookies } from 'react-cookie';
import { FaTrophy, FaCheckCircle, FaCalendar, FaMapMarkerAlt, FaPlus, FaPencilAlt, FaArrowLeft, FaUserTie, FaPeopleArrows } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const initialAwardData = Object.freeze({
  name: '',
  description: '',
  awards: '',
  logo: '',
  category_count: 0,
  schedule_1: '',
  schedule_2: '',
  schedule_3: '',
  schedule_4: '',
  schedule_5: '',
  website_url: '',
  finalists_url: '',
  judges_url: '',
  about_url: '',
  support_email: '',
  support_phone: '',
  host: '',
  host_description: '',
  start: '',
  location: '',
  dacast_url: '',
  vimeo_url: '',
  entry_guide_url: ''
})

const PBRPage = (props) => {
  let history = useHistory();
  const [awardData, setAwardData] = useState(initialAwardData);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const [award, setAward] = useState();
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'token', 'userDetails']);
  const currentUser = cookies.currentUser;
  const userRole = cookies.userRole;
  const isLoggedIn = cookies.isLoggedIn;
  const token = cookies.token;
  const userDetails = cookies.userDetails;

  const retrieveAward = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };


  // Get Award
  useEffect(() => {
    const getAward = async () => {
      const selectedAward = await retrieveAward();
      //console.log(selectedAward);
      setAward(selectedAward);
    }
    getAward();
  }, []);

  return (
    <EmptyPage
      className="preview pl-0 pr-0"
    //   title="Performance Benchmark Report"
      breadcrumbs={[{ name: 'Single Award', active: true }]}
    >
        <Iframe src="http://127.0.0.1:8000/award/report" />
    </EmptyPage>
  );
}
export default PBRPage;