import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/stripe/stripe.css';
import bg from '../assets/img/bg/BG.png';
import logo from '../assets/img/logo/Business-River-Logo.png';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import CustomToast from '../utils/CustomToast';
import '../styles/stripe/stripe.css';

const ConfirmEntryTitlePage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const code = query.get('code');
  const awardLogo = query.get('logo');

  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState('');
  const [entryTitle, setEntryTitle] = useState('');
  const [category, setCategory] = useState('');

  const getConfirmTitle = () => {
    return axios.get(process.env.REACT_APP_API_ENDPOINT + 'shortlist/entry/' + code + '/confirm/collateral');
  }
  
  useEffect(() => {
    getConfirmTitle().then(response => {
      const result = response.data.data;
      const message = response.data.message;

      setMessage(message);
      setEntryTitle(result.name);
      setCategory(result.category_name);
      setLoading(false);
    }).catch(() => CustomToast.defaultError());
  }, []);

  return (
    <>
      <ToastContainer style={{ width: "370px" }} />
      <div id='hero-bussiness-river' style={{ backgroundImage: 'url(' + bg + ')' }}>
        <div className='DemoWrapper' style={{ maxWidth: "1060px" }} >
          <div className='Demo'>
            <img 
              src={awardLogo} 
              alt="Award Logo"
              className="bussiness-river-logo my-3"
              style={{ height: '150px' }}
            />
            {loading ?
              <>
                <h1 className="text-center text-dark">Confirming Entry Title...</h1>
                <p className="text-center">Please don't close this window...</p>
              </>
              :
              <>
                <h1 className="text-center text-dark mb-0" style={{ maxWidth: "1012px" }} >{message}</h1>
                <p className="text-center mb-2" style={{ fontSize: '27px' }}>{entryTitle}</p>
                <p className="text-center">{category}</p>
              </>
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmEntryTitlePage;
