import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import BISlogoblue from 'assets/img/bis/logo2.png'
import PropTypes from 'prop-types';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Card, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import validator from 'validator'
import axios from 'axios';

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
  company_name: '',
  company_position: '',
  company_industry: 'Accounting',
  company_email: '',
  company_phone: '',
  first_name: '',
  last_name: '',
  contact_phone: '',
  contact_email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  province: '',
  zip: '',
  country: '',
});

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
})

function BisAuthForm(props) {
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  const { register, setValue, handleSubmit, formState: { errors } } = useForm();
  let query = useQuery();
  const email = query.get('email');
  const page = query.get('page');
  const action = query.get('action');
  const entry_id = query.get('entry_id');
  const parameter_section = query.get('section');
  const category_id = query.get('category_id');
  const location = useLocation();
  // console.log(parameter_section);
  var currentPathName = location.pathname;
  var currentHost = window.location.hostname;
  var currentUrl = window.location.href;
  // var currentHost = "account.greenawards.ie";
  const [section, setSection] = useState("");
  const [award_id, setAwardId] = useState(query.get('award_id'));
  const [awardReady, setAwardReady] = useState(false);
  const [formDataRegister, setFormDataRegister] = useState(initialFormDataRegister)
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin)
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn','award_id','section','failsafe_award_id','userRole']);
  // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentUser = cookies.currentUser;
  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  const isLoggedIn = cookies.isLoggedIn;
  const cookie_award_id = cookies.award_id;
  const cookie_section = cookies.section;
  const failsafe_award_id = cookies.failsafe_award_id;
  const userRole = cookies.userRole;

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id);
  }

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;

  const [isLogin, setIsLogin] = useState(props.authState === STATE_BIS_LOGIN)

  const [isSignup, setIsSignup] = useState(props.authState === STATE_BIS_SIGNUP)
  const [awardStatus, setAwardStatus] = useState('')

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  useEffect(() => {
    if (currentHost.includes("bis-administration") || currentHost.includes("bis-platform") || currentHost == "localhost") {
      var checkAwardId = query.get('award_id');
      if (checkAwardId) {
        setAwardId(checkAwardId);
        setCookie('award_id', checkAwardId, { path: '/' });
        setCookie('cookie_award_id', checkAwardId, { path: '/' });
        setCookie('failsafe_award_id', checkAwardId, { path: '/' });
        setTimeout(function(){
          setAwardReady(true);
        },1000);
      } else if (cookie_award_id) {
        setAwardId(cookie_award_id);
        setCookie('award_id', cookie_award_id, { path: '/' });
        setCookie('cookie_award_id', cookie_award_id, { path: '/' });
        setCookie('failsafe_award_id', cookie_award_id, { path: '/' });
        setTimeout(function(){
          setAwardReady(true);
        },1000);
      } else if (failsafe_award_id) {
        setAwardId(failsafe_award_id);
        setCookie('award_id', failsafe_award_id, { path: '/' });
        setCookie('cookie_award_id', failsafe_award_id, { path: '/' });
        setCookie('failsafe_award_id', failsafe_award_id, { path: '/' });
        setTimeout(function(){
          setAwardReady(true);
        },1000);
      } else {
        window.location.href = "http://businessriver.tv/";
      }
    } else {  
      let url = '';
      const domainParts = currentHost.split('.');
      // Check if there are at least two parts (subdomain and domain)
      if (domainParts.length >= 2) {
        // Remove the first part (subdomain) and join the remaining parts back together
        url = domainParts.slice(1).join('.');
      } else {
        toast.error('An error occured. Invalid domain format.', {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setTimeout(function(){
          window.location.href = "http://businessriver.tv/";
        },1500);
      }
      var checkAwardId = query.get('award_id');
      if (checkAwardId) {
        setAwardId(checkAwardId);
        setCookie('award_id', checkAwardId, { path: '/' });
        setCookie('cookie_award_id', checkAwardId, { path: '/' });
        setCookie('failsafe_award_id', checkAwardId, { path: '/' });
        setTimeout(function(){
          setAwardReady(true);
        },1000);
      } else {
        const getAwardId = async () => {
          return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'dashboard/award/current?url='+url);
        }
        getAwardId().then(response => {
          const result = response.data;
          var awardIdGet = result.data.id;
          if (result.status == "Success") {
            setAwardId(awardIdGet);
            setCookie('award_id', awardIdGet, { path: '/' });
            setCookie('cookie_award_id', awardIdGet, { path: '/' });
            setCookie('failsafe_award_id', awardIdGet, { path: '/' });
            setTimeout(function(){
              setAwardReady(true);
            },1000);
          } else {
            toast.error('An error occured. Please try again or contact the administrator.', {
              position: 'top-right',
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            setTimeout(function(){
              window.location.href = "http://businessriver.tv/";
            },1500);
          }
        }).catch((error) => {
          toast.error('An error occured. Please try again or contact the administrator.', {
            position: 'top-right',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(function(){
            window.location.href = "http://businessriver.tv/";
          },1500);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (currentPathName == "/entrant/login") {
      setSection("entrant");
    } else if (currentPathName == "/judge/login" || currentPathName == "/judge/register") {
      setSection("judge");
    } else if (currentPathName == "/coordinator/login" || currentPathName == "/coordinator/register") {
      setSection("coordinator");
    } else if (currentPathName == "/sponsor/login") {
      setSection("sponsor");
    } else if (currentPathName == "/designer/login") {
      setSection("designer");
    } else if (currentPathName == "/printer/login") {
      setSection("printer");
    } else {
      if (parameter_section) {
        setSection(parameter_section);
      } else {
        setSection("entrant");
      }
    }
    if (awardReady) {
      if (!currentUser) {
        if (currentPathName == "/register" || currentPathName == "/award/book" || currentPathName == "/award/book/form" || currentPathName == '/award/book/form/seats' || currentPathName == "/award/event"|| currentPathName == "/booking/summary" || currentPathName == "/booking/details" || currentPathName == "/booking/payment/success" || currentPathName == "/booking/attendees" || currentPathName == "/booking/attendees/setup" || currentPathName == "/category/winner" || currentPathName == "/award/winner" || currentPathName == "/award/nomination" || currentPathName == "/winner/summary" || currentPathName == "/winner/payment/success" || currentPathName == "/entry/payment/success") {
        } else {
          if ((!award_id && !section) || (award_id == "" && section == "")) {
            const firstPath = currentPathName.split('/')[1];
            if (firstPath && firstPath != "") {
              if (failsafe_award_id && failsafe_award_id != "") {
                removeCookie('token', { path: '/' });
                removeCookie('userDetails', { path: '/' });
                removeCookie('awardSettings', { path: '/' });
                removeCookie('isWelcome', { path: '/' });
                removeCookie('currentUser', { path: '/' });
                removeCookie('isLoggedIn', { path: '/' });
                removeCookie('userRole', { path: '/' });
                removeCookie('judge', { path: '/' });
                removeCookie('judge_id', { path: '/' });
                removeCookie('coordinator_id', { path: '/' });
                removeCookie('isAccept', { path: '/' });
                removeCookie('isProceed', { path: '/' });
                removeCookie('section', { path: '/' });
                removeCookie('award_id', { path: '/' });
                removeCookie('cookie_section', { path: '/' });
                removeCookie('cookie_award_id', { path: '/' });
                removeCookie('isSubmit', { path: '/' });
                setTimeout(function(){
                  history.push("/"+firstPath+"/login?award_id=" + failsafe_award_id);
                },500);
              } else {
                if (firstPath == "admin") {
                  removeCookie('token', { path: '/' });
                  removeCookie('userDetails', { path: '/' });
                  removeCookie('awardSettings', { path: '/' });
                  removeCookie('isWelcome', { path: '/' });
                  removeCookie('currentUser', { path: '/' });
                  removeCookie('isLoggedIn', { path: '/' });
                  removeCookie('userRole', { path: '/' });
                  removeCookie('judge', { path: '/' });
                  removeCookie('judge_id', { path: '/' });
                  removeCookie('coordinator_id', { path: '/' });
                  removeCookie('isAccept', { path: '/' });
                  removeCookie('isProceed', { path: '/' });
                  removeCookie('section', { path: '/' });
                  removeCookie('award_id', { path: '/' });
                  removeCookie('cookie_section', { path: '/' });
                  removeCookie('cookie_award_id', { path: '/' });
                  removeCookie('isSubmit', { path: '/' });
                  setTimeout(function(){
                    history.push("/"+firstPath+"/login");
                  },500);
                } else {
                  removeCookie('token', { path: '/' });
                  removeCookie('userDetails', { path: '/' });
                  removeCookie('awardSettings', { path: '/' });
                  removeCookie('isWelcome', { path: '/' });
                  removeCookie('currentUser', { path: '/' });
                  removeCookie('isLoggedIn', { path: '/' });
                  removeCookie('userRole', { path: '/' });
                  removeCookie('judge', { path: '/' });
                  removeCookie('judge_id', { path: '/' });
                  removeCookie('coordinator_id', { path: '/' });
                  removeCookie('isAccept', { path: '/' });
                  removeCookie('isProceed', { path: '/' });
                  removeCookie('section', { path: '/' });
                  removeCookie('award_id', { path: '/' });
                  removeCookie('cookie_section', { path: '/' });
                  removeCookie('cookie_award_id', { path: '/' });
                  removeCookie('isSubmit', { path: '/' });
                  window.location.href = "http://businessriver.tv/";
                }
              }
            } else {
              removeCookie('token', { path: '/' });
              removeCookie('userDetails', { path: '/' });
              removeCookie('awardSettings', { path: '/' });
              removeCookie('isWelcome', { path: '/' });
              removeCookie('currentUser', { path: '/' });
              removeCookie('isLoggedIn', { path: '/' });
              removeCookie('userRole', { path: '/' });
              removeCookie('judge', { path: '/' });
              removeCookie('judge_id', { path: '/' });
              removeCookie('coordinator_id', { path: '/' });
              removeCookie('isAccept', { path: '/' });
              removeCookie('isProceed', { path: '/' });
              removeCookie('section', { path: '/' });
              removeCookie('award_id', { path: '/' });
              removeCookie('cookie_section', { path: '/' });
              removeCookie('cookie_award_id', { path: '/' });
              removeCookie('isSubmit', { path: '/' });
              window.location.href = "http://businessriver.tv/";
            }
          } else {
            getAward().then(response => {
              const result = response.data.data;
              if (result) {
                setAwardStatus(() => result.status);
              } else {
                removeCookie('token', { path: '/' });
                removeCookie('userDetails', { path: '/' });
                removeCookie('awardSettings', { path: '/' });
                removeCookie('isWelcome', { path: '/' });
                removeCookie('currentUser', { path: '/' });
                removeCookie('isLoggedIn', { path: '/' });
                removeCookie('userRole', { path: '/' });
                removeCookie('judge', { path: '/' });
                removeCookie('judge_id', { path: '/' });
                removeCookie('coordinator_id', { path: '/' });
                removeCookie('isAccept', { path: '/' });
                removeCookie('isProceed', { path: '/' });
                removeCookie('section', { path: '/' });
                removeCookie('award_id', { path: '/' });
                removeCookie('cookie_section', { path: '/' });
                removeCookie('cookie_award_id', { path: '/' });
                removeCookie('isSubmit', { path: '/' });
                window.location.href = "http://businessriver.tv/";
              }
            }).catch(error => {
              console.error('Error during service worker registration:', error);
            });
          }
        }
      } else {
        if (currentPathName == "/register" || currentPathName == "/award/book" || currentPathName == "/award/book/form" || currentPathName == '/award/book/form/seats' || currentPathName == "/award/event"|| currentPathName == "/booking/summary" || currentPathName == "/booking/details" || currentPathName == "/booking/payment/success" || currentPathName == "/booking/attendees" || currentPathName == "/booking/attendees/setup" || currentPathName == "/category/winner" || currentPathName == "/award/winner" || currentPathName == "/award/nomination" || currentPathName == "/winner/summary" || currentPathName == "/winner/payment/success" || currentPathName == "/entry/payment/success") {
          if (action === 'entry' && section === 'entrant' && currentPathName == "/register") {
            setTimeout(function(){
              history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
            },500);
          }
        } else {
          if (userRole == "Admin") {
            history.push('/admin');
          } else {
            if (!award_id && !section) {
              if (!cookie_award_id && !cookie_section) {
                const firstPath = currentPathName.split('/')[1];
                if (firstPath && firstPath != "") {
                  if (failsafe_award_id && failsafe_award_id != "") {
                    removeCookie('token', { path: '/' });
                    removeCookie('userDetails', { path: '/' });
                    removeCookie('awardSettings', { path: '/' });
                    removeCookie('isWelcome', { path: '/' });
                    removeCookie('currentUser', { path: '/' });
                    removeCookie('isLoggedIn', { path: '/' });
                    removeCookie('userRole', { path: '/' });
                    removeCookie('judge', { path: '/' });
                    removeCookie('judge_id', { path: '/' });
                    removeCookie('coordinator_id', { path: '/' });
                    removeCookie('isAccept', { path: '/' });
                    removeCookie('isProceed', { path: '/' });
                    removeCookie('section', { path: '/' });
                    removeCookie('award_id', { path: '/' });
                    removeCookie('cookie_section', { path: '/' });
                    removeCookie('cookie_award_id', { path: '/' });
                    removeCookie('isSubmit', { path: '/' });
                    setTimeout(function(){
                      history.push("/"+firstPath+"/login?award_id=" + failsafe_award_id);
                    },500);
                    
                  } else {
                    if (firstPath == "admin") {
                      removeCookie('token', { path: '/' });
                      removeCookie('userDetails', { path: '/' });
                      removeCookie('awardSettings', { path: '/' });
                      removeCookie('isWelcome', { path: '/' });
                      removeCookie('currentUser', { path: '/' });
                      removeCookie('isLoggedIn', { path: '/' });
                      removeCookie('userRole', { path: '/' });
                      removeCookie('judge', { path: '/' });
                      removeCookie('judge_id', { path: '/' });
                      removeCookie('coordinator_id', { path: '/' });
                      removeCookie('isAccept', { path: '/' });
                      removeCookie('isProceed', { path: '/' });
                      removeCookie('section', { path: '/' });
                      removeCookie('award_id', { path: '/' });
                      removeCookie('cookie_section', { path: '/' });
                      removeCookie('cookie_award_id', { path: '/' });
                      removeCookie('isSubmit', { path: '/' });
                      setTimeout(function(){
                        history.push("/"+firstPath+"/login");
                      },500);
                    } else {
                      removeCookie('token', { path: '/' });
                      removeCookie('userDetails', { path: '/' });
                      removeCookie('awardSettings', { path: '/' });
                      removeCookie('isWelcome', { path: '/' });
                      removeCookie('currentUser', { path: '/' });
                      removeCookie('isLoggedIn', { path: '/' });
                      removeCookie('userRole', { path: '/' });
                      removeCookie('judge', { path: '/' });
                      removeCookie('judge_id', { path: '/' });
                      removeCookie('coordinator_id', { path: '/' });
                      removeCookie('isAccept', { path: '/' });
                      removeCookie('isProceed', { path: '/' });
                      removeCookie('section', { path: '/' });
                      removeCookie('award_id', { path: '/' });
                      removeCookie('cookie_section', { path: '/' });
                      removeCookie('cookie_award_id', { path: '/' });
                      removeCookie('isSubmit', { path: '/' });
                      window.location.href = "http://businessriver.tv/";
                    }
                  }
                } else {
                  removeCookie('token', { path: '/' });
                  removeCookie('userDetails', { path: '/' });
                  removeCookie('awardSettings', { path: '/' });
                  removeCookie('isWelcome', { path: '/' });
                  removeCookie('currentUser', { path: '/' });
                  removeCookie('isLoggedIn', { path: '/' });
                  removeCookie('userRole', { path: '/' });
                  removeCookie('judge', { path: '/' });
                  removeCookie('judge_id', { path: '/' });
                  removeCookie('coordinator_id', { path: '/' });
                  removeCookie('isAccept', { path: '/' });
                  removeCookie('isProceed', { path: '/' });
                  removeCookie('section', { path: '/' });
                  removeCookie('award_id', { path: '/' });
                  removeCookie('cookie_section', { path: '/' });
                  removeCookie('cookie_award_id', { path: '/' });
                  removeCookie('isSubmit', { path: '/' });
                  window.location.href = "http://businessriver.tv/";
                }
              } else {
                setCookie('award_id', cookie_award_id, { path: '/' });
                setCookie('section', cookie_section, { path: '/' });

                if (action === 'entry' && cookie_section === 'entrant') {
                  setTimeout(function(){
                    history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                  },500);
                } else if (entry_id && cookie_section === 'entrant') {
                  setTimeout(function(){
                    history.push("/entrant/entry/details?entry_id="+entry_id);
                  },500);
                } else {
                  setTimeout(function(){
                    history.push("/"+cookie_section+"/home");
                  },500);
                }

              }
            } else {
              if (!cookie_award_id && !cookie_section) {
                removeCookie('token', { path: '/' });
                removeCookie('userDetails', { path: '/' });
                removeCookie('awardSettings', { path: '/' });
                removeCookie('isWelcome', { path: '/' });
                removeCookie('currentUser', { path: '/' });
                removeCookie('isLoggedIn', { path: '/' });
                removeCookie('userRole', { path: '/' });
                removeCookie('judge', { path: '/' });
                removeCookie('judge_id', { path: '/' });
                removeCookie('coordinator_id', { path: '/' });
                removeCookie('isAccept', { path: '/' });
                removeCookie('isProceed', { path: '/' });
                removeCookie('section', { path: '/' });
                removeCookie('award_id', { path: '/' });
                removeCookie('cookie_section', { path: '/' });
                removeCookie('cookie_award_id', { path: '/' });
                removeCookie('isSubmit', { path: '/' });
                window.location.href = "http://businessriver.tv/";
              } else {
                if (category_id && category_id != "") {
                  setCookie('award_id', award_id, { path: '/' });
                  setCookie('section', section, { path: '/' });
                  if (action === 'entry') {
                    setTimeout(function(){
                      history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                    },500);
                  } else {
                    setTimeout(function(){
                      history.push("/entrant/category?category_id="+category_id+"&from_website=1");
                    },500);
                  }
                } else {
                  if (section && award_id) {
                    setCookie('award_id', award_id, { path: '/' });
                    setCookie('section', section, { path: '/' });
                    if (action === 'entry' && section === 'entrant') {
                      setTimeout(function(){
                        history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                      },500);
                    } else if (entry_id && cookie_section === 'entrant') {
                      setTimeout(function(){
                        history.push("/entrant/entry/details?entry_id="+entry_id);
                      },500);
                    } else {
                      setTimeout(function(){
                        history.push("/"+section+"/home");
                      },500);
                    }
                  } else {
                    if (cookie_section && cookie_award_id) {
                      setCookie('award_id', cookie_award_id, { path: '/' });
                      setCookie('section', cookie_section, { path: '/' });

                      if (action === 'entry' && cookie_section == 'entrant') {
                        setTimeout(function(){
                          history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                        },500);
                      } else if (entry_id && cookie_section === 'entrant') {
                        setTimeout(function(){
                          history.push("/entrant/entry/details?entry_id="+entry_id);
                        },500);
                      } else {
                        setTimeout(function(){
                          history.push("/"+cookie_section+"/home");
                        },500);
                      }
                    } else {
                      setCookie('section', "entrant", { path: '/' });
                      if (action === 'entry') {
                        setTimeout(function(){
                          history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                        },500);
                      } else if (entry_id && cookie_section === 'entrant') {
                        setTimeout(function(){
                          history.push("/entrant/entry/details?entry_id="+entry_id);
                        },500);
                      } else {
                        setTimeout(function(){
                          history.push("/entrant/home");
                        },500);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }, [awardReady]);

  const renderButtonText = (e) => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isSignup) {
      return 'Register';
    }
    return buttonText;
  }

  const handleChange = (e) => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      })
    }
    //console.log(formDataLogin);
    //console.log(formDataRegister);
  }

  const goToLogin = (e) => {
    let emailParams = '';
    if (email)
      emailParams = '&email=' + email;

    if (action) {
      if (category_id && category_id != "") {
        history.push("/entrant/login?award_id="+award_id+"&section="+section+"&action="+action+"&category_id="+category_id+emailParams);
      } else {
        history.push("/entrant/login?award_id="+award_id+"&section="+section+"&action="+action+emailParams);
      }
    } else {
      if (category_id && category_id != "") {
        if (award_id && section) {
          history.push("/"+section+"/login?award_id="+award_id+"&category_id="+category_id+emailParams);
        } else {
          history.push("/entrant/login?award_id="+award_id+"&category_id="+category_id+emailParams);
        }
      } else {
        if (award_id && section) {
          history.push("/"+section+"/login?award_id="+award_id+emailParams);
        } else {
          history.push("/entrant/login?award_id="+award_id+emailParams);
        }
      }
      
    }
  }

  const goToRegister = (e) => {
    let emailParams = '';
    if (email)
      emailParams = '&email=' + email;

    if (action) {
      if (category_id && category_id != "") {
        history.push("/entrant/register?award_id="+award_id+"&section="+section+"&action="+action+"&category_id="+category_id+emailParams);
      } else {
        history.push("/entrant/register?award_id="+award_id+"&section="+section+"&action="+action+emailParams);
      }
      
    } else {
      if (category_id && category_id != "") {
        if (award_id && section) {
          if (currentPathName == "/entrant/login") {
            history.push("/entrant/register?award_id="+award_id+"&category_id="+category_id+emailParams);
          } else {
            history.push("/register?award_id="+award_id+"&section="+section+"&category_id="+category_id+emailParams);
          }
        } else {
          history.push("/register?category_id="+category_id+emailParams);
        }
      } else {
        if (award_id && section) {
          if (currentPathName == "/entrant/login") {
            history.push("/entrant/register?award_id="+award_id+emailParams);
          } else {
            history.push("/register?award_id="+award_id+"&section="+section+emailParams);
          }
        } else {
          if (email)
            history.push("/register?email="+email);
          else
            history.push("/register");
        }
      }
    }
  }
  
  async function loginSubmit(data) {
    // const onSubmit = data => {
    //   console.log(data);
    //   console.log(errors);
    // }
    // e.preventDefault();
    setFormStatus("loading");
    if (validator.isEmail(data.email)) {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'login', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify({...data, award_id: award_id, url: currentUrl}),
      });

      const json = await response.json();
      
      if (json.status == "Success") {
        // console.log(json);
        setFormStatus("standby");
        // console.log(json.data);
        setCookie('userDetails', JSON.stringify(json.data), { path: '/' });
        setCookie('awardSettings', json.data.user.award_settings, { path: '/' });
        setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
        setCookie('isLoggedIn', true, { path: '/' });
        setCookie('userRole', json.data.user.role, { path: '/' });
        setCookie('judge', json.data.user.judge, { path: '/' });
        setCookie('judge_id', json.data.user.judge_id, { path: '/' });
        setCookie('coordinator_id', json.data.user.coordinator_id, { path: '/' });
        setCookie('award_id', award_id, { path: '/' });
        setCookie('token', json.data.token, { path: '/' });
        setCookie('cookie_award_id', award_id, { path: '/' });
        setCookie('failsafe_award_id', award_id, { path: '/' });
        
        setTimeout(function(){
          if (currentPathName == "/entrant/login") {
            setCookie('section', "entrant", { path: '/' });
            setCookie('cookie_section', "entrant", { path: '/' });
            if (action === 'entry') {
              history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
            } else if (entry_id) {
              setTimeout(function(){
                history.push("/entrant/entry/details?entry_id="+entry_id);
              },500);
            } else {
              if (category_id && category_id != "") {
                history.push("/entrant/category?category_id="+category_id+"&from_website=1");
              } else {
                history.push("/entrant/home");
              }
            }
            if (page === 'price-quote') {
              history.push("/entrant/price-quote");
            }
          } else if (currentPathName == "/judge/login") {
            setCookie('section', "judge", { path: '/' });
            setCookie('cookie_section', "judge", { path: '/' });
            history.push("/judge/home");
          } else if (currentPathName == "/coordinator/login") {
            setCookie('section', "coordinator", { path: '/' });
            setCookie('cookie_section', "coordinator", { path: '/' });
            history.push("/coordinator/home");
          } else {
            if (section == "designer" || section == "printer") {
              if (section == "designer") {
                setCookie('section', "designer", { path: '/' });
                setCookie('cookie_section', "designer", { path: '/' });
                history.push("/designer/home?award_id="+award_id+"&section="+section);
              } else {
                setCookie('section', "printer", { path: '/' });
                setCookie('cookie_section', "printer", { path: '/' });
                history.push("/printer/home?award_id="+award_id+"&section="+section);
              }
            } else {
              if (action) {
                setCookie('section', section, { path: '/' });
                setCookie('cookie_section', section, { path: '/' });
                history.push("/"+section+"/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
              } else {
                if (section == "sponsor") {
                  setCookie('section', section, { path: '/' });
                  setCookie('cookie_section', section, { path: '/' });
                  history.push("/sponsor/home");
                } else {
                  if (json.data.user.role == "Admin") {
                    history.push("/admin");
                  } else if (award_id && section) {
                    setCookie('section', section, { path: '/' });
                    setCookie('cookie_section', section, { path: '/' });
                    history.push("/"+section+"/home");
                    // history.push("?award_id=none&section=none");
                  } else if (award_id) {
                    history.push("/entrant/home");
                  } else if (section) {
                    setCookie('section', section, { path: '/' });
                    setCookie('cookie_section', section, { path: '/' });
                    history.push("/"+section+"/home");
                  } else {
                    history.push("/entrant/home");
                  }
                }
              }
            }
          }
          
        }, 500);
      } else {
        //console.log(json);
        if (json.errors.email) {
          toast.error(json.errors.email[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } if (json.errors.password) {
          toast.error(json.errors.password[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(json.errors[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    } 
  }

  async function signupSubmit(data) {
    // e.preventDefault();
    setFormStatus("loading");
    // console.log(currentUrl, currentHost, currentPathName);
    // console.log(data);
    if (validator.isEmail(data.email)) {
      if (data.password != "" && data.password_confirmation != ""
       && data.first_name != ""  && data.last_name != ""  && data.company_name != ""
       && data.company_position != ""  && data.company_industry != "" && data.company_phone != "" ) {
        const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'register', {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "omit",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          redirect: "follow",
          referrer: "client",
          body: JSON.stringify({...data, award_id: award_id, url: currentUrl}),
        });

        const json = await response.json();
        // console.log(json);
        if (json.status == "Success") {
          setCookie('userDetails', JSON.stringify(json.data), { path: '/' });
          setCookie('userRole', json.data.user.role, { path: '/' });
          setCookie('token', json.data.token, { path: '/' });
          setCookie('awardSettings', JSON.stringify(json.data.user.award_settings ? json.data.user.award_settings : 0), { path: '/' });
          setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
          setCookie('isLoggedIn', true, { path: '/' });
          setCookie('judge', json.data.user.judge, { path: '/' });
          setCookie('judge_id', json.data.user.judge_id, { path: '/' });
          setCookie('coordinator_id', json.data.user.coordinator_id, { path: '/' });
          setCookie('cookie_award_id', award_id, { path: '/' });
          setCookie('cookie_section', section, { path: '/' });
          setCookie('award_id', award_id, { path: '/' });
          setCookie('section', section, { path: '/' });

          if (action) {
            history.push("/"+section+"/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
          } else {
            if (currentPathName == "/entrant/register") {
              setCookie('cookie_section', "entrant", { path: '/' });
              setCookie('section', '"entrant"', { path: '/' });
              if (action === 'entry') {
                history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
              } else {
                if (category_id && category_id != "") {
                  history.push("/entrant/category?category_id="+category_id+"&from_website=1");
                } else {
                  history.push("/entrant/home");
                }
              }
            } else if (currentPathName == "/judge/register") {
              setCookie('cookie_section', "judge", { path: '/' });
              setCookie('section', '"judge"', { path: '/' });
              history.push("/judge/home");
            } else if (currentPathName == "/coordinator/register") {
              setCookie('cookie_section', "coordinator", { path: '/' });
              setCookie('section', '"coordinator"', { path: '/' });
              history.push("/coordinator/home");
            } else {
              if (json.data.user.role == "Admin") {
                history.push("/admin");
              } else if (award_id && section) {
                history.push(section+"/home?award_id="+award_id+"&section="+section);
              } else if (award_id) {
                history.push("?award_id="+award_id);
              } else if (section) {
                history.push(section+"/home?section="+section);
              } else {
                if (action === 'entry') {
                  history.push("/entrant/submit-entry?award_id="+award_id+"&section="+section+"&action="+action);
                } else {
                  if (category_id && category_id != "") {
                    history.push("/entrant/category?category_id="+category_id+'&from_website=1');
                  } else {
                    history.push("/entrant/home");
                  }
                }
              }
            }

          }


          // toast.success('Registered successfully!', {
          //   position: "top-right",
          //   autoClose: 3000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   onClose: () => {
          //     if (json.data.user.role == "Admin") {
          //       history.push("/admin");
          //     } else if (award_id && section) {
          //       history.push("?award_id="+award_id+"&section=entrant");
          //     } else if (award_id) {
          //       history.push("?award_id="+award_id);
          //     } else if (section) {
          //       history.push("?section="+section);
          //     }  else {
          //       history.push("/entrant/home?award_id=7");
          //     }
          //     setCookie('awardSettings', JSON.stringify(json.data.user.award_settings ? json.data.user.award_settings : 0), { path: '/' });
          //     setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
          //     setCookie('isLoggedIn', true, { path: '/' });
          //     setCookie('judge', json.data.user.judge, { path: '/' });
          //     setCookie('judge_id', json.data.user.judge_id, { path: '/' });
          //     setCookie('coordinator_id', json.data.user.coordinator_id, { path: '/' });
          //   }
          // });
          //history.push("/");
          setFormStatus("standby");
        } else {
          if (json.errors.email) {
            toast.error(json.errors.email[0], {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } if (json.errors.password) {
            toast.error(json.errors.password[0], {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error(json.errors[0], {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          setFormStatus("standby");
        }
      } else {
        toast.error("Please fill up all fields!", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    }

  }

  useEffect(() => {
    if (email && email != "") {
      setValue("email", email);
    }
  }, [email]);

  return (
    <div id="bis-form">
      <ToastContainer style={{ width: "370px" }} />
      {isLogin && (
        <form onSubmit={handleSubmit(loginSubmit)} className="bis_login_form">
          {section === "entrant" && awardStatus === 'Closed' ?
            <>
              <p className="text-center">Register, download and share the images and videos from last night's awards ceremony!</p>
            </>
            :
            null
          }
          {/* {showLogo && (
            <div className="text-center pb-4">
              <a href="/">
                <img
                  src={VEP}
                  className="rounded"
                  style={{ height: 60 }}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </a>
            </div>
          )} */}

          <p className="text-center form_login">MEMBER LOGIN</p>
          <div className="form-group">
            {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
            <input {...register("email", { required: "Email field is required"})} placeholder="Email Address" maxLength={80} type="email" className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-3"}`} />
            {errors.email ? <p role="alert" className="invalid-feedback">Email field is required</p> : null}
          </div>
          <FormGroup>
            {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
            <input {...register("password", { required: "Password field is required"})} placeholder="Password" maxLength={255} type="password" className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-3"}`} />
            {errors.email ? <p role="alert" className="invalid-feedback">Password field is required</p> : null}
          </FormGroup>
          {/* {isLogin && (
            <a href="#" className="forget_password">Forget Password</a>
          )} */}
          {formStatus != "standby" ?
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              Logging in...
            </Button>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block
              >
              {renderButtonText()}
            </Button>
          }
          <div className='text-center'>
            <a className='bis-link' href={'/forgotpassword?email=' + email}>Forgot password?</a>
          </div>
          <div className="text-center mt-1">
            <a href="" className="bis-link" onClick={goToRegister}>
              Register
            </a>
          </div>
        </form>
      )}

      {isSignup && (
        <Form onSubmit={handleSubmit(signupSubmit)} className="bis_signup_form container">
          {/* {showLogo && (
            <div className="text-center pb-4">
              <img
                src={VEP}
                className="rounded"
                style={{ height: 60 }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )} */}
          <div className='text-center mt-3 mb-3'>
            <img
              src={BISlogoblue}
              className="rounded"
              style={{ height: 60 }}
              alt="logo"
            />
          </div>
          {section == "entrant" ?
            <>
              <p className="text-center form_login mb-0">ENTRY REGISTRATION</p>
              <p className="small text-muted text-center mb-4">Fill out your details below which will bring you to step 2 where you can then choose categories and upload your submission.{award_id != 47 && award_id != 67 && ' The Awards are free to enter.'}</p>
            </>
            :
            <p className="text-center form_login mb-0">MEMBER REGISTRATION</p>
          }

          <h3>LOGIN INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <FormGroup>
                <p className='small mb-1'>Email Address<span className="asterisk_red">*</span> </p>
                {/* <Input {...usernameInputProps} type="text" name="username" className="mb-3" onChange={handleChange} /> */}
                {/* <Input type="email" name="email" className="mb-3" onChange={handleChange} /> */}
                <input {...register("email", { required: true})} type="email" maxLength={80} className={`form-control ${errors.email ? "form-invalid mb-0" : "mb-3"}`} />
                {errors.email ? <p role="alert" className="invalid-feedback">Email field is required</p> : null}
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Password<span className="asterisk_red">*</span></p>
                    {/* <Input type="password" name="password" onChange={handleChange} /> */}
                    <input {...register("password", { required: true})} type="password" maxLength={255} className={`form-control ${errors.password ? "form-invalid mb-0" : "mb-0"}`} />
                    {errors.email ? <p role="alert" className="invalid-feedback mb-0">Password field is required</p> : null}
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <p className='small mb-1'>Confirm Password<span className="asterisk_red">*</span></p>
                    {/* <Input type="password" name="password_confirmation" onChange={handleChange} /> */}
                    <input {...register("password_confirmation", { required: true})} type="password" maxLength={255} className={`form-control ${errors.password_confirmation ? "form-invalid mb-0" : "mb-0"}`} />
                    {errors.password_confirmation ? <p role="alert" className="invalid-feedback  mb-0">Password confirmation field is required</p> : null}
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3>PERSONAL INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <p className='small mb-1'>First Name<span className="asterisk_red">*</span></p>
              {/* <Input type="text" name="first_name" onChange={handleChange} required/> */}
              <input {...register("first_name", { required: true})} type="text" maxLength={40} className={`form-control ${errors.first_name ? "form-invalid mb-0" : "mb-0"}`} />
              {errors.first_name ? <p role="alert" className="invalid-feedback mb-0">First Name field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Last Name<span className="asterisk_red">*</span></p>
              {/* <Input type="text" name="last_name" onChange={handleChange} required/> */}
              <input {...register("last_name", { required: true})} type="text" maxLength={40} className={`form-control ${errors.last_name ? "form-invalid mb-0" : "mb-0"}`} />
              {errors.last_name ? <p role="alert" className="invalid-feedback mb-0">Last Name field is required</p> : null}
            </Col>
          </Row>
          <h3>COMPANY DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <p className='small mb-1'>Company<span className="asterisk_red">*</span></p>
              {/* <Input type="text" name="company_name" className="mb-3" onChange={handleChange} required/> */}
              <input {...register("company_name", { required: true})} type="text" maxlength={150} className={`form-control ${errors.company_name ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_name ? <p role="alert" className="invalid-feedback">Company field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Position/Job Title<span className="asterisk_red">*</span></p>
              {/* <Input type="text" name="company_position" className="mb-3" onChange={handleChange} required/> */}
              <input {...register("company_position", { required: true})} type="text" maxlength={150} className={`form-control ${errors.company_position ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_position ? <p role="alert" className="invalid-feedback">Company Position field is required</p> : null}
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Industry<span className="asterisk_red">*</span></p>
              <select {...register("company_industry", { required: true})} maxLength={50} className={`form-control ${errors.company_industry ? "form-invalid mb-0" : "mb-3"}`}>
                <option value="Accounting">Accounting</option>
                <option value="Airlines/Aviation">Airlines/Aviation</option>
                <option value="Alternative Dispute Resolution">Alternative Dispute Resolution</option>
                <option value="Alternative Medicine">Alternative Medicine</option>
                <option value="Animation">Animation</option>
                <option value="Apparel/Fashion">Apparel/Fashion</option>
                <option value="Architecture/Planning">Architecture/Planning</option>
                <option value="Arts/Crafts">Arts/Crafts</option>
                <option value="Automotive">Automotive</option>
                <option value="Aviation/Aerospace">Aviation/Aerospace</option>
                <option value="Banking/Mortgage">Banking/Mortgage</option>
                <option value="Biotechnology/Greentech">Biotechnology/Greentech</option>
                <option value="Broadcast Media">Broadcast Media</option>
                <option value="Building Materials">Building Materials</option>
                <option value="Business Supplies/Equipment">Business Supplies/Equipment</option>
                <option value="Capital Markets/Hedge Fund/Private Equity">Capital Markets/Hedge Fund/Private Equity</option>
                <option value="Chemicals">Chemicals</option>
                <option value="Civic/Social Organization">Civic/Social Organization</option>
                <option value="Civil Engineering">Civil Engineering</option>
                <option value="Commercial Real Estate">Commercial Real Estate</option>
                <option value="Computer Games">Computer Games</option>
                <option value="Computer Hardware">Computer Hardware</option>
                <option value="Computer Networking">Computer Networking</option>
                <option value="Computer Software/Engineering">Computer Software/Engineering</option>
                <option value="Computer/Network Security">Computer/Network Security</option>
                <option value="Construction">Construction</option>
                <option value="Consumer Electronics">Consumer Electronics</option>
                <option value="Consumer Goods">Consumer Goods</option>
                <option value="Consumer Services">Consumer Services</option>
                <option value="Cosmetics">Cosmetics</option>
                <option value="Dairy">Dairy</option>
                <option value="Defense/Space">Defense/Space</option>
                <option value="Design">Design</option>
                <option value="E-Learning">E-Learning</option>
                <option value="Education Management">Education Management</option>
                <option value="Electrical/Electronic Manufacturing">Electrical/Electronic Manufacturing</option>
                <option value="Entertainment/Movie Production">Entertainment/Movie Production</option>
                <option value="Environmental Services">Environmental Services</option>
                <option value="Events Services">Events Services</option>
                <option value="Executive Office">Executive Office</option>
                <option value="Facilities Services">Facilities Services</option>
                <option value="Farming">Farming</option>
                <option value="Financial Services">Financial Services</option>
                <option value="Fine Art">Fine Art</option>
                <option value="Fishery">Fishery</option>
                <option value="Food Production">Food Production</option>
                <option value="Food/Beverages">Food/Beverages</option>
                <option value="Fundraising">Fundraising</option>
                <option value="Furniture">Furniture</option>
                <option value="Gambling/Casinos">Gambling/Casinos</option>
                <option value="Glass/Ceramics/Concrete">Glass/Ceramics/Concrete</option>
                <option value="Government Administration">Government Administration</option>
                <option value="Government Relations">Government Relations</option>
                <option value="Graphic Design/Web Design">Graphic Design/Web Design</option>
                <option value="Health/Fitness">Health/Fitness</option>
                <option value="Higher Education/Acadamia">Higher Education/Acadamia</option>
                <option value="Hospital/Health Care">Hospital/Health Care</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Human Resources/HR">Human Resources/HR</option>
                <option value="Import/Export">Import/Export</option>
                <option value="Individual/Family Services">Individual/Family Services</option>
                <option value="Industrial Automation">Industrial Automation</option>
                <option value="Information Services">Information Services</option>
                <option value="Information Technology/IT">Information Technology/IT</option>
                <option value="Insurance">Insurance</option>
                <option value="International Affairs">International Affairs</option>
                <option value="International Trade/Development">International Trade/Development</option>
                <option value="Internet">Internet</option>
                <option value="Investment Banking/Venture">Investment Banking/Venture</option>
                <option value="Investment Management/Hedge Fund/Private Equity">Investment Management/Hedge Fund/Private Equity</option>
                <option value="Judiciary">Judiciary</option>
                <option value="Law Enforcement">Law Enforcement</option>
                <option value="Law Practice/Law Firms">Law Practice/Law Firms</option>
                <option value="Legal Services">Legal Services</option>
                <option value="Legislative Office">Legislative Office</option>
                <option value="Leisure/Travel">Leisure/Travel</option>
                <option value="Library">Library</option>
                <option value="Logistics/Procurement">Logistics/Procurement</option>
                <option value="Luxury Goods/Jewelry">Luxury Goods/Jewelry</option>
                <option value="Machinery">Machinery</option>
                <option value="Management Consulting">Management Consulting</option>
                <option value="Maritime">Maritime</option>
                <option value="Market Research">Market Research</option>
                <option value="Marketing/Advertising/Sales">Marketing/Advertising/Sales</option>
                <option value="Mechanical or Industrial Engineering">Mechanical or Industrial Engineering</option>
                <option value="Media Production">Media Production</option>
                <option value="Medical Equipment">Medical Equipment</option>
                <option value="Medical Practice">Medical Practice</option>
                <option value="Mental Health Care">Mental Health Care</option>
                <option value="Military Industry">Military Industry</option>
                <option value="Mining/Metals">Mining/Metals</option>
                <option value="Motion Pictures/Film">Motion Pictures/Film</option>
                <option value="Museums/Institutions">Museums/Institutions</option>
                <option value="Music">Music</option>
                <option value="Nanotechnology">Nanotechnology</option>
                <option value="Newspapers/Journalism">Newspapers/Journalism</option>
                <option value="Non-Profit/Volunteering">Non-Profit/Volunteering</option>
                <option value="Oil/Energy/Solar/Greentech">Oil/Energy/Solar/Greentech</option>
                <option value="Online Publishing">Online Publishing</option>
                <option value="Other Industry">Other Industry</option>
                <option value="Outsourcing/Offshoring">Outsourcing/Offshoring</option>
                <option value="Package/Freight Delivery">Package/Freight Delivery</option>
                <option value="Packaging/Containers">Packaging/Containers</option>
                <option value="Paper/Forest Products">Paper/Forest Products</option>
                <option value="Performing Arts">Performing Arts</option>
                <option value="Pharmaceuticals">Pharmaceuticals</option>
                <option value="Philanthropy">Philanthropy</option>
                <option value="Photography">Photography</option>
                <option value="Plastics">Plastics</option>
                <option value="Political Organization">Political Organization</option>
                <option value="Primary/Secondary Education">Primary/Secondary Education</option>
                <option value="Printing">Printing</option>
                <option value="Professional Training">Professional Training</option>
                <option value="Program Development">Program Development</option>
                <option value="Public Relations/PR">Public Relations/PR</option>
                <option value="Public Safety">Public Safety</option>
                <option value="Publishing Industry">Publishing Industry</option>
                <option value="Railroad Manufacture">Railroad Manufacture</option>
                <option value="Ranching">Ranching</option>
                <option value="Real Estate/Mortgage">Real Estate/Mortgage</option>
                <option value="Recreational Facilities/Services">Recreational Facilities/Services</option>
                <option value="Religious Institutions">Religious Institutions</option>
                <option value="Renewables/Environment">Renewables/Environment</option>
                <option value="Research Industry">Research Industry</option>
                <option value="Restaurants">Restaurants</option>
                <option value="Retail Industry">Retail Industry</option>
                <option value="Security/Investigations">Security/Investigations</option>
                <option value="Semiconductors">Semiconductors</option>
                <option value="Shipbuilding">Shipbuilding</option>
                <option value="Sporting Goods">Sporting Goods</option>
                <option value="Sports">Sports</option>
                <option value="Staffing/Recruiting">Staffing/Recruiting</option>
                <option value="Supermarkets">Supermarkets</option>
                <option value="Technology">Technology</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Textiles">Textiles</option>
                <option value="Think Tanks">Think Tanks</option>
                <option value="Tobacco">Tobacco</option>
                <option value="Translation/Localization">Translation/Localization</option>
                <option value="Transportation">Transportation</option>
                <option value="Utilities">Utilities</option>
                <option value="Venture Capital/VC">Venture Capital/VC</option>
                <option value="Veterinary">Veterinary</option>
                <option value="Warehousing">Warehousing</option>
                <option value="Wholesale">Wholesale</option>
                <option value="Wine/Spirits">Wine/Spirits</option>
                <option value="Wireless">Wireless</option>
                <option value="Writing/Editing">Writing/Editing</option>
              </select>
              {errors.company_industry ? <p role="alert" className="invalid-feedback">Industry field is required</p> : null}
            </Col>
            {/* <Col lg={6}>
              <p className='small mb-1'>Company Email Address<span className="asterisk_red">*</span></p>
              <Input type="email" name="company_email" className="mb-3" onChange={handleChange} required/>
            </Col> */}
            <Col lg={12}>
              <p className='small mb-1'>Company Phone Number<span className="asterisk_red">*</span></p>
              {/* <Input type="tel" name="company_phone" onChange={handleChange} required/> */}
              <input {...register("company_phone", { required: true})} type="tel" maxLength={150} className={`form-control ${errors.company_phone ? "form-invalid mb-0" : "mb-3"}`} />
              {errors.company_phone ? <p role="alert" className="invalid-feedback">Company Phone Number field is required</p> : null}
            </Col>
          </Row>
          {/* <h3>ADDRESS DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <p className='small mb-1'>Address Line 1</p>
              <Input type="text" name="address_line_1" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={12}>
              <p className='small mb-1'>Address Line 2</p>
              <Input type="text" name="address_line_2" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>City</p>
              <Input type="text" name="city" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Province/City</p>
              <Input type="text" name="province" className="mb-3" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Postal Code</p>
              <Input type="text" name="zip" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Country</p>
              <Input type="text" name="country" onChange={handleChange} />
            </Col>
          </Row>
          <h3>CONTACT DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <p className='small mb-1'>Phone Number</p>
              <Input type="tel" name="contact_phone" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <p className='small mb-1'>Mobile Number</p>
              <Input type="tel" name="contact_mobile" onChange={handleChange} />
            </Col>
          </Row> */}
          {formStatus != "standby" ?
            <>
              {isLogin ?
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Logging in...
                </Button>
                :
                <Button
                  size="lg"
                  className="border-0 mt-4"
                  block
                  type="submit"
                >
                  Signing up...
                </Button>
              }
            </>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              {renderButtonText()}
            </Button>
          }
          <div className="text-center">
            <span className="mr-2">Already have an account?</span>
            <a href="" className="bis-link" onClick={goToLogin}>
              Login
            </a>
          </div>
        </Form>
      )}
      {/* <div className="text-center pt-1">
        <h6>
          {isSignup ? (
            <a href="#bis-login" className="bis-link" onClick={changeAuthState(STATE_BIS_LOGIN)}>
              Login
            </a>
          ) : (
            <a href="#bis-signup" className="bis-link" onClick={changeAuthState(STATE_BIS_SIGNUP)}>
              Register
            </a>
          )}
        </h6>
      </div> */}
      {children}
    </div>

  );
}


export const STATE_BIS_LOGIN = 'LOGIN';
export const STATE_BIS_SIGNUP = 'SIGNUP';

BisAuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_BIS_LOGIN, STATE_BIS_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

BisAuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => { },
};

export default BisAuthForm;
