import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import axios from 'axios';
import CustomToast from '../../utils/CustomToast';
import PageSpinner from '../PageSpinner';
import StepOne from './StepOne';

import EntriesPreEvent from './Entries/PreEventFeedbackForm';
import LiveEventPreEvent from './LiveEvent/PreEventFeedbackForm';
import PreEventPreEvent from './PreEvent/PreEventFeedbackForm';
import SponsorshipPreEvent from './Sponsorship/PreEventFeedbackForm';

import EntriesEventDay from './Entries/EventDayFeedbackForm';
import LiveEventEventDay from './LiveEvent/EventDayFeedbackForm';
import PreEventEventDay from './PreEvent/EventDayFeedbackForm';
import SponsorshipEventDay from './Sponsorship/EventDayFeedbackForm';

import EntriesPostEvent from './Entries/PostEventFeedbackForm';
import LiveEventPostEvent from './LiveEvent/PostEventFeedbackForm';
import PreEventPostEvent from './PreEvent/PostEventFeedbackForm';
import SponsorshipPostEvent from './Sponsorship/PostEventFeedbackForm';

const initialFormData = Object.freeze({
    department: ''
})

const initialEntriesPreEventData = Object.freeze({
    campaign_materials: '',
    campaign_materials_improvement: '',
    website_launch: '',
    website_launch_improvement: '',
    email_templates: '',
    email_templates_improvement: '',
    entries_campaign: '',
    entries_campaign_improvement: '',
    entries_difficulties: '',
    entries_difficulties_improvement: '',
    entries_delay: '',
    entries_delay_improvement: '',
    entrants_entry_issue: '',
    entrants_entry_issue_improvement: '',
    sl_deadline: '',
    sl_deadline_improvement: '',
    sl_announcement: '',
    sl_announcement_improvement: '',
    judge_recruit: '',
    judge_recruit_improvement: '',
    winners_approval: '',
    winners_approval_improvement: '',
    seat_allocation: '',
    seat_allocation_improvement: '',
    pre_event_preparation: '',
    event_preparation: ''
});

const initialEntriesEventDayData = Object.freeze({
    live_seat_plan: '',
    live_seat_plan_improvement: '',
    place_names: '',
    place_names_improvement: '',
    seat_allocation_easy_updates: '',
    seat_allocation_easy_updates_improvement: '',
    sorting_place_names: '',
    sorting_place_names_improvement: '',
    presenters_brief_flow: '',
    presenters_brief_flow_improvement: '',
    seat_plan_presenters: '',
    seat_plan_presenters_improvement: '',
    event_day_matters: '',
    venue_guest_feedback: '',
    event_process_improvement: ''
});

const initialEntriesPostEventData = Object.freeze({
    updated_accordingly_gallery: '',
    updated_accordingly_gallery_improvement: '',
    post_event_matters: '',
    post_event_process_improvement: ''
});

const initialLiveEventsPreEventData = Object.freeze({
    signoff_accurate: '',
    signoff_accurate_improvement: '',
    attendee_number_ontime: '',
    attendee_number_ontime_improvement: '',
    email_templates: '',
    email_templates_improvement: '',
    envelope_labels_sent: '',
    envelope_labels_sent_improvement: '',
    constant_communication: '',
    constant_communication_improvement: '',
    sponsor_collaterals_accurate: '',
    sponsor_collaterals_accurate_improvement: '',
    smooth_eperience_floorplan: '',
    smooth_eperience_floorplan_improvement: '',
    print_materials_accurate: '',
    print_materials_accurate_improvement: '',
    draft_script_sharing: '',
    draft_script_sharing_improvement: '',
    communication_smooth: '',
    communication_smooth_improvement: '',
    delivery_smoothly: '',
    delivery_smoothly_improvement: '',
    pre_event_preparation: '',
    suggestion_preparation: ''
});

const initialLiveEventsEventDayData = Object.freeze({
    seat_plan_uptodate_changes: '',
    seat_plan_uptodate_changes_improvement: '',
    setup_signed_off_venue: '',
    setup_signed_off_venue_improvement: '',
    update_seat_easy_understand: '',
    update_seat_easy_understand_improvement: '',
    printed_names_correct: '',
    printed_names_correct_improvement: '',
    enough_time_sorting_name: '',
    enough_time_sorting_name_improvement: '',
    materials_good_condition: '',
    materials_good_condition_improvement: '',
    trophies_good_condition: '',
    trophies_good_condition_improvement: '',
    setup_same_floor_plan: '',
    setup_same_floor_plan_improvement: '',
    item_setup_correctly: '',
    item_setup_correctly_improvement: '',
    graphic_correct_information: '',
    graphic_correct_information_improvement: '',
    host_rehearsal: '',
    host_rehearsal_improvement: '',
    avc_pre_event_rehearsal: '',
    avc_pre_event_rehearsal_improvement: '',
    items_services_expectation_met: '',
    items_services_expectation_met_improvement: '',
    photographer_good_job: '',
    photographer_good_job_improvement: '',
    food_expectation: '',
    food_expectation_improvement: '',
    trophy_assistant_perform: '',
    trophy_assistant_perform_improvement: '',
    band_expectation: '',
    band_expectation_improvement: '',
    host_perform_well: '',
    host_perform_well_improvement: '',
    matters_encountered: '',
    guests_feedback: '',
    suggestion_consideration: '',
});

const initialLiveEventsPostEventData = Object.freeze({
    materials_dismantled: '',
    materials_dismantled_improvement: '',
    materials_shipped_back: '',
    materials_shipped_back_improvement: '',
    matters_encountered_after: '',
    suggestion_consideration_post: '',
});

const initialPreEventPreEventData = Object.freeze({
    materials_campaign: '',
    materials_campaign_improvement: '',
    prepared_launch: '',
    prepared_launch_improvement: '',
    entries_submitted: '',
    entries_submitted_improvement: '',
    matched_tracker: '',
    matched_tracker_improvement: '',
    before_hand: '',
    before_hand_improvement: '',
    delay_entries: '',
    delay_entries_improvement: '',
    entries_went: '',
    entries_went_improvement: '',
    target_deadline: '',
    target_deadline_improvement: '',
    event_info: '',
    event_info_improvement: '',
    shortlist_announcement: '',
    shortlist_announcement_improvement: '',
    missing_item: '',
    missing_item_improvement: '',
    preparing_script: '',
    preparing_script_improvement: '',
    graphic_videos: '',
    graphic_videos_improvement: '',
    enough_judges: '',
    enough_judges_improvement: '',
    printed_materials: '',
    printed_materials_improvement: '',
    judging_period: '',
    judging_period_improvement: '',
    event_supplier: '',
    event_supplier_improvement: '',
    delivery_materials: '',
    delivery_materials_improvement: '',
    period_met: '',
    period_met_improvement: '',
    winners_signed: '',
    winners_signed_improvement: '',
    running_order: '',
    running_order_improvement: '',
    floorplan_setup: '',
    floorplan_setup_improvement: '',
    trophy_artworks: '',
    trophy_artworks_improvement: '',
    power_point: '',
    power_point_improvement: '',
    sponsorship_collaterals: '',
    sponsorship_collaterals_improvement: '',
    guest_list: '',
    guest_list_improvement: '',
    email_templates: '',
    email_templates_improvement: '',
    live_tweeting: '',
    live_tweeting_improvement: '',
    seat_allocation: '',
    seat_allocation_improvement: '',
    event_preparation_improvement: '',
    other_suggestion_improvement: '',
});

const initialPreEventEventDayData = Object.freeze({
    live_seat_plan_up_to_date: '',
    live_seat_plan_up_to_date_improvement: '',
    constant_communication: '',
    constant_communication_improvement: '',
    event_day_matters: '',
    guest_feedback: '',
    event_day_process_improvement: ''
});

const initialPreEventPostEventData = Object.freeze({
    gallery_on_time: '',
    gallery_on_time_improvement: '',
    gallery_ready: '',
    gallery_ready_improvement: '',
    post_event_email: '',
    post_event_email_improvement: '',
    post_event_materials: '',
    post_event_materials_improvement: '',
    post_event_matters: '',
    post_event_process_improvement: '',
});

const initialSponsorshipPreEventData = Object.freeze({
    materials_prepared: '',
    materials_prepared_improvement: '',
    website_prepared: '',
    website_prepared_improvement: '',
    emails_templates_sent: '',
    emails_templates_sent_improvement: '',
    difficulties_onboarding: '',
    difficulties_onboarding_improvement: '',
    shortlisting_process_thorough: '',
    shortlisting_process_thorough_improvement: '',
    consistent_communication: '',
    consistent_communication_improvement: '',
    target_deadline: '',
    target_deadline_improvement: '',
    website_shortlist_prepared: '',
    website_shortlist_prepared_improvement: '',
    sponsor_collateral: '',
    sponsor_collateral_improvement: '',
    graphic_render: '',
    graphic_render_improvement: '',
    summary_sponsor: '',
    summary_sponsor_improvement: '',
    floorplan_timely_manner: '',
    floorplan_timely_manner_improvement: '',
    enough_time_running: '',
    enough_time_running_improvement: '',
    proposed_floorplan: '',
    proposed_floorplan_improvement: '',
    seat_allocation_good: '',
    seat_allocation_good_improvement: '',
    communication_reminded: '',
    communication_reminded_improvement: '',
    collated_timely: '',
    collated_timely_improvement: '',
    transfer_communication: '',
    transfer_communication_improvement: '',
    encountered_pre_event_preparation: '',
    suggestion_preparation_event: '',
});

const initialSponsorshipEventDayData = Object.freeze({
    setup_same_venue: '',
    setup_same_venue_improvement: '',
    graphic_material_correct: '',
    graphic_material_correct_improvement: '',
    presenters_easy: '',
    presenters_easy_improvement: '',
    good_atmosphere: '',
    good_atmosphere_improvement: '',
    people_show: '',
    people_show_improvement: '',
    awards_buzz: '',
    awards_buzz_improvement: '',
    shout_cheer: '',
    shout_cheer_improvement: '',
    people_dance: '',
    people_dance_improvement: '',
    matters_encountered: '',
    guests_feedback: '',
    suggestion_consideration: ''
});

const initialSponsorshipPostEventData = Object.freeze({
    updated_accordingly: '',
    updated_accordingly_improvement: '',
    matters_encountered_after: '',
    suggestion_consideration_post: ''
});

const PostEventFeedback = ({ onClose, id, scrollToTop }) => {

    const [cookies] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'awardStatus', 'token']);
    let history = useHistory();

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    let query = useQuery();
    const award_id = query.get('award_id');

    const user_id = cookies.userDetails.id;
    const token = cookies.token;

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialFormData);
    const [formStatus, setFormStatus] = useState('standby');
    const [loading, setLoading] = useState(true);

    // Entries Department
    const [entriesPreEventData, setEntriesPreEventData] = useState(initialEntriesPreEventData);
    const [entriesEventDayData, setEntriesEventDayData] = useState(initialEntriesEventDayData);
    const [entriesPostEventData, setEntriesPostEventData] = useState(initialEntriesPostEventData);

    // Live Event Department
    const [liveEventPreEventData, setLiveEventPreEventData] = useState(initialLiveEventsPreEventData);
    const [liveEventEventDayData, setLiveEventEventDayData] = useState(initialLiveEventsEventDayData);
    const [liveEventPostEventData, setLiveEventPostEventData] = useState(initialLiveEventsPostEventData);

    // Pre Event Department
    const [preEventPreEventData, sePreEventPreEventData] = useState(initialPreEventPreEventData);
    const [preEventEventDayData, sePreEventEventDayData] = useState(initialPreEventEventDayData);
    const [preEventPostEventData, sePreEventPostEventData] = useState(initialPreEventPostEventData);

    // Sponsorship Department
    const [sponsorshipPreEventData, setSponsorshipPreEventData] = useState(initialSponsorshipPreEventData);
    const [sponsorshipEventDayData, setSponsorshipEventDayData] = useState(initialSponsorshipEventDayData);
    const [sponsorshipPostEventData, setSponsorshipPostEventData] = useState(initialSponsorshipPostEventData);

    const getPostEventFeedbacksSingle = async () =>
        await axios.get(
            process.env.REACT_APP_API_ENDPOINT + 'post-event/survey/' + id,
            {
                params: {
                    user_id: user_id,
                    token: token,
                },
            },
        );

    const putFeedbackForm = async (data) => {
        return await axios.put(process.env.REACT_APP_API_ENDPOINT + 'post-event/survey/' + id, data);
    }

    const handleChangeNextStep = (data) => {
        let department = formData.department;

        if (step < 3) {
            scrollToTop();
            switch (department) {
                case 'Entries':
                    handleChangeEntriesData(data);
                    break;
                case 'Live Events':
                    handleChangeLiveEventsData(data);
                    break;
                case 'Pre Event':
                    handleChangePreEventData(data);
                    break;
                case 'Sponsorship':
                    handleChangeSponsorshipData(data);
                    break;
                default:
                    break;
            }
        } else {
            setFormStatus('loading');
            const departmentPayloads = {
                'Entries': { ...entriesPreEventData, ...entriesEventDayData, ...data },
                'Live Events': { ...liveEventPreEventData, ...liveEventEventDayData, ...data },
                'Pre Event': { ...preEventPreEventData, ...preEventEventDayData, ...data },
                'Sponsorship': { ...sponsorshipPreEventData, ...sponsorshipEventDayData, ...data },
            }

            const payload = {
                award_id: award_id,
                user_id: user_id,
                department: department,
                status: 'Completed',
                response: departmentPayloads[department]
            }

            putFeedbackForm(payload).then(response => {
                const status = response.data.status;

                if (status === 'Success') {
                    const message = "Feedback form has been submitted!";
                    CustomToast.success(message);
                    history.push("/admin/award/feedbacks/list?award_id=" + award_id);
                    // window.location.reload();
                    setFormStatus('standby');
                    onClose('Submit');
                } else {
                    CustomToast.defaultWarning();
                    setFormStatus('standby');
                }
            }).catch(() => {
                CustomToast.defaultError();
                setFormStatus('standby');
            })

            return;
        }

        setStep(prev => prev + 1);
    }

    const handleChangePreviousStep = (data) => {
        const { department } = formData;
        scrollToTop();

        switch (department) {
            case 'Entries':
                handleChangeEntriesData(data);
                break;
            case 'Live Events':
                handleChangeLiveEventsData(data);
                break;
            case 'Pre Event':
                handleChangePreEventData(data);
                break;
            case 'Sponsorship':
                handleChangeSponsorshipData(data);
                break;
            default:
                break;
        }

        setStep(prev => prev - 1);
    }

    const handleChangeEntriesData = (data) => {
        switch (step) {
            case 1:
                setEntriesPreEventData(data);
                break;
            case 2:
                setEntriesEventDayData(data);
                break;
            case 3:
                setEntriesPostEventData(data);
                break;
            default:
                break;
        }
    }

    const handleChangeLiveEventsData = async (data) => {
        switch (step) {
            case 1:
                setLiveEventPreEventData(data);
                break;
            case 2:
                setLiveEventEventDayData(data);
                break;
            case 3:
                setLiveEventPostEventData(data);
                break;
            default:
                break;
        }
    }

    const handleChangePreEventData = async (data) => {
        switch (step) {
            case 1:
                sePreEventPreEventData(data);
                break;
            case 2:
                sePreEventEventDayData(data);
                break;
            case 3:
                sePreEventPostEventData(data);
                break;
            default:
                break;
        }
    }

    const handleChangeSponsorshipData = async (data) => {
        switch (step) {
            case 1:
                setSponsorshipPreEventData(data);
                break;
            case 2:
                setSponsorshipEventDayData(data);
                break;
            case 3:
                setSponsorshipPostEventData(data);
                break;
            default:
                break;
        }
    }

    const preEventForm = (currentDepartment) => {
        switch (currentDepartment) {
            case 'Entries':
                return <EntriesPreEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={entriesPreEventData} />;
            case 'Live Events':
                return <LiveEventPreEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={liveEventPreEventData} />;
            case 'Pre Event':
                return <PreEventPreEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={preEventPreEventData} />;
            case 'Sponsorship':
                return <SponsorshipPreEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={sponsorshipPreEventData} />;
            default:
                break;
        }
    }

    const eventDayForm = (currentDepartment) => {
        switch (currentDepartment) {
            case 'Entries':
                return <EntriesEventDay formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={entriesEventDayData} />;
            case 'Live Events':
                return <LiveEventEventDay formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={liveEventEventDayData} />;
            case 'Pre Event':
                return <PreEventEventDay formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={preEventEventDayData} />;
            case 'Sponsorship':
                return <SponsorshipEventDay formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={sponsorshipEventDayData} />;
            default:
                break;
        }
    }

    const postEventForm = (currentDepartment) => {
        switch (currentDepartment) {
            case 'Entries':
                return <EntriesPostEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={entriesPostEventData} formStatus={formStatus} />;
            case 'Live Events':
                return <LiveEventPostEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={liveEventPostEventData} formStatus={formStatus} />;
            case 'Pre Event':
                return <PreEventPostEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={preEventPostEventData} formStatus={formStatus} />;
            case 'Sponsorship':
                return <SponsorshipPostEvent formSubmit={handleChangeNextStep} onPrevious={handleChangePreviousStep} data={sponsorshipPostEventData} formStatus={formStatus} />;
            default:
                break;
        }
    }

    const stepPageProps = (currentStep) => {
        const { department } = formData;

        switch (currentStep) {
            case 1:
                return preEventForm(department);
            case 2:
                return eventDayForm(department);
            case 3:
                return postEventForm(department);
            default:
                break;
        }
    }

    useEffect(() => {
        getPostEventFeedbacksSingle().then(response => {
            const result = response.data.data;
            const survey = {
                department: result.department
            };
            setFormData(survey);
            setLoading(false);
        })
    }, [])

    return (
        <>
            <Card>
                <CardBody className='card_container'>
                    <div className='text-uppercase text-left'>
                        <button type='button' className='close' data-dismiss='modal' aria-label='Close' onClick={() => onClose()}>
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>
                    <div className="mx-3 text-center mb-4">
                        <h2
                            className="text-center award-subheader"
                            style={{ color: "#1ec2f3", fontSize: "22px" }}
                        >
                            Post-Event Feedback Form
                        </h2>
                    </div>
                    {loading ?
                        <PageSpinner />
                        :
                        <>
                            <Row>
                                <Col lg="12" className="text-center">
                                    <ul className="steps-progress mt-1 mb-4">
                                        <li>
                                            <div className="steps_img_container1 step-progress--done step-progress--active feedback-form-steps-container-size">
                                                <p className="first number-steps feedback-form-steps-number-size">
                                                    1
                                                </p>
                                            </div>
                                        </li>
                                        <li className={step > 1 ? "progress__bar step-progress--done" : "progress__bar"} ></li>
                                        <li>
                                            <div className={step > 1 ? "step-progress--done step-progress--active steps_img_container_active feedback-form-steps-container-size" : "steps_img_container feedback-form-steps-container-size"}>
                                                <p className={step > 1 ? "number_steps_active feedback-form-steps-number-size" : "number-steps feedback-form-steps-number-size"}>
                                                    2
                                                </p>
                                            </div>
                                        </li>
                                        <li className={step > 2 ? "progress__bar step-progress--done" : "progress__bar"} ></li>
                                        <li>
                                            <div className={step > 2 ? "step-progress--done step-progress--active steps_img_container_active feedback-form-steps-container-size" : "steps_img_container feedback-form-steps-container-size"}>
                                                <p className={step > 2 ? "number_steps_active feedback-form-steps-number-size" : "number-steps feedback-form-steps-number-size"}>
                                                    3
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row className="mx-1">
                                <Col md="12" sm="12" xs="12" className="p-1">
                                    <p className="required font-italic">Your feedback is important to our event's success, as it enables us to uphold and enhance standards and ensure a positive experience for all participants.</p>
                                    <hr />
                                    <p className="asterisk_red">* Indicates required question</p>
                                </Col>
                                <Col lg="12" className="p-1">
                                    {stepPageProps(step)}
                                </Col>
                            </Row>
                        </>
                    }
                </CardBody>
            </Card>
        </>
    );
};

export default PostEventFeedback;
