import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "materials_campaign",
        "materials_campaign_improvement",
        "prepared_launch",
        "prepared_launch_improvement",
        "entries_submitted",
        "entries_submitted_improvement",
        "matched_tracker",
        "matched_tracker_improvement",
        "before_hand",
        "before_hand_improvement",
        "delay_entries",
        "delay_entries_improvement",
        "entries_went",
        "entries_went_improvement",
        "target_deadline",
        "target_deadline_improvement",
        "event_info",
        "event_info_improvement",
        "shortlist_announcement",
        "shortlist_announcement_improvement",
        "missing_item",
        "missing_item_improvement",
        "preparing_script",
        "preparing_script_improvement",
        "graphic_videos",
        "graphic_videos_improvement",
        "enough_judges",
        "enough_judges_improvement",
        "printed_materials",
        "printed_materials_improvement",
        "judging_period",
        "judging_period_improvement",
        "event_supplier",
        "event_supplier_improvement",
        "delivery_materials",
        "delivery_materials_improvement",
        "period_met",
        "period_met_improvement",
        "winners_signed",
        "winners_signed_improvement",
        "running_order",
        "running_order_improvement",
        "floorplan_setup",
        "floorplan_setup_improvement",
        "trophy_artworks",
        "trophy_artworks_improvement",
        "power_point",
        "power_point_improvement",
        "sponsorship_collaterals",
        "sponsorship_collaterals_improvement",
        "guest_list",
        "guest_list_improvement",
        "email_templates",
        "email_templates_improvement",
        "live_tweeting",
        "live_tweeting_improvement",
        "seat_allocation",
        "seat_allocation_improvement",
        "event_preparation_improvement",
        "other_suggestion_improvement",
    ];

    const [materialsCampaignImprovement, setMaterialsCampaignImprovement] = useState({ required: false });
    const [preparedLaunchImprovement, setPreparedLaunchImprovement] = useState({ required: false });
    const [entriesSubmittedImprovement, setEntriesSubmittedImprovement] = useState({ required: false });
    const [matchedTrackerImprovement, setMatchedTrackerImprovement] = useState({ required: false });
    const [beforeHandImprovement, setBeforeHandImprovement] = useState({ required: false });
    const [delayEntriesImprovement, setDelayEntriesImprovement] = useState({ required: false });
    const [entriesWentImprovement, setEntriesWentImprovement] = useState({ required: false });
    const [targetDeadlineImprovement, setTargetDealineImprovement] = useState({ required: false });
    const [eventInfoImprovement, setEventInfoImprovement] = useState({ required: false });
    const [shortlistAnnouncementImprovement, setshortlistAnnouncementImprovement] = useState({ required: false });
    const [missingItemImprovement, setMissingItemImprovement] = useState({ required: false });
    const [preparingScriptImprovement, setPreparingScriptImprovement] = useState({ required: false });
    const [graphicVideosImprovement, setGraphicVideosImprovement] = useState({ required: false });
    const [enoughJudgesImprovement, setEnoughJudgesImprovement] = useState({ required: false });
    const [printedMaterialsImprovement, setPrintedMaterialsImprovement] = useState({ required: false });
    const [judgingPeriodImprovement, setJudgingPeriodImprovement] = useState({ required: false });
    const [eventSupplierImprovement, setEventSupplierImprovement] = useState({ required: false });
    const [deliveryMaterialsImprovement, setDeliveryMaterialsImprovement] = useState({ required: false });
    const [periodMetImprovement, setPeriodMetImprovement] = useState({ required: false });
    const [winnersSignedImprovement, setWinnersSignedImprovement] = useState({ required: false });
    const [runningOrderImprovement, setRunningOrderImprovement] = useState({ required: false });
    const [floorplanSetupImprovement, setFloorplanSetupImprovement] = useState({ required: false });
    const [trophyArtworksImprovement, setTrophyArtworksImprovement] = useState({ required: false });
    const [powerPointImprovement, setPowerPointImprovement] = useState({ required: false });
    const [sponsorshipCollateralsImprovement, setSponsorshipCollateralsImprovement] = useState({ required: false });
    const [guestListImprovement, setGuestListImprovement] = useState({ required: false });
    const [emailTemplatesImprovement, setEmailTemplatesImprovement] = useState({ required: false });
    const [liveTweetingImprovement, setLiveTweetingImprovement] = useState({ required: false });
    const [seatAllocationImprovement, setSeatAllocationImprovement] = useState({ required: false });
    const [eventPreparationImprovement] = useState({ required: false });
    const [otherSuggestionImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "materials_campaign": (value) => setMaterialsCampaignImprovement(value),
                "prepared_launch": (value) => setPreparedLaunchImprovement(value),
                "entries_submitted": (value) => setEntriesSubmittedImprovement(value),
                "matched_tracker": (value) => setMatchedTrackerImprovement(value),
                "before_hand": (value) => setBeforeHandImprovement(value),
                "delay_entries": (value) => setDelayEntriesImprovement(value),
                "entries_went": (value) => setEntriesWentImprovement(value),
                "target_deadline": (value) => setTargetDealineImprovement(value),
                "event_info": (value) => setEventInfoImprovement(value),
                "shortlist_announcement": (value) => setshortlistAnnouncementImprovement(value),
                "missing_item": (value) => setMissingItemImprovement(value),
                "preparing_script": (value) => setPreparingScriptImprovement(value),
                "graphic_videos": (value) => setGraphicVideosImprovement(value),
                "enough_judges": (value) => setEnoughJudgesImprovement(value),
                "printed_materials": (value) => setPrintedMaterialsImprovement(value),
                "judging_period": (value) => setJudgingPeriodImprovement(value),
                "event_supplier": (value) => setEventSupplierImprovement(value),
                "delivery_materials": (value) => setDeliveryMaterialsImprovement(value),
                "period_met": (value) => setPeriodMetImprovement(value),
                "winners_signed": (value) => setWinnersSignedImprovement(value),
                "running_order": (value) => setRunningOrderImprovement(value),
                "floorplan_setup": (value) => setFloorplanSetupImprovement(value),
                "trophy_artworks": (value) => setTrophyArtworksImprovement(value),
                "power_point": (value) => setPowerPointImprovement(value),
                "sponsorship_collaterals": (value) => setSponsorshipCollateralsImprovement(value),
                "guest_list": (value) => setGuestListImprovement(value),
                "email_templates": (value) => setEmailTemplatesImprovement(value),
                "live_tweeting": (value) => setLiveTweetingImprovement(value),
                "seat_allocation": (value) => setSeatAllocationImprovement(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* materials_campaign */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were the materials for the campaign prepared? (e.g., Event Guide, Master Scoresheet, Sample Winning Entry, Etc.)
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_campaign_yes"
                            type='radio'
                            {...register("materials_campaign", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_campaign_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_campaign_no"
                            type='radio'
                            {...register("materials_campaign", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_campaign_no">
                            No 
                        </Label>
                    </p>
                    {errors.materials_campaign ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_campaign.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {materialsCampaignImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("materials_campaign_improvement", {
                            ...materialsCampaignImprovement
                        })}
                        className={`form-control ${errors.materials_campaign_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.materials_campaign_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_campaign_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Is the website properly prepared for the launch?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="prepared_launch_yes"
                            type='radio'
                            {...register("prepared_launch", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="prepared_launch_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="prepared_launch_no"
                            type='radio'
                            {...register("prepared_launch", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="prepared_launch_no">
                            No 
                        </Label>
                    </p>
                    {errors.prepared_launch ? <p role="alert" className="invalid-feedback mb-0">{errors.prepared_launch.message}</p> : null}

                    <Label className='my-2'>
                        kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {preparedLaunchImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("prepared_launch_improvement", {
                            ...preparedLaunchImprovement
                        })}
                        className={`form-control ${errors.prepared_launch_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.prepared_launch_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.prepared_launch_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did all the entries get submitted through the BIS?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_submitted_yes"
                            type='radio'
                            {...register("entries_submitted", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_submitted_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_submitted_no"
                            type='radio'
                            {...register("entries_submitted", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_submitted_no">
                            No 
                        </Label>
                    </p>
                    {errors.entries_submitted ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_submitted.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entriesSubmittedImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entries_submitted_improvement", {
                            ...entriesSubmittedImprovement
                        })}
                        className={`form-control ${errors.entries_submitted_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entries_submitted_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_submitted_improvement.message}</p> : null}
                </CardBody>
            </Card>
                        
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were all the entries submitted through the BIS matched with the Q tracker of the entries team?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="matched_tracker_yes"
                            type='radio'
                            {...register("matched_tracker", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="matched_tracker_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="matched_tracker_no"
                            type='radio'
                            {...register("matched_tracker", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="matched_tracker_no">
                            No 
                        </Label>
                    </p>
                    {errors.matched_tracker ? <p role="alert" className="invalid-feedback mb-0">{errors.matched_tracker.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {matchedTrackerImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("matched_tracker_improvement", {
                            ...matchedTrackerImprovement
                        })}
                        className={`form-control ${errors.matched_tracker_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.matched_tracker_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.matched_tracker_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                    
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were all the details in BIS prepared beforehand (e.g., inserting the categories and criteria, adding the ratings and comments sections, updating the dates of the event)?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="before_hand_yes"
                            type='radio'
                            {...register("before_hand", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="before_hand_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="before_hand_no"
                            type='radio'
                            {...register("before_hand", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="before_hand_no">
                            No 
                        </Label>
                    </p>
                    {errors.before_hand ? <p role="alert" className="invalid-feedback mb-0">{errors.before_hand.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {beforeHandImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("before_hand_improvement", {
                            ...beforeHandImprovement
                        })}
                        className={`form-control ${errors.before_hand_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.before_hand_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.before_hand_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Is there any delay in the submission of entries?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delay_entries_yes"
                            type='radio'
                            {...register("delay_entries", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delay_entries_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delay_entries_no"
                            type='radio'
                            {...register("delay_entries", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delay_entries_no">
                            No 
                        </Label>
                    </p>
                    {errors.delay_entries ? <p role="alert" className="invalid-feedback mb-0">{errors.delay_entries.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {delayEntriesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("delay_entries_improvement", {
                            ...delayEntriesImprovement
                        })}
                        className={`form-control ${errors.delay_entries_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.delay_entries_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.delay_entries_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did the processing of entries went smoothly?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_went_yes"
                            type='radio'
                            {...register("entries_went", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_went_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_went_no"
                            type='radio'
                            {...register("entries_went", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_went_no">
                            No 
                        </Label>
                    </p>
                    {errors.entries_went ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_went.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entriesWentImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entries_went_improvement", {
                            ...entriesWentImprovement
                        })}
                        className={`form-control ${errors.entries_went_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entries_went_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_went_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the target deadline for the shortlist announcement met?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="target_deadline_yes"
                            type='radio'
                            {...register("target_deadline", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="target_deadline_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="target_deadline_no"
                            type='radio'
                            {...register("target_deadline", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="target_deadline_no">
                            No 
                        </Label>
                    </p>
                    {errors.target_deadline ? <p role="alert" className="invalid-feedback mb-0">{errors.target_deadline.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {targetDeadlineImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("target_deadline_improvement", {
                            ...targetDeadlineImprovement
                        })}
                        className={`form-control ${errors.target_deadline_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.target_deadline_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.target_deadline_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the Event Information sent for sign-off accurate and complete?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="event_info_yes"
                            type='radio'
                            {...register("event_info", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="event_info_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="event_info_no"
                            type='radio'
                            {...register("event_info", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="event_info_no">
                            No 
                        </Label>
                    </p>
                    {errors.event_info ? <p role="alert" className="invalid-feedback mb-0">{errors.event_info.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {eventInfoImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("event_info_improvement", {
                            ...eventInfoImprovement
                        })}
                        className={`form-control ${errors.event_info_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_info_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.event_info_improvement.message}</p> : null}
                </CardBody>
            </Card>
                        
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Is the website properly prepared for the shortlist announcement?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shortlist_announcement_yes"
                            type='radio'
                            {...register("shortlist_announcement", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shortlist_announcement_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shortlist_announcement_no"
                            type='radio'
                            {...register("shortlist_announcement", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shortlist_announcement_no">
                            No 
                        </Label>
                    </p>
                    {errors.shortlist_announcement ? <p role="alert" className="invalid-feedback mb-0">{errors.shortlist_announcement.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {shortlistAnnouncementImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("shortlist_announcement_improvement", {
                            ...shortlistAnnouncementImprovement
                        })}
                        className={`form-control ${errors.shortlist_announcement_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.shortlist_announcement_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.shortlist_announcement_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                   
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did the collection of missing item collaterals and entry title confirmation through the BIS go smoothly?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="missing_item_yes"
                            type='radio'
                            {...register("missing_item", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="missing_item_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="missing_item_no"
                            type='radio'
                            {...register("missing_item", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="missing_item_no">
                            No 
                        </Label>
                    </p>
                    {errors.missing_item ? <p role="alert" className="invalid-feedback mb-0">{errors.missing_item.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {missingItemImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("missing_item_improvement", {
                            ...missingItemImprovement
                        })}
                        className={`form-control ${errors.missing_item_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.missing_item_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.missing_item_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                   
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were there any issues encountered in preparing the script through the BIS?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="preparing_script_yes"
                            type='radio'
                            {...register("preparing_script", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="preparing_script_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="preparing_script_no"
                            type='radio'
                            {...register("preparing_script", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="preparing_script_no">
                            No 
                        </Label>
                    </p>
                    {errors.preparing_script ? <p role="alert" className="invalid-feedback mb-0">{errors.preparing_script.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {preparingScriptImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("preparing_script_improvement", {
                            ...preparingScriptImprovement
                        })}
                        className={`form-control ${errors.preparing_script_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.preparing_script_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.preparing_script_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                               
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were there any issues encountered in preparing the graphic videos for the event? 
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_videos_yes"
                            type='radio'
                            {...register("graphic_videos", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_videos_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_videos_no"
                            type='radio'
                            {...register("graphic_videos", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_videos_no">
                            No 
                        </Label>
                    </p>
                    {errors.graphic_videos ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_videos.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {graphicVideosImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("graphic_videos_improvement", {
                            ...graphicVideosImprovement
                        })}
                        className={`form-control ${errors.graphic_videos_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.graphic_videos_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_videos_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                           
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was there enough judges recruited for the event?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_judges_yes"
                            type='radio'
                            {...register("enough_judges", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_judges_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_judges_no"
                            type='radio'
                            {...register("enough_judges", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_judges_no">
                            No 
                        </Label>
                    </p>
                    {errors.enough_judges ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_judges.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {enoughJudgesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("enough_judges_improvement", {
                            ...enoughJudgesImprovement
                        })}
                        className={`form-control ${errors.enough_judges_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.enough_judges_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_judges_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                                       
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did the preparation of artwork for printed materials go well? 
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="printed_materials_yes"
                            type='radio'
                            {...register("printed_materials", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="printed_materials_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="printed_materials_no"
                            type='radio'
                            {...register("printed_materials", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="printed_materials_no">
                            No 
                        </Label>
                    </p>
                    {errors.printed_materials ? <p role="alert" className="invalid-feedback mb-0">{errors.printed_materials.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {printedMaterialsImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("printed_materials_improvement", {
                            ...printedMaterialsImprovement
                        })}
                        className={`form-control ${errors.printed_materials_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.printed_materials_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.printed_materials_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                                                   
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did the judging period go well?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="judging_period_yes"
                            type='radio'
                            {...register("judging_period", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="judging_period_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="judging_period_no"
                            type='radio'
                            {...register("judging_period", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="judging_period_no">
                            No 
                        </Label>
                    </p>
                    {errors.judging_period ? <p role="alert" className="invalid-feedback mb-0">{errors.judging_period.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {judgingPeriodImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("judging_period_improvement", {
                            ...judgingPeriodImprovement
                        })}
                        className={`form-control ${errors.judging_period_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.judging_period_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.judging_period_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were there any issues encountered in submitting the materials for the event to the supplier (e.g., DPC, Lazer Now, AVC, Trish, etc.)
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="event_supplier_yes"
                            type='radio'
                            {...register("event_supplier", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="event_supplier_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="event_supplier_no"
                            type='radio'
                            {...register("event_supplier", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="event_supplier_no">
                            No 
                        </Label>
                    </p>
                    {errors.event_supplier ? <p role="alert" className="invalid-feedback mb-0">{errors.event_supplier.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {eventSupplierImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("event_supplier_improvement", {
                            ...eventSupplierImprovement
                        })}
                        className={`form-control ${errors.event_supplier_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_supplier_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.event_supplier_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were there any issues encountered in the pick-up and delivery of materials for the event to the venue?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delivery_materials_yes"
                            type='radio'
                            {...register("delivery_materials", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delivery_materials_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delivery_materials_no"
                            type='radio'
                            {...register("delivery_materials", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delivery_materials_no">
                            No 
                        </Label>
                    </p>
                    {errors.delivery_materials ? <p role="alert" className="invalid-feedback mb-0">{errors.delivery_materials.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {deliveryMaterialsImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("delivery_materials_improvement", {
                            ...deliveryMaterialsImprovement
                        })}
                        className={`form-control ${errors.delivery_materials_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.delivery_materials_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.delivery_materials_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the target deadline for the judging period met? 
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="period_met_yes"
                            type='radio'
                            {...register("period_met", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="period_met_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="period_met_no"
                            type='radio'
                            {...register("period_met", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="period_met_no">
                            No 
                        </Label>
                    </p>
                    {errors.period_met ? <p role="alert" className="invalid-feedback mb-0">{errors.period_met.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {periodMetImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("period_met_improvement", {
                            ...periodMetImprovement
                        })}
                        className={`form-control ${errors.period_met_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.period_met_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.period_met_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was there enough time for the winners to be signed off both internally and externally?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="winners_signed_yes"
                            type='radio'
                            {...register("winners_signed", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="winners_signed_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="winners_signed_no"
                            type='radio'
                            {...register("winners_signed", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="winners_signed_no">
                            No 
                        </Label>
                    </p>
                    {errors.winners_signed ? <p role="alert" className="invalid-feedback mb-0">{errors.winners_signed.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {winnersSignedImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("winners_signed_improvement", {
                            ...winnersSignedImprovement
                        })}
                        className={`form-control ${errors.winners_signed_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.winners_signed_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.winners_signed_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the target deadline for signing off the Running Order of categories met?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="running_order_yes"
                            type='radio'
                            {...register("running_order", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="running_order_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="running_order_no"
                            type='radio'
                            {...register("running_order", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="running_order_no">
                            No 
                        </Label>
                    </p>
                    {errors.running_order ? <p role="alert" className="invalid-feedback mb-0">{errors.running_order.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {runningOrderImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("running_order_improvement", {
                            ...runningOrderImprovement
                        })}
                        className={`form-control ${errors.running_order_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.running_order_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.running_order_improvement.message}</p> : null}
                </CardBody>
            </Card>
                        
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the floorplan set up in a timely manner?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="floorplan_setup_yes"
                            type='radio'
                            {...register("floorplan_setup", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="floorplan_setup_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="floorplan_setup_no"
                            type='radio'
                            {...register("floorplan_setup", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="floorplan_setup_no">
                            No 
                        </Label>
                    </p>
                    {errors.floorplan_setup ? <p role="alert" className="invalid-feedback mb-0">{errors.floorplan_setup.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {floorplanSetupImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("floorplan_setup_improvement", {
                            ...floorplanSetupImprovement
                        })}
                        className={`form-control ${errors.floorplan_setup_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.floorplan_setup_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.floorplan_setup_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                    
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the target deadline in providing the artworks to the suppliers met (e.g.,trophy artworks, Printed Materials, etcetera)?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophy_artworks_yes"
                            type='radio'
                            {...register("trophy_artworks", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophy_artworks_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophy_artworks_no"
                            type='radio'
                            {...register("trophy_artworks", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophy_artworks_no">
                            No 
                        </Label>
                    </p>
                    {errors.trophy_artworks ? <p role="alert" className="invalid-feedback mb-0">{errors.trophy_artworks.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {trophyArtworksImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("trophy_artworks_improvement", {
                            ...trophyArtworksImprovement
                        })}
                        className={`form-control ${errors.trophy_artworks_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.trophy_artworks_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.trophy_artworks_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were the graphic videos, script and powerpoint sent to AVC 2 days before the event day?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="power_point_yes"
                            type='radio'
                            {...register("power_point", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="power_point_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="power_point_no"
                            type='radio'
                            {...register("power_point", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="power_point_no">
                            No 
                        </Label>
                    </p>
                    {errors.power_point ? <p role="alert" className="invalid-feedback mb-0">{errors.power_point.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {powerPointImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("power_point_improvement", {
                            ...powerPointImprovement
                        })}
                        className={`form-control ${errors.power_point_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.power_point_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.power_point_improvement.message}</p> : null}
                </CardBody>
            </Card>
                                                
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were there any issues encountered in collating the sponsorship collaterals for the event materials (e.g., adverts, logos, etc.)? 
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsorship_collaterals_yes"
                            type='radio'
                            {...register("sponsorship_collaterals", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsorship_collaterals_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsorship_collaterals_no"
                            type='radio'
                            {...register("sponsorship_collaterals", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsorship_collaterals_no">
                            No 
                        </Label>
                    </p>
                    {errors.sponsorship_collaterals ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsorship_collaterals.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {sponsorshipCollateralsImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sponsorship_collaterals_improvement", {
                            ...sponsorshipCollateralsImprovement
                        })}
                        className={`form-control ${errors.sponsorship_collaterals_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sponsorship_collaterals_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsorship_collaterals_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Did the gathering of the guest list and trophy recipients go smoothly?  
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="guest_list_yes"
                            type='radio'
                            {...register("guest_list", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="guest_list_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="guest_list_no"
                            type='radio'
                            {...register("guest_list", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="guest_list_no">
                            No 
                        </Label>
                    </p>
                    {errors.guest_list ? <p role="alert" className="invalid-feedback mb-0">{errors.guest_list.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {guestListImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("guest_list_improvement", {
                            ...guestListImprovement
                        })}
                        className={`form-control ${errors.guest_list_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.guest_list_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.guest_list_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were the post-event materials and email templates prepared before the event day? 
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="email_templates_yes"
                            type='radio'
                            {...register("email_templates", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="email_templates_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="email_templates_no"
                            type='radio'
                            {...register("email_templates", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="email_templates_no">
                            No 
                        </Label>
                    </p>
                    {errors.email_templates ? <p role="alert" className="invalid-feedback mb-0">{errors.email_templates.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {emailTemplatesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("email_templates_improvement", {
                            ...emailTemplatesImprovement
                        })}
                        className={`form-control ${errors.email_templates_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.email_templates_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.email_templates_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Were the materials for the live tweeting finalised 3 days before the event day and already briefed to the SMT?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_tweeting_yes"
                            type='radio'
                            {...register("live_tweeting", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_tweeting_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_tweeting_no"
                            type='radio'
                            {...register("live_tweeting", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_tweeting_no">
                            No 
                        </Label>
                    </p>
                    {errors.live_tweeting ? <p role="alert" className="invalid-feedback mb-0">{errors.live_tweeting.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {liveTweetingImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("live_tweeting_improvement", {
                            ...liveTweetingImprovement
                        })}
                        className={`form-control ${errors.live_tweeting_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.live_tweeting_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.live_tweeting_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Was the seat allocation for entrants good?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_yes"
                            type='radio'
                            {...register("seat_allocation", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_no"
                            type='radio'
                            {...register("seat_allocation", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_allocation ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation.message}</p> : null}

                    <Label className='my-2'>
                    Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatAllocationImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_allocation_improvement", {
                            ...seatAllocationImprovement
                        })}
                        className={`form-control ${errors.seat_allocation_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_allocation_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_improvement.message}</p> : null}
                </CardBody>
            </Card>
            
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Any other matters that have been encountered during the pre-event preparation?&nbsp;
                        {eventPreparationImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("event_preparation_improvement", {
                            ...eventPreparationImprovement
                        })}
                        className={`form-control ${errors.event_preparation_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_preparation_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.event_preparation_improvement.message}</p> : null}
                </CardBody>
            </Card>
                        
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                    Any other suggestion that we can take into consideration to improve our preparations for the event? &nbsp;
                        {otherSuggestionImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("other_suggestion_improvement", {
                            ...otherSuggestionImprovement
                        })}
                        className={`form-control ${errors.other_suggestion_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.other_suggestion_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.other_suggestion_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;