import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import AdminSeatPlanPageV2 from './AdminSeatPlanPageV2';
import AdminSeatPlanPageV3 from './AdminSeatPlanPageV3';
import AdminSeatPlanPageV4 from './AdminSeatPlanPageV4';
import AdminSeatPlanPageV5 from './AdminSeatPlanPageV5';
import AdminSeatPlanPageV6 from './AdminSeatPlanPageV6';
import AdminSeatPlanPageV7 from './AdminSeatPlanPageV7';
import AdminSeatPlanPageV8 from './AdminSeatPlanPageV8';
import AdminSeatPlanPageV9 from './AdminSeatPlanPageV9';
import AdminSeatPlanPageV10 from './AdminSeatPlanPageV10';
import AdminSeatPlanPageV11 from './AdminSeatPlanPageV11';

const AdminVenueSeatingPage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const version = query.get('version');

  return (
    <>
      {version == 1 ?
        <AdminSeatPlanPageV2/>
        : version == 2 ?
          <AdminSeatPlanPageV3/>
          : version == 3 ?
            <AdminSeatPlanPageV4/>
            : version == 4 ?
              <AdminSeatPlanPageV5/>
              : version == 5 ?
                <AdminSeatPlanPageV6/>
                : version == 6 ?
                  <AdminSeatPlanPageV7/>
                  : version == 7 ?
                    <AdminSeatPlanPageV8/>
                    : version == 8 ?
                      <AdminSeatPlanPageV9/>
                      : version == 9 ?
                        <AdminSeatPlanPageV10/>
                        : <AdminSeatPlanPageV10/>
      }
    </>
  )
}

export default AdminVenueSeatingPage;
