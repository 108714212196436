import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "seat_plan_uptodate_changes",
        "seat_plan_uptodate_changes_improvement",
        "setup_signed_off_venue",
        "setup_signed_off_venue_improvement",
        "update_seat_easy_understand",
        "update_seat_easy_understand_improvement",
        "printed_names_correct",
        "printed_names_correct_improvement",
        "enough_time_sorting_name",
        "enough_time_sorting_name_improvement",
        "materials_good_condition",
        "materials_good_condition_improvement",
        "trophies_good_condition",
        "trophies_good_condition_improvement",
        "setup_same_floor_plan",
        "setup_same_floor_plan_improvement",
        "item_setup_correctly",
        "item_setup_correctly_improvement",
        "graphic_correct_information",
        "graphic_correct_information_improvement",
        "host_rehearsal",
        "host_rehearsal_improvement",
        "avc_pre_event_rehearsal",
        "avc_pre_event_rehearsal_improvement",
        "items_services_expectation_met",
        "items_services_expectation_met_improvement",
        "photographer_good_job",
        "photographer_good_job_improvement",
        "food_expectation",
        "food_expectation_improvement",
        "trophy_assistant_perform",
        "trophy_assistant_perform_improvement",
        "band_expectation",
        "band_expectation_improvement",
        "host_perform_well",
        "host_perform_well_improvement",
        "matters_encountered",
        "guests_feedback",
        "suggestion_consideration",
    ];

    const [seatPlanUpToDateChangesImprovment, setSeatPlanUpToDateChangesImprovment] = useState({ required: false });
    const [setupSignedOffVenueImprovment, setSetupSignedOffVenueImprovment] = useState({ required: false });
    const [updateSeatEasyUnderstandImprovment, setUpdateSeatEasyUnderstandImprovment] = useState({ required: false });
    const [printedNamesCorrectImprovment, setPrintedNamesCorrectImprovment] = useState({ required: false });
    const [enoughTimeSortingNameImprovment, setEnoughTimeSortingNameImprovment] = useState({ required: false });
    const [materialsGoodConditionImprovment, setMaterialsGoodConditionImprovment] = useState({ required: false });
    const [trophiesGoodConditionImprovment, setTrophiesGoodConditionImprovment] = useState({ required: false });
    const [setupSameFloorPlanImprovment, setSetupSameFloorPlanImprovment] = useState({ required: false });
    const [itemSetupCorrectlyImprovment, setItemSetupCorrectlyImprovment] = useState({ required: false });
    const [graphicCorrectInformationImprovment, setGraphicCorrectInformationImprovment] = useState({ required: false });
    const [hostRehearsalImprovment, setHostRehearsalImprovment] = useState({ required: false });
    const [avcPreEventRehearsalImprovment, setAvcPreEventRehearsalImprovment] = useState({ required: false });
    const [itemsServicesExpectationMetImprovment, setItemsServicesExpectationMetImprovment] = useState({ required: false });
    const [photographerGoodJobImprovment, setPhotographerGoodJobImprovment] = useState({ required: false });
    const [foodExpectationImprovment, setFoodExpectationImprovment] = useState({ required: false });
    const [trophyAssistantPerformImprovment, setTrophyAssistantPerformImprovment] = useState({ required: false });
    const [bandExpectationImprovment, setBandExpectationImprovment] = useState({ required: false });
    const [hostPerformWellImprovment, setHostPerformWellImprovment] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "seat_plan_uptodate_changes": (value) => setSeatPlanUpToDateChangesImprovment(value),
                "setup_signed_off_venue": (value) => setSetupSignedOffVenueImprovment(value),
                "update_seat_easy_understand": (value) => setUpdateSeatEasyUnderstandImprovment(value),
                "printed_names_correct": (value) => setPrintedNamesCorrectImprovment(value),
                "enough_time_sorting_name": (value) => setEnoughTimeSortingNameImprovment(value),
                "materials_good_condition": (value) => setMaterialsGoodConditionImprovment(value),
                "trophies_good_condition": (value) => setTrophiesGoodConditionImprovment(value),
                "setup_same_floor_plan": (value) => setSetupSameFloorPlanImprovment(value),
                "item_setup_correctly": (value) => setItemSetupCorrectlyImprovment(value),
                "graphic_correct_information": (value) => setGraphicCorrectInformationImprovment(value),
                "host_rehearsal": (value) => setHostRehearsalImprovment(value),
                "avc_pre_event_rehearsal": (value) => setAvcPreEventRehearsalImprovment(value),
                "items_services_expectation_met": (value) => setItemsServicesExpectationMetImprovment(value),
                "photographer_good_job": (value) => setPhotographerGoodJobImprovment(value),
                "food_expectation": (value) => setFoodExpectationImprovment(value),
                "trophy_assistant_perform": (value) => setTrophyAssistantPerformImprovment(value),
                "band_expectation": (value) => setBandExpectationImprovment(value),
                "host_perform_well": (value) => setHostPerformWellImprovment(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* seat_plan_uptodate_changes */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is the live seat plan always kept up to date with any changes received from the attendees?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_plan_uptodate_changes_yes"
                            type='radio'
                            {...register("seat_plan_uptodate_changes", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_plan_uptodate_changes_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_plan_uptodate_changes_no"
                            type='radio'
                            {...register("seat_plan_uptodate_changes", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_plan_uptodate_changes_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_plan_uptodate_changes ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_plan_uptodate_changes.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatPlanUpToDateChangesImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_plan_uptodate_changes_improvement", {
                            ...seatPlanUpToDateChangesImprovment
                        })}
                        className={`form-control ${errors.seat_plan_uptodate_changes_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_plan_uptodate_changes_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_plan_uptodate_changes_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* setup_signed_off_venue */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the stage setup that was signed off the same as what was set up at the venue?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_signed_off_venue_yes"
                            type='radio'
                            {...register("setup_signed_off_venue", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_signed_off_venue_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_signed_off_venue_no"
                            type='radio'
                            {...register("setup_signed_off_venue", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_signed_off_venue_no">
                            No 
                        </Label>
                    </p>
                    {errors.setup_signed_off_venue ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_signed_off_venue.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {setupSignedOffVenueImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("setup_signed_off_venue_improvement", {
                            ...setupSignedOffVenueImprovment
                        })}
                        className={`form-control ${errors.setup_signed_off_venue_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.setup_signed_off_venue_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_signed_off_venue_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* update_seat_easy_understand */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Are the updates in seat allocation of guests easy to understand?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="update_seat_easy_understand_yes"
                            type='radio'
                            {...register("update_seat_easy_understand", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="update_seat_easy_understand_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="update_seat_easy_understand_no"
                            type='radio'
                            {...register("update_seat_easy_understand", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="update_seat_easy_understand_no">
                            No 
                        </Label>
                    </p>
                    {errors.update_seat_easy_understand ? <p role="alert" className="invalid-feedback mb-0">{errors.update_seat_easy_understand.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updateSeatEasyUnderstandImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("update_seat_easy_understand_improvement", {
                            ...updateSeatEasyUnderstandImprovment
                        })}
                        className={`form-control ${errors.update_seat_easy_understand_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.update_seat_easy_understand_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.update_seat_easy_understand_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* printed_names_correct */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Are most of the printed place names correct?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="printed_names_correct_yes"
                            type='radio'
                            {...register("printed_names_correct", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="printed_names_correct_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="printed_names_correct_no"
                            type='radio'
                            {...register("printed_names_correct", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="printed_names_correct_no">
                            No 
                        </Label>
                    </p>
                    {errors.printed_names_correct ? <p role="alert" className="invalid-feedback mb-0">{errors.printed_names_correct.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {printedNamesCorrectImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("printed_names_correct_improvement", {
                            ...printedNamesCorrectImprovment
                        })}
                        className={`form-control ${errors.printed_names_correct_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.printed_names_correct_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.printed_names_correct_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* enough_time_sorting_name */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did you have enough time sorting the place names?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_time_sorting_name_yes"
                            type='radio'
                            {...register("enough_time_sorting_name", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_time_sorting_name_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_time_sorting_name_no"
                            type='radio'
                            {...register("enough_time_sorting_name", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_time_sorting_name_no">
                            No 
                        </Label>
                    </p>
                    {errors.enough_time_sorting_name ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_time_sorting_name.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {enoughTimeSortingNameImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("enough_time_sorting_name_improvement", {
                            ...enoughTimeSortingNameImprovment
                        })}
                        className={`form-control ${errors.enough_time_sorting_name_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.enough_time_sorting_name_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_time_sorting_name_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* materials_good_condition */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the printed materials that were delivered to the venue correct and in good condition?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_good_condition_yes"
                            type='radio'
                            {...register("materials_good_condition", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_good_condition_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_good_condition_no"
                            type='radio'
                            {...register("materials_good_condition", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_good_condition_no">
                            No 
                        </Label>
                    </p>
                    {errors.materials_good_condition ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_good_condition.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {materialsGoodConditionImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("materials_good_condition_improvement", {
                            ...materialsGoodConditionImprovment
                        })}
                        className={`form-control ${errors.materials_good_condition_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.materials_good_condition_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_good_condition_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* trophies_good_condition */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the trophies that were delivered to the venue correct and in good condition?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophies_good_condition_yes"
                            type='radio'
                            {...register("trophies_good_condition", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophies_good_condition_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophies_good_condition_no"
                            type='radio'
                            {...register("trophies_good_condition", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophies_good_condition_no">
                            No 
                        </Label>
                    </p>
                    {errors.trophies_good_condition ? <p role="alert" className="invalid-feedback mb-0">{errors.trophies_good_condition.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {trophiesGoodConditionImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("trophies_good_condition_improvement", {
                            ...trophiesGoodConditionImprovment
                        })}
                        className={`form-control ${errors.trophies_good_condition_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.trophies_good_condition_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.trophies_good_condition_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* setup_same_floor_plan */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the venue setup the same as what was laid out in the floor plan?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_same_floor_plan_yes"
                            type='radio'
                            {...register("setup_same_floor_plan", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_same_floor_plan_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_same_floor_plan_no"
                            type='radio'
                            {...register("setup_same_floor_plan", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_same_floor_plan_no">
                            No 
                        </Label>
                    </p>
                    {errors.setup_same_floor_plan ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_same_floor_plan.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {setupSameFloorPlanImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("setup_same_floor_plan_improvement", {
                            ...setupSameFloorPlanImprovment
                        })}
                        className={`form-control ${errors.setup_same_floor_plan_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.setup_same_floor_plan_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_same_floor_plan_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* item_setup_correctly */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were all the logistic items setup correctly and are in place (e.g., Podium, microphone, etcetera)?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="item_setup_correctly_yes"
                            type='radio'
                            {...register("item_setup_correctly", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="item_setup_correctly_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="item_setup_correctly_no"
                            type='radio'
                            {...register("item_setup_correctly", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="item_setup_correctly_no">
                            No 
                        </Label>
                    </p>
                    {errors.item_setup_correctly ? <p role="alert" className="invalid-feedback mb-0">{errors.item_setup_correctly.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {itemSetupCorrectlyImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("item_setup_correctly_improvement", {
                            ...itemSetupCorrectlyImprovment
                        })}
                        className={`form-control ${errors.item_setup_correctly_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.item_setup_correctly_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.item_setup_correctly_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* graphic_correct_information */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Are the graphic materials prepared for the event with correct information and as expected?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_correct_information_yes"
                            type='radio'
                            {...register("graphic_correct_information", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_correct_information_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_correct_information_no"
                            type='radio'
                            {...register("graphic_correct_information", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_correct_information_no">
                            No 
                        </Label>
                    </p>
                    {errors.graphic_correct_information ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_correct_information.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {graphicCorrectInformationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("graphic_correct_information_improvement", {
                            ...graphicCorrectInformationImprovment
                        })}
                        className={`form-control ${errors.graphic_correct_information_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.graphic_correct_information_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_correct_information_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* host_rehearsal */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the rehearsal with the host went well?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="host_rehearsal_yes"
                            type='radio'
                            {...register("host_rehearsal", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="host_rehearsal_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="host_rehearsal_no"
                            type='radio'
                            {...register("host_rehearsal", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="host_rehearsal_no">
                            No 
                        </Label>
                    </p>
                    {errors.host_rehearsal ? <p role="alert" className="invalid-feedback mb-0">{errors.host_rehearsal.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {hostRehearsalImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("host_rehearsal_improvement", {
                            ...hostRehearsalImprovment
                        })}
                        className={`form-control ${errors.host_rehearsal_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.host_rehearsal_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.host_rehearsal_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* avc_pre_event_rehearsal */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the rehearsal with AVC and the pre-event team went well?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="avc_pre_event_rehearsal_yes"
                            type='radio'
                            {...register("avc_pre_event_rehearsal", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="avc_pre_event_rehearsal_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="avc_pre_event_rehearsal_no"
                            type='radio'
                            {...register("avc_pre_event_rehearsal", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="avc_pre_event_rehearsal_no">
                            No 
                        </Label>
                    </p>
                    {errors.avc_pre_event_rehearsal ? <p role="alert" className="invalid-feedback mb-0">{errors.avc_pre_event_rehearsal.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {avcPreEventRehearsalImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("avc_pre_event_rehearsal_improvement", {
                            ...avcPreEventRehearsalImprovment
                        })}
                        className={`form-control ${errors.avc_pre_event_rehearsal_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.avc_pre_event_rehearsal_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.avc_pre_event_rehearsal_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* items_services_expectation_met */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the expectations regarding the items/services provided by the suppliers met?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="items_services_expectation_met_yes"
                            type='radio'
                            {...register("items_services_expectation_met", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="items_services_expectation_met_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="items_services_expectation_met_no"
                            type='radio'
                            {...register("items_services_expectation_met", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="items_services_expectation_met_no">
                            No 
                        </Label>
                    </p>
                    {errors.items_services_expectation_met ? <p role="alert" className="invalid-feedback mb-0">{errors.items_services_expectation_met.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {itemsServicesExpectationMetImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("items_services_expectation_met_improvement", {
                            ...itemsServicesExpectationMetImprovment
                        })}
                        className={`form-control ${errors.items_services_expectation_met_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.items_services_expectation_met_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.items_services_expectation_met_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* photographer_good_job */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the photographer do a good job in capturing all the important aspects of the venue?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="photographer_good_job_yes"
                            type='radio'
                            {...register("photographer_good_job", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="photographer_good_job_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="photographer_good_job_no"
                            type='radio'
                            {...register("photographer_good_job", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="photographer_good_job_no">
                            No 
                        </Label>
                    </p>
                    {errors.photographer_good_job ? <p role="alert" className="invalid-feedback mb-0">{errors.photographer_good_job.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {photographerGoodJobImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("photographer_good_job_improvement", {
                            ...photographerGoodJobImprovment
                        })}
                        className={`form-control ${errors.photographer_good_job_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.photographer_good_job_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.photographer_good_job_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* food_expectation */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Based on your observations, did the food served at the venue meet the guests' expectations?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="food_expectation_yes"
                            type='radio'
                            {...register("food_expectation", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="food_expectation_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="food_expectation_no"
                            type='radio'
                            {...register("food_expectation", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="food_expectation_no">
                            No 
                        </Label>
                    </p>
                    {errors.food_expectation ? <p role="alert" className="invalid-feedback mb-0">{errors.food_expectation.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {foodExpectationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("food_expectation_improvement", {
                            ...foodExpectationImprovment
                        })}
                        className={`form-control ${errors.food_expectation_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.food_expectation_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.food_expectation_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* trophy_assistant_perform */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the trophy assistant perform well in her position?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophy_assistant_perform_yes"
                            type='radio'
                            {...register("trophy_assistant_perform", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophy_assistant_perform_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="trophy_assistant_perform_no"
                            type='radio'
                            {...register("trophy_assistant_perform", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="trophy_assistant_perform_no">
                            No 
                        </Label>
                    </p>
                    {errors.trophy_assistant_perform ? <p role="alert" className="invalid-feedback mb-0">{errors.trophy_assistant_perform.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {trophyAssistantPerformImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("trophy_assistant_perform_improvement", {
                            ...trophyAssistantPerformImprovment
                        })}
                        className={`form-control ${errors.trophy_assistant_perform_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.trophy_assistant_perform_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.trophy_assistant_perform_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* band_expectation */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Based on your observations, did the band meet the attendees expectations?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="band_expectation_yes"
                            type='radio'
                            {...register("band_expectation", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="band_expectation_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="band_expectation_no"
                            type='radio'
                            {...register("band_expectation", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="band_expectation_no">
                            No 
                        </Label>
                    </p>
                    {errors.band_expectation ? <p role="alert" className="invalid-feedback mb-0">{errors.band_expectation.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {bandExpectationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("band_expectation_improvement", {
                            ...bandExpectationImprovment
                        })}
                        className={`form-control ${errors.band_expectation_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.band_expectation_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.band_expectation_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* host_perform_well */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the host perform well? &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="host_perform_well_yes"
                            type='radio'
                            {...register("host_perform_well", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="host_perform_well_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="host_perform_well_no"
                            type='radio'
                            {...register("host_perform_well", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="host_perform_well_no">
                            No 
                        </Label>
                    </p>
                    {errors.host_perform_well ? <p role="alert" className="invalid-feedback mb-0">{errors.host_perform_well.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {hostPerformWellImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("host_perform_well_improvement", {
                            ...hostPerformWellImprovment
                        })}
                        className={`form-control ${errors.host_perform_well_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.host_perform_well_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.host_perform_well_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered during the event day? &nbsp;
                    </Label>
                    <textarea
                        {...register("matters_encountered", {
                            required: false
                        })}
                        className={`form-control ${errors.matters_encountered ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.matters_encountered ? <p role="alert" className="invalid-feedback mb-0">{errors.matters_encountered.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is there any feedback that you received from the guests while you are at the venue? &nbsp;
                    </Label>
                    <textarea
                        {...register("guests_feedback", {
                            required: false
                        })}
                        className={`form-control ${errors.guests_feedback ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.guests_feedback ? <p role="alert" className="invalid-feedback mb-0">{errors.guests_feedback.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes during the event day? &nbsp;
                    </Label>
                    <textarea
                        {...register("suggestion_consideration", {
                            required: false
                        })}
                        className={`form-control ${errors.suggestion_consideration ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.suggestion_consideration ? <p role="alert" className="invalid-feedback mb-0">{errors.suggestion_consideration.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;