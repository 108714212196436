import { Content, Footer, Header, Sidebar } from 'components/Layout';
import React, {useState, useEffect} from 'react';
import { useLocation, BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import ChatWidget from '../ChatWidget';
import PostEventFeedbackAlert from '../PostEventFeedback/PostEventFeedbackAlert';
import PageSpinner from '../PageSpinner';

const MainLayout = (props) => {
  const { children } = props;
  const [cookies, setCookie, removeCookie] = useCookies(['reloadHeader', 'currentUser', 'isLoggedIn', 'award_id', 'section', 'token', 'userDetails', 'awardStatus']);
  const currentUser = cookies.currentUser;
  const location = useLocation()
  const currentPathName = location.pathname;
  const [pageReady, setPageReady] = useState(false);
  const section = cookies.section;
  const awardStatus = cookies.awardStatus;
  const award_id = Number(cookies.award_id);

  useEffect(() => {
    if (currentUser) {
      setPageReady(true);
    } else {
      if (currentPathName == "/register" || currentPathName == "/invoice/payment/success" || currentPathName == '/price-quote/summary' || currentPathName == "/bank/transfer" || currentPathName == "/bank/transfer/terms" || currentPathName == "/report" || currentPathName == "/award/book" || currentPathName == "/award/booking" || currentPathName == '/award/booking/v1' || currentPathName == '/award/booking/v2' || currentPathName == '/award/book/seats/v2' || currentPathName == '/award/book/v2' || currentPathName == '/award/book/v1' || currentPathName == '/award/book/seats/v1' || currentPathName == "/award/book/form" || currentPathName == '/award/book/form/seats' || currentPathName == "/award/book/seats" || currentPathName == "/award/event" || currentPathName == "/booking/summary" || currentPathName == "/booking/summary/unlisted" || currentPathName == "/booking/summary/v2" || currentPathName == "/booking/details" || currentPathName == "/booking/payment/success" || currentPathName == '/booking/payment/success/v2' || currentPathName == "/booking/attendees" || currentPathName == "/booking/attendees/setup" || currentPathName == "/category/winner" || currentPathName == "/award/winner" || currentPathName == "/award/nomination" || currentPathName == "/winner/summary" || currentPathName == "/winner/payment/success" || currentPathName == "/bank/transfer" || currentPathName == "/bank/transfer/terms" || currentPathName == "/tickets" || currentPathName == "/entry/payment/success") {
        setPageReady(true);
      }
    }
  },[currentUser])

  return (
    <>
      {pageReady ?
        <main className="cr-app bg-light">
          {/*<Sidebar />*/}
          <Content fluid>
            <Header />
            <PostEventFeedbackAlert />
            {children}
            {(section === 'entrant' && award_id > 53 && awardStatus !== 'Closed') &&
              (
                <ChatWidget/>
              )
            }
          </Content>
        </main>
      :
        <main className="cr-app bg-light">
          <Content fluid>
            <Header />
            <div className='container text-center py-5'>
              <PageSpinner />
            </div>
          </Content>
        </main>
      }
      {/* <Footer /> */}
    </>
  );
}

export default MainLayout;
