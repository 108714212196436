import BisAuthForm, { STATE_BIS_LOGIN } from 'components/BisAuthForm';
import React from 'react';
import { Card, Col, Row, Container } from 'reactstrap';
import { useLocation, Link, useHistory } from 'react-router-dom';
import BISLogoWhite from '../assets/img/logo/Business-River-Logo-White.png';
import { useCookies } from 'react-cookie';


const MaintenancePage = (props) => {
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const section = query.get('section');
  const award_id = query.get('award_id');
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'isLoggedIn']);
  

  const logOut = (e) => {
    setCookies('currentUser', "", { path: '/' });
    setCookies('isLoggedIn', false, { path: '/' });
    setCookies('userRole', "", { path: '/' });
    setCookies('judge', 0, { path: '/' });
    setCookies('judge_id', 0, { path: '/' });
    setCookies('coordinator_id', 0, { path: '/' });
    history.push("/entrant/login?award_id="+award_id+"&section="+section);
  }

  return (
    <>
      <div className="VEPbackground d-flex align-items-center pt-5 pb-5">
          <Container className="text-center justify-content-center">
              <Row>
                <Col lg="12">
                  <h1 className='text-white font-weight-bold'>COMING SOON!</h1>
                  <p className='text-white'>Our website is under construction.</p>
                  <button type="button" onClick={logOut} className="btn newbtntheme logout-btn no-shadow">Logout</button>
                </Col>
              </Row>
          </Container>
      </div>
    </>
  );
}

export default MaintenancePage;