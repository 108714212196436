import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data, formStatus }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "gallery_on_time",
        "gallery_on_time_improvement",
        "gallery_ready",
        "gallery_ready_improvement",
        "post_event_email",
        "post_event_email_improvement",
        "post_event_materials",
        "post_event_materials_improvement",
        "post_event_matters",
        "post_event_process_improvement",
    ];

    const [updatedAccordinglyGalleryImprovement, setUpdatedAccordinglyGalleryImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "gallery_on_time": (value) => setUpdatedAccordinglyGalleryImprovement(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* gallery_on_time */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Were the photos uploaded to the gallery on time and without any issues encountered?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="gallery_on_time_yes"
                            type='radio'
                            {...register("gallery_on_time", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="gallery_on_time_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="gallery_on_time_no"
                            type='radio'
                            {...register("gallery_on_time", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="gallery_on_time_no">
                            No 
                        </Label>
                    </p>
                    {errors.gallery_on_time ? <p role="alert" className="invalid-feedback mb-0">{errors.gallery_on_time.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updatedAccordinglyGalleryImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("gallery_on_time_improvement", {
                            ...updatedAccordinglyGalleryImprovement
                        })}
                        className={`form-control ${errors.gallery_on_time_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.gallery_on_time_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.gallery_on_time_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* gallery_ready */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was the other team notified when the gallery was ready?&nbps;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="gallery_ready_yes"
                            type='radio'
                            {...register("gallery_ready", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="gallery_ready_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="gallery_ready_no"
                            type='radio'
                            {...register("gallery_ready", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="gallery_ready_no">
                            No 
                        </Label>
                    </p>
                    {errors.gallery_ready ? <p role="alert" className="invalid-feedback mb-0">{errors.gallery_ready.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updatedAccordinglyGalleryImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("gallery_ready_improvement", {
                            ...updatedAccordinglyGalleryImprovement
                        })}
                        className={`form-control ${errors.gallery_ready_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.gallery_ready_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.gallery_ready_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_email */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was the post event email sent to the attendees on time?&nbps;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="post_event_email_yes"
                            type='radio'
                            {...register("post_event_email", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="post_event_email_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="post_event_email_no"
                            type='radio'
                            {...register("post_event_email", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="post_event_email_no">
                            No 
                        </Label>
                    </p>
                    {errors.post_event_email ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_email.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updatedAccordinglyGalleryImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("post_event_email_improvement", {
                            ...updatedAccordinglyGalleryImprovement
                        })}
                        className={`form-control ${errors.post_event_email_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_email_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_email_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_materials */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Are all post event materials sent out to those who avail them?&nbps;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="post_event_materials_yes"
                            type='radio'
                            {...register("post_event_materials", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="post_event_materials_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="post_event_materials_no"
                            type='radio'
                            {...register("post_event_materials", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="post_event_materials_no">
                            No 
                        </Label>
                    </p>
                    {errors.post_event_materials ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_materials.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updatedAccordinglyGalleryImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("post_event_materials_improvement", {
                            ...updatedAccordinglyGalleryImprovement
                        })}
                        className={`form-control ${errors.post_event_materials_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_materials_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_materials_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_matters */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered after the event day?&nbsp;
                    </Label>
                    <textarea
                        {...register("post_event_matters", {
                            required: false
                        })}
                        className={`form-control ${errors.post_event_matters ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_matters ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_matters.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_process_improvement */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes post event?&nbsp;
                    </Label>
                    <textarea
                        {...register("post_event_process_improvement", {
                            required: false
                        })}
                        className={`form-control ${errors.post_event_process_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_process_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_process_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit" disabled={formStatus !== 'standby'}>{formStatus === 'standby' ? 'SAVE' : 'SAVING...'}</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;