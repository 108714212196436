import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { TabContent, TabPane, Nav, NavItem, Alert, Card, CardHeader, CardBody, CardFooter, Row, Col, Modal, ModalBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { Input, Container } from 'reactstrap';
import PageSpinner from 'components/PageSpinner';
import { FaArrowLeft, FaCheck } from 'react-icons/fa';
import { toJpeg } from 'html-to-image';
import Carousel from 'react-grid-carousel';
import axios from 'axios';
import softworks from '../assets/img/logo-cms/softworks.png';
import icon from '../assets/img/icon.png';
import irishTimes from '../assets/img/logo-cms/irish-times-logo.png';
import elis from '../assets/img/company-logos/elis.png';
import crannog from '../assets/img/company-logos/crannog.png';
import winesDirect from '../assets/img/company-logos/wines-direct.JPG';
import esb from '../assets/img/company-logos/esb.jpg';
import larnrod from '../assets/img/company-logos/larnrod.JPG';
import kirby from '../assets/img/company-logos/kirby.jpg';
import lmc from '../assets/img/company-logos/lmc.png';
import mitie from '../assets/img/company-logos/mitie.png';
import bam from '../assets/img/company-logos/bam.jpg';
import lotusWorks from '../assets/img/company-logos/lotus-works.jpg';
import faithfulGould from '../assets/img/company-logos/faithful-gould.JPG';
import fastway from '../assets/img/company-logos/fastway.png';
import leckey from '../assets/img/company-logos/leckey.png';
import mannok from '../assets/img/company-logos/mannok.png';
import tirlan from '../assets/img/company-logos/tirlan.jpg';
import gasNetworks from '../assets/img/company-logos/gas-networks.png';
import sensori from '../assets/img/company-logos/sensori.jpg';
import abbvie from '../assets/img/company-logos/abbvie.png';
import allview from '../assets/img/company-logos/allview.jpg';
import keltech from '../assets/img/company-logos/keltech.jpg';
import bridgewater from '../assets/img/company-logos/bridgewater.jpg';
import guildhall from '../assets/img/company-logos/guildhall.jpg';
import ruralWifi from '../assets/img/company-logos/rural-wifi.png';
import workhuman from '../assets/img/company-logos/workhuman.png';
import donoghue from '../assets/img/company-logos/donoghue.jpg';
import dpd from '../assets/img/company-logos/dpd.png';
import george from '../assets/img/company-logos/george.jpg';
import mias from '../assets/img/company-logos/mias.png';
import centric from '../assets/img/company-logos/centric.jpg';
import comfort from '../assets/img/company-logos/comfort.png';
import uisce from '../assets/img/company-logos/uisce.png';
import viking from '../assets/img/company-logos/viking.jpg';
import abbott from '../assets/img/company-logos/abbott.jpeg';
import griffithResidence from '../assets/img/company-logos/griffith-residence.png';
import griffithFaculty from '../assets/img/company-logos/griffith-faculty.jpg';
import west from '../assets/img/company-logos/west.jpg';
import anpost from '../assets/img/company-logos/anpost.png';
import eurofins from '../assets/img/company-logos/eurofins.PNG';
import ventac from '../assets/img/company-logos/ventac.png';
import breedon from '../assets/img/company-logos/breedon.png';
import primark from '../assets/img/company-logos/primark.jpg';
import kerrygold from '../assets/img/company-logos/kerrygold.png';
import southCoast from '../assets/img/company-logos/south-coast.jpg';
import skibbereen from '../assets/img/company-logos/skibbereen.jpg';
import salesforce from '../assets/img/company-logos/salesforce.png';
import atu from '../assets/img/company-logos/atu.jpg';
import cheshire from '../assets/img/company-logos/cheshire.jpg';
import iss from '../assets/img/company-logos/iss.png';
import teagasc from '../assets/img/company-logos/teagasc.jpg';
import cxv from '../assets/img/company-logos/cxv.png';
import kora from '../assets/img/company-logos/kora.png';
import metabolomic from '../assets/img/company-logos/metabolomic.jpeg';
import dentaqua from '../assets/img/company-logos/dentaqua.jpg';
import boston from '../assets/img/company-logos/boston-scientific.svg';
import almac from '../assets/img/company-logos/almac.png';
import medtronic from '../assets/img/company-logos/medtronic.jpg';
import mcardle from '../assets/img/company-logos/mcardle.png';
import cuh from '../assets/img/company-logos/cuh.jpg';
import iconPlc from '../assets/img/company-logos/icon-plc.jpg';
import janssen from '../assets/img/company-logos/janssen.png';
import syncrophi from '../assets/img/company-logos/syncrophi.jpg';
import cbre from '../assets/img/company-logos/cbre.jpg';
import pfizer from '../assets/img/company-logos/pfizer.png';
import deciphex from '../assets/img/company-logos/deciphex.png';
import ptc from '../assets/img/company-logos/ptc.jpg';
import abbotLsa from '../assets/img/company-logos/abbott-lsa.png';
import dmf from '../assets/img/company-logos/dmf.png';
import wuxi from '../assets/img/company-logos/wuxi.png';
import cec from '../assets/img/company-logos/cec.JPG';
import arrotek from '../assets/img/company-logos/arrotek.png';
import fsi from '../assets/img/company-logos/fsi.jpg';
import asgard from '../assets/img/company-logos/asgard.png';
import byrne from '../assets/img/company-logos/byrne.png';
import mcr from '../assets/img/company-logos/mcr.jpg';
import hgf from '../assets/img/company-logos/hgf.png';
import kuehne from '../assets/img/company-logos/kuehne.png';
import synergy from '../assets/img/company-logos/synergy.jpg';
import dhl from '../assets/img/company-logos/dhl.jpg';
import atuLsa from '../assets/img/company-logos/atu-lsa.jpg';
import lilly from '../assets/img/company-logos/lilly.jpg';
import tuDublin from '../assets/img/company-logos/tu-dublin.png';
import symphysis from '../assets/img/company-logos/symphysis.png';
import unispace from '../assets/img/company-logos/unispace-life-sciences.png';
import viatris from '../assets/img/company-logos/viatris.png';
import businessriver from '../assets/img/company-logos/businessriver.jpg';
import actylis from '../assets/img/company-logos/actylis.png';
import aldi from '../assets/img/company-logos/aldi.png';
import alexion from '../assets/img/company-logos/alexion.png';
import arthurCox from '../assets/img/company-logos/arthur-cox.jpg';
import clayton from '../assets/img/company-logos/clayton.jpg';
import dairygold from '../assets/img/company-logos/dairygold.png';
import dublinBus from '../assets/img/company-logos/dublin-bus.jpg';
import ebay from '../assets/img/company-logos/ebay.jpg';
import garrett from '../assets/img/company-logos/garrett.jpg';
import harmac from '../assets/img/company-logos/harmac.jpg';
import graham from '../assets/img/company-logos/graham.jpg';
import lidl from '../assets/img/company-logos/lidl.jpg';
import mahon from '../assets/img/company-logos/mahon.png';
import matheson from '../assets/img/company-logos/matheson.png';
import meritmedical from '../assets/img/company-logos/meritmedical.jpeg';
import queenUniversity from '../assets/img/company-logos/queen-university.png';
import refillz from '../assets/img/company-logos/refillz.jpg';
import sig from '../assets/img/company-logos/sig.png';
import swords from '../assets/img/company-logos/swords.png';
import samuel from '../assets/img/company-logos/samuel.jpg';
import thermo from '../assets/img/company-logos/thermo.jpg';
import wren from '../assets/img/company-logos/wren.png';
import jacobs from '../assets/img/company-logos/jacobs.png';
import isea from '../assets/img/company-logos/isea.jpg';
import climate from '../assets/img/company-logos/climate.png';
import aviva from '../assets/img/company-logos/aviva.jpg';
import abp from '../assets/img/company-logos/abp.jpg';
import gilead from '../assets/img/company-logos/gilead.png';
import analog from '../assets/img/company-logos/analog.jpeg';
import rcsi from '../assets/img/company-logos/rcsi.jpg';
import isa123 from '../assets/img/company-logos/123.ie.png';
import allianz from '../assets/img/company-logos/allianz.jpeg';
import avivaIsa from '../assets/img/company-logos/aviva-isa.jpg';
import electricIreland from '../assets/img/company-logos/electric-ireland.png';
import footballAssociationOfIreland from '../assets/img/company-logos/football-association-of-ireland.png';
import justEat from '../assets/img/company-logos/just-eat.png';
import kpmg from '../assets/img/company-logos/kpmg.jpg';
import legacyCommunications from '../assets/img/company-logos/legacy-communications.png';
import liveNation from '../assets/img/company-logos/live-nation.png';
import punchestownRacecourseEventVenue from '../assets/img/company-logos/punchestown-tacecourse-event-venue.png';
import pwc from '../assets/img/company-logos/pwc.jpg';
import rte from '../assets/img/company-logos/rte.png';
import sage from '../assets/img/company-logos/sage.png';
import supermacs from '../assets/img/company-logos/supermacs.jpg';
import theBigIdea from '../assets/img/company-logos/the-big-idea.png';
import urbanMedia from '../assets/img/company-logos/urban-media.png';
import urbanmedia from '../assets/img/company-logos/urban-media.png';
import badmintonIreland from '../assets/img/company-logos/badminton-ireland.png';
import bordGaisEnergy from '../assets/img/company-logos/bord-gais-energy.jpg';
import irishLand from '../assets/img/company-logos/irish-life.png';
import permanentTsb from '../assets/img/company-logos/permanent-tsb.png';
import vhi from '../assets/img/company-logos/vhi.jpg';
import bwgFoodsSparIteland from '../assets/img/company-logos/bwg-foods-spar-ireland.jpg';
import dublinInternationalFilmFestival from '../assets/img/company-logos/dublin-international-film-festival.jpg';
import esbIsa from '../assets/img/company-logos/esb.jpg';
import forefrontSports from '../assets/img/company-logos/forefront-sports.webp';
import greyhoundRacingIreland from '../assets/img/company-logos/greyhound-racing-ireland.png';
import horseRacingIreland from '../assets/img/company-logos/horse-racing-ireland.png';
import kick from '../assets/img/company-logos/kick.png';
import pentlandBrandsCanterbury from '../assets/img/company-logos/pentland-brands-canterbury.jpg';
import specialOlympicsIreland from '../assets/img/company-logos/special-olympics-ireland.png';
import specialOlympicsIrelandEir from '../assets/img/company-logos/special-olympics-ireland.png';
import teneo from '../assets/img/company-logos/teneo.png';
import theCamogieAssocation from '../assets/img/company-logos/the-camogie-association.jpg';
import titanExperience from '../assets/img/company-logos/titan-experience.png';
import pinergy from '../assets/img/company-logos/pinergy.png';
import aerLingus from '../assets/img/company-logos/aer-lingus.png';
import futureProofOfMedia from '../assets/img/company-logos/future-proof-of-media.png';
import imageMedia from '../assets/img/company-logos/image-media.png';
import leaderKicking from '../assets/img/company-logos/leader-kicking.png';
import newsIreland from '../assets/img/company-logos/news-ireland.jpg';
import zenith from '../assets/img/company-logos/zenith.jpg';
import mindshareMediaIreland from '../assets/img/company-logos/mindshare-media-ireland.jpg';
import sweartaker from '../assets/img/company-logos/sweartaker.jpg';
import aig from '../assets/img/company-logos/aig.png';
import cleanCoast from '../assets/img/company-logos/clean-coasts.png';
import glenveagh from '../assets/img/company-logos/glenveagh.png';
import businessRiverIsa from '../assets/img/company-logos/businessriver.jpg';
import mediahuisIreland from '../assets/img/company-logos/mediahuis-ireland.jpg';
import nationalConcertHall from '../assets/img/company-logos/national-concert-hall.jpg';
import theSundayTimes from '../assets/img/company-logos/the-sunday-times.jpg';
import accordHealthcare from '../assets/img/company-logos/accord-healthcare.jpg';
import almacGroup from '../assets/img/company-logos/almac.png';
import biopharmaDynamics from '../assets/img/company-logos/biopharma-dynamics.png';
import bristolLaboratoriesLtd from '../assets/img/company-logos/bristol-laboratories-ltd.jpg';
import businessRiver from '../assets/img/company-logos/businessriver.jpg';
import cellomaticsBiosciencesLimited from '../assets/img/company-logos/cellomatics-biosciences-limited.png';
import chiesi from '../assets/img/company-logos/chiesi.png';
import diaceuticsPlc from '../assets/img/company-logos/diaceutics-plc.png';
import hertilityHealth from '../assets/img/company-logos/hertility-health.png';
import insife from '../assets/img/company-logos/insife.jpg';
import inspiredPharmaTrainingLtd from '../assets/img/company-logos/inspired-pharma-training-ltd.jpg';
import ipsenBioharmLtd from '../assets/img/company-logos/ipsen-biopharma-ltd.png';
import kiwiBiosciences from '../assets/img/company-logos/kiwi-biosciences.png';
import kuehneNagelLtd from '../assets/img/company-logos/kuehne.png';
import medisonal from '../assets/img/company-logos/medisonal.jpg';
import micregen from '../assets/img/company-logos/micregen.jpg';
import pantheraBiopartners from '../assets/img/company-logos/panthera-biopartners.png';
import paradigmShiftConsultingLimited from '../assets/img/company-logos/paradigm-shift-consuling-limited.jpg';
import pharsafer from '../assets/img/company-logos/pharsafer.jpg';
import rosemontPharmaceuticals from '../assets/img/company-logos/rosemont-pharmaceuticals.jpg';
import sedaPharmaceuticalDevelopmentServices from '../assets/img/company-logos/seda-pharmaceutical-development-services.jpeg';
import semarion from '../assets/img/company-logos/semarion.png';
import taurxPharmaceuticalsLtd from '../assets/img/company-logos/taurx-pharmaceuticals-ltd.png';
import tiroTrainingLtd from '../assets/img/company-logos/tiro-training-ltd.jpg';
import uptake from '../assets/img/company-logos/uptake.png';
import dawsongroup from '../assets/img/company-logos/dawsongroup.png';
import skycell from '../assets/img/company-logos/skycell.png';
import silverCloud from '../assets/img/company-logos/silver-cloud.png';
import hotPress from '../assets/img/company-logos/hot-press.png';
import agility from '../assets/img/company-logos/agility.png';
import aquaclinical from '../assets/img/company-logos/aquaclinical.jpg';
import arecor from '../assets/img/company-logos/arecor.jpg';
import astrazeneca from '../assets/img/company-logos/astrazeneca.png';
import besinsHealthcareUkLimited from '../assets/img/company-logos/besins-healthcare-uk-limited.jpg';
import biocare from '../assets/img/company-logos/biocair.png';
import boydsConsultans from '../assets/img/company-logos/boyds-consultants.jpg';
import deepxHealth from '../assets/img/company-logos/deepx-health.jpeg';
import dexcelPharma from '../assets/img/company-logos/dexcel-pharma.png';
import internationalHealthPartners from '../assets/img/company-logos/international-health-partners.png';
import kingstonUniversityLondon from '../assets/img/company-logos/kingston-university-london.png';
import kyowaKirin from '../assets/img/company-logos/kyowa-kirin.png';
import microBioIrelandLtd from '../assets/img/company-logos/micro-bio-ireland-ltd.jpg';
import pharmanovia from '../assets/img/company-logos/pharmanovia.png';
import universityCollegeLondonUcl from '../assets/img/company-logos/university-college-london-ucl.jpg';
import universityOfEastAnglia from '../assets/img/company-logos/university-of-east-anglia.png';
import bionow from '../assets/img/company-logos/bionow.jpg';
import cognizant from '../assets/img/company-logos/cognizant.jpg';
import unispaceLifeSciences from '../assets/img/company-logos/unispace-life-sciences.png';
import approachPeopleRecruitment from '../assets/img/company-logos/approach-people-recruitment.png';
import busEirann from '../assets/img/company-logos/bus-eireann.png';
import businessriverHr from '../assets/img/company-logos/businessriver.jpg';
import cartonHouseResort from '../assets/img/company-logos/carton-house-resort.jpg';
import chadwicksGroup from '../assets/img/company-logos/chadwicks-group.png';
import circet from '../assets/img/company-logos/circet.png';
import clunetech from '../assets/img/company-logos/clunetech.jpg';
import complianceAndRisks from '../assets/img/company-logos/compliance-and-risks.png';
import copaceticBusinessSolutionLtd from '../assets/img/company-logos/copacetic-business-solution-ltd.jpg';
import cpl from '../assets/img/company-logos/cpl.png';
import cpmIreland from '../assets/img/company-logos/cpm-ireland.jpg';
import cyaraIrelandLimited from '../assets/img/company-logos/cyara-ireland-limited.png';
import decare from '../assets/img/company-logos/decare.png';
import deparmentOfHealth from '../assets/img/company-logos/department-of-health.png';
import deparmentOfTransport from '../assets/img/company-logos/department-of-transport.png';
import digitalMarketingInstitute from '../assets/img/company-logos/digital-marketing-institute.jpg';
import dpdIreland from '../assets/img/company-logos/dpd-ireland.jpg';
import firstDerivative from '../assets/img/company-logos/first-derivative.jpg';
import fragomenIrelandLtd from '../assets/img/company-logos/fragomen-ireland-ltd.png';
import gleneagleHotelGroup from '../assets/img/company-logos/gleneagle-hotel-group.png';
import iconPlcHr from '../assets/img/company-logos/icon-plc.jpg';
import irishRail from '../assets/img/company-logos/irish-rail.jpg';
import kerryDiocesanYouthService from '../assets/img/company-logos/kerry-diocesan-youth-service.jpg';
import materMisericordiaeUniversityHospital from '../assets/img/company-logos/mater-misericordiae-university-hospital.jpg';
import materPrivateNetwork from '../assets/img/company-logos/mater-private-network.jpg';
import mckesson from '../assets/img/company-logos/mckesson.jpg';
import nibrt from '../assets/img/company-logos/nibrt.png';
import oriflameRndLtd from '../assets/img/company-logos/oriflame-rnd-ltd.jpg';
import permanentTsbHr from '../assets/img/company-logos/hrpermanent-tsb.png';
import pobal from '../assets/img/company-logos/pobal.jpg';
import ptcTherapeutics from '../assets/img/company-logos/ptc-therapeutics.jpg';
import rcsiHr from '../assets/img/company-logos/rcsi.jpg';
import sdworx from '../assets/img/company-logos/sd-worx.jpg';
import telusInternational from '../assets/img/company-logos/telus-international.png';
import trigonHotels from '../assets/img/company-logos/trigon-hotels.jpg';
import tusla from '../assets/img/company-logos/tusla.png';
import valeoFoodsIreland from '../assets/img/company-logos/valeo-foods-ireland.png';
import version1 from '../assets/img/company-logos/version-1.jpg';
import vmware from '../assets/img/company-logos/vmware.png';
import voltedgeManagement from '../assets/img/company-logos/voltedge-management.png';
import woodies from '../assets/img/company-logos/woodies.png';
import h2Compliance from '../assets/img/company-logos/h2-compliance.jpg';
import inspiros from '../assets/img/company-logos/inspiros.png';
import avolon from '../assets/img/company-logos/avolon.png';
import hse from '../assets/img/company-logos/health-service-executive.png';
import baxterStorey from '../assets/img/company-logos/baxter-storey.jpg';
import excelRecruitment from '../assets/img/company-logos/excel-recruitment.png';
import healthAndSafetyAuthority from '../assets/img/company-logos/health-and-safety-authority.png';
import healthServiceExecutive from '../assets/img/company-logos/health-service-executive.png';
import idaireland from '../assets/img/company-logos/ida-ireland.png';
import masonHayesCurran from '../assets/img/company-logos/mason-hayes-curran.png';
import ncbi from '../assets/img/company-logos/ncbi.jpg';
import orpheaResidencesIreland from '../assets/img/company-logos/orpea-residences-reland.jpg';
import primeline from '../assets/img/company-logos/primeline.jpg';
import primelineExpress from '../assets/img/company-logos/primeline.jpg';
import steeringPoint from '../assets/img/company-logos/steering-point.png';
import dublinCityUniversity from '../assets/img/company-logos/dublin-city-university.png';
import ryanHrsLtd from '../assets/img/company-logos/ryan-hrs-ltd.png';
import SponsorsMarquee from '../components/SponsorsMarquee';
import alexionAthlone from '../assets/img/company-logos/alexion.png';
import alexionPharma from '../assets/img/company-logos/alexion-pharma.jpg';
import amgen from '../assets/img/company-logos/amgen.jpg';
import apcVleTherapeutics from '../assets/img/company-logos/apc-vle-therapeutics.jpg';
import asgardCleanroomSolutions from '../assets/img/company-logos/asgard.png';
import bauschHealth from '../assets/img/company-logos/bausch-health.jpg';
import biocair from '../assets/img/company-logos/biocair.png';
import biopharmachemIreland from '../assets/img/company-logos/biopharmachem-ireland.jpg';
import biostorIreland from '../assets/img/company-logos/biostor-ireland.png';
import blynksolve from '../assets/img/company-logos/blynksolve.jpg';
import bostonScientific from '../assets/img/company-logos/boston-scientific.png';
import bristolMyersSquibb from '../assets/img/company-logos/bristol-myers-squibb.png';
import piaIrelandCbre from '../assets/img/company-logos/cbre.jpg';
import piaIrelandCognizant from '../assets/img/company-logos/cognizant.jpg';
import piaIrelandcpl from '../assets/img/company-logos/cpl.png';
import eliLilly from '../assets/img/company-logos/eli-lilly.jpg';
import eurofinsBpt from '../assets/img/company-logos/eurofins-bpt.jpg';
import getreskilled from '../assets/img/company-logos/getreskilled.jpg';
import piaIrelandh2Compliance from '../assets/img/company-logos/h2-compliance.jpg';
import hanleyCalibration from '../assets/img/company-logos/hanley-calibration.jpg';
import horizonTherapeiticsNowAPartAmgen from '../assets/img/company-logos/horizon-therapeutics-now-a-part-of-amgen.jpg';
import janssenSciencesIreland from '../assets/img/company-logos/janssen.png';
import kiernanSteel from '../assets/img/company-logos/kiernan-steel.jpg';
import koerberPharmaSoftware from '../assets/img/company-logos/koerber-pharma-software.jpg';
import piaIrelandLotusworks from '../assets/img/company-logos/lotusworks.png';
import piaIrelandMckesson from '../assets/img/company-logos/mckesson.jpg';
import merckKgaa from '../assets/img/company-logos/merck-kgaa.jpg';
import metabolomicDiagnostics from '../assets/img/company-logos/metabolomic-diagnostics.jpg';
import microBioIreland from '../assets/img/company-logos/micro-bio-ireland.jpg';
import msd from '../assets/img/company-logos/msd.png';
import msdIreland from '../assets/img/company-logos/msd.png';
import piaIrelandNibrt from '../assets/img/company-logos/nibrt.png';
import nutriband from '../assets/img/company-logos/nutriband.jpg';
import obwTechnologies from '../assets/img/company-logos/obw-teachnologies.png';
import pfizerIrelandPharmaceuticalsRingaskiddy from '../assets/img/company-logos/pfizer-ireland-pharmaceuticals-ringaskiddy.jpg';
import pharmaIntegrity from '../assets/img/company-logos/pharma-integrity.png';
import q1Scientific from '../assets/img/company-logos/q1-scientific.png';
import piaIrelandSkycell from '../assets/img/company-logos/skycell.png';
import sspc from '../assets/img/company-logos/sspc.png';
import piaIrelandSynergy from '../assets/img/company-logos/synergy.jpg';
import takedaIreland from '../assets/img/company-logos/takeda-ireland.jpg';
import piaIrelandTuDublin from '../assets/img/company-logos/tu-dublin.png';
import piaIrelandUnispaceLifeSciences from '../assets/img/company-logos/unispace-life-sciences.png';
import viatrisLittleIsland from '../assets/img/company-logos/viatris-little-island.jpg';
import wdExcellence from '../assets/img/company-logos/wd-excellence.jpg';
import westPharceuticalServices from '../assets/img/company-logos/west.jpg';
import wolfMedical from '../assets/img/company-logos/wolf-medical.png';
import wuxiBiologicsIreland from '../assets/img/company-logos/wuxi.png';
import zoetis from '../assets/img/company-logos/zoetis.jpg';
import bakerFinn from '../assets/img/company-logos/baker-finn.jpg';
import bocGasses from '../assets/img/company-logos/boc-gasses.jpg';
import camida from '../assets/img/company-logos/camida.png';
import ecolab from '../assets/img/company-logos/ecolab.jpg';
import ellabIreland from '../assets/img/company-logos/ellab-ireland.png';
import indaver from '../assets/img/company-logos/indaver.jpg';
import nextGeneration from '../assets/img/company-logos/next-generation.jpg';
import kpc from '../assets/img/company-logos/kpc-international.png';
import quickstat from  '../assets/img/company-logos/quickstat.jpeg';
import catalyx from '../assets/img/company-logos/catalyx.jpg';
import titanExperiencePia from '../assets/img/company-logos/titan-experience-pia.png';
import pmbrc from '../assets/img/company-logos/pmbrc.jpg';
import mtu from '../assets/img/company-logos/mtu.png';
import careerVision from '../assets/img/company-logos/career-vision.png';
import ucc from '../assets/img/company-logos/ucc.png';
import actavo from '../assets/img/company-logos/actavo.png';
import alternativeHeat from '../assets/img/company-logos/alternative-heat.png';
import alucraft from '../assets/img/company-logos/alucraft.png';
import arcadisIrelandLimited from '../assets/img/company-logos/arcadis-ireland-limited.png';
import ardmac from '../assets/img/company-logos/ardmac.jpg';
import asee from '../assets/img/company-logos/asee.png';
import ashviewConsultantsLtd from '../assets/img/company-logos/ashview-consultants-ltd.png';
import axisengMELtd from '../assets/img/company-logos/axiseng-m-e-ltd.png';
import ballymore from '../assets/img/company-logos/ballymore.png';
import bamIreland from '../assets/img/company-logos/bam-ireland.png';
import bennetConstructionLtd from '../assets/img/company-logos/bennett-construction-ltd.png';
import boyleConstruction from '../assets/img/company-logos/boyle-construction.jpg';
import breedonIcia from '../assets/img/company-logos/bredon.jpg';
import cairnHomes from '../assets/img/company-logos/cairn-homes.png';
import cfieldConstruction from '../assets/img/company-logos/cfield-construction.png';
import clancyEngineering from '../assets/img/company-logos/clancy-engineering.jpg';
import collenConstruction from '../assets/img/company-logos/collen-construction.png';
import cunningmanCivilMarineLtd from '../assets/img/company-logos/cunningman-civil-marine-ltd.png';
import cunninghamContracts from '../assets/img/company-logos/cunningham-contracts-group.png';
import dugganBrothers from '../assets/img/company-logos/duggan-brothers.png';
import dukeMccaffrey from '../assets/img/company-logos/duke-mccaffrey.jpg';
import edcEngineeringDesignConsultantsLimited from '../assets/img/company-logos/edc-engineering-design-consultants-limited.png';
import ethosEngineering from '../assets/img/company-logos/ethos-engineering.jpg';
import extremeBalconies from '../assets/img/company-logos/extreme-balconies.png';
import flynn from '../assets/img/company-logos/flynn.jpg';
import hollis from '../assets/img/company-logos/hollis.png';
import instrotecLtd from '../assets/img/company-logos/instrotec-ltd.png';
import jcpsMechanicalLtd from '../assets/img/company-logos/jcps-mechanical-ltd.png';
import johnPaulConstruction from '../assets/img/company-logos/john-paul-constuction.png';
import johnSiskSon from '../assets/img/company-logos/john-sisk-son.png';
import jonesEngineering from '../assets/img/company-logos/jones-engineering.jpg';
import jpmContractsLtd from '../assets/img/company-logos/jpm-contracts-ltd.jpg';
import kelbuildLtd from '../assets/img/company-logos/kelbuild-ltd.jpg';
import kiernanSteelLtd from '../assets/img/company-logos/kiernan-steel-ltd.jpg';
import kingMoffattBuildingServices from '../assets/img/company-logos/king-moffatt-building-services.png';
import kirbyGroup from '../assets/img/company-logos/kirby-group.png';
import lmdBuild from '../assets/img/company-logos/lmd-build.jpg';
import mccabeGroup from '../assets/img/company-logos/mccabe-group.jpg';
import mccauleyDateOConnellArchitects from '../assets/img/company-logos/mccauley-date-o-connell-architects.png';
import miethealArchitects from '../assets/img/company-logos/meitheal-architects.png';
import mitchellMcdermott from '../assets/img/company-logos/mitchell-mcdermott.png';
import molaArchitecture from '../assets/img/company-logos/mola-architecture.png';
import murphyIreland from '../assets/img/company-logos/murphy-ireland.png';
import nationalBroadbandIreland from '../assets/img/company-logos/national-broadband-ireland.png';
import ors from '../assets/img/company-logos/ors.png';
import pMcveyBuildingSystems from '../assets/img/company-logos/p-mcvey-building-systems.jpg';
import qmacConstructionLtd from '../assets/img/company-logos/qmac-construction-ltd.jpg';
import reddyArchitectureUrbanism from '../assets/img/company-logos/reddy-architecture-urbanism.png';
import rocculLtd from '../assets/img/company-logos/roccul-ltd.jpg';
import scottTallonWalkerArchitects from '../assets/img/company-logos/scott-tallon-walker-architects.png';
import sesWaterManagement from '../assets/img/company-logos/ses-water-management.jpg';
import shareridge from '../assets/img/company-logos/shareridge.jpg';
import shayMurtaghPrecast from '../assets/img/company-logos/shay-murtagh-precast.png';
import stoneconLimited from '../assets/img/company-logos/stonecon-limited.png';
import suirEngineeringLtd from '../assets/img/company-logos/suir-engineering-ltd.png';
import timelessSashWindows from '../assets/img/company-logos/timeless-sash-windows.png';
import titanRoofingLtd from '../assets/img/company-logos/titan-roofing-ltd.png';
import truwood from '../assets/img/company-logos/truwood.png';
import visionContracting from '../assets/img/company-logos/vision-contracting.png';
import wallsConstruction from '../assets/img/company-logos/walls-construction.png';
import writech from '../assets/img/company-logos/writech.png';
import boomEdam from '../assets/img/company-logos/boon-edam.png';
import globoserv from '../assets/img/company-logos/globoserv.png';
import kccGroup from '../assets/img/company-logos/kcc-group.png';
import williamCox from '../assets/img/company-logos/william.png';
import meinhardtIreland from '../assets/img/company-logos/meinhardt-ireland.jpg';
import techRefrigeration from '../assets/img/company-logos/tech-refrigeration.png';
import lumenSearch from '../assets/img/company-logos/lumen.png';
import mmcIreland from '../assets/img/company-logos/mmc-ireland.png';
import considerateConstructorsScheme from '../assets/img/company-logos/mmc-ireland.png';
import korec from '../assets/img/company-logos/korec.png';
import its from '../assets/img/company-logos/its.png';
import robinmandal from '../assets/img/company-logos/robin-mandal.png';
import propertyDistrict from '../assets/img/company-logos/property-disctrict.png';
import technologicalUniversity from '../assets/img/company-logos/technological-university.png';
import southEastTechno from '../assets/img/company-logos/south-east-technological.png';
import alliedIrish from '../assets/img/company-logos/allied-irish-bank.png';
import layherIreland from '../assets/img/company-logos/layher-ireland.png';
import anPost from '../assets/img/company-logos/anpost.jpg';
import bordNaMona from '../assets/img/company-logos/bord-na-mona.jpg';
import busEireann from '../assets/img/company-logos/bus-eireann.jpg';
import compassGroupIreland from '../assets/img/company-logos/compass-group-ireland.jpg';
import danskoFoods from '../assets/img/company-logos/dansko-foods.jpg';
import dublinBusNpa from '../assets/img/company-logos/dublin-bus-npa2023.jpg';
import esbNpa from '../assets/img/company-logos/esb-npa2023.jpg';
import greenvilleProcurementPartners from '../assets/img/company-logos/greenville-procurement-partners.jpg';
import griffithCollege from '../assets/img/company-logos/griffith-college.png';
import healthServiceExecutiveNpa from '../assets/img/company-logos/hse.jpg';
import heinekenIreland from '../assets/img/company-logos/heineken-ireland.jpg';
import hseNpa from '../assets/img/company-logos/hse.jpg';
import hseServicesForOldPeople from '../assets/img/company-logos/hse.jpg';
import hseServicesForOldPeopleCopy from '../assets/img/company-logos/hse.jpg';
import iarnodEirann from '../assets/img/company-logos/larnrod-eireann-irish-rail.jpg';
import meadeFarmGroup from '../assets/img/company-logos/meade-farm-group.jpg';
import msdNpa from '../assets/img/company-logos/msd-npa2023.png';
import musgrave from '../assets/img/company-logos/musgrave.jpg';
import obwTechnologiesNpa from '../assets/img/company-logos/obw-teachnologies.png';
import officeOfGovermentProcurement from '../assets/img/company-logos/office-of-government-procurement.jpg';
import penneysPrimark from '../assets/img/company-logos/penneys-primark.jpg';
import permanentTsbAndEfficio from '../assets/img/company-logos/permanent-tsb-efficio.png';
import pfizerIrelandPharmaceuticals from '../assets/img/company-logos/pfizer-ireland-pharmaceuticals.jpg';
import uisceEireann from '../assets/img/company-logos/uisce-eireann.jpg';
import universityCollegeCork from '../assets/img/company-logos/university-college-cork.jpg';
import verizon from '../assets/img/company-logos/verizon.jpg';
import westPharmaceutical from '../assets/img/company-logos/west.jpg';
import transportInfrastructureIreland from '../assets/img/company-logos/transport-infrastructure.png';
import bristolMyersSquibbNpa from '../assets/img/company-logos/bristol-myers-squibb.png';
import kpmgNpa from '../assets/img/company-logos/kpmg.jpg';
import accountOnline from '../assets/img/company-logos/accountant-online.png';
import aerLingusIcx from '../assets/img/company-logos/aer-lingus.png';
import aibIcx from '../assets/img/company-logos/allied-irish-bank.png';
import allviewHealthcare from '../assets/img/company-logos/allview.jpg';
import bankOfIreland from '../assets/img/company-logos/bank-of-ireland.png';
import busEirannIrishBus from '../assets/img/company-logos/bus-eireann.jpg';
import businessRiverIcx from '../assets/img/company-logos/businessriver.jpg';
import calor from '../assets/img/company-logos/calor.png';
import circit from '../assets/img/company-logos/circit.png';
import coll8Logisctics from '../assets/img/company-logos/coll-8-logistics.png';
import diageoIreland from '../assets/img/company-logos/diageo.png';
import dubarryOfIreland from '../assets/img/company-logos/dubarry-ireland.png';
import eason from '../assets/img/company-logos/eason.png';
import electricIrelandIcx from '../assets/img/company-logos/electric-ireland.png';
import esbNetworks from '../assets/img/company-logos/esb-networks.png';
import fastwayIcx from '../assets/img/company-logos/fastway.png';
import fragomenIrealandIcx from '../assets/img/company-logos/fragomen.png';
import gasNetworksIreland from '../assets/img/company-logos/gas-networks-ireland.png';
import irishLifeIcx from '../assets/img/company-logos/irish-life.png';
import karaConnect from '../assets/img/company-logos/kara-connect.png';
import kylemoreCars from '../assets/img/company-logos/kylemore-cars.png';
import kylemoreCarsIcx from '../assets/img/company-logos/kylemore-cars.png';
import libertyInsurance from '../assets/img/company-logos/liberty-insurance.png';
import locoScootersLtd from '../assets/img/company-logos/loco-scooters.png';
import magnetPlus from '../assets/img/company-logos/magnet-plus.png';
import musgraveIcx from '../assets/img/company-logos/musgrave.jpg';
import nationalRehabilitationHospital from '../assets/img/company-logos/national-rehabilitation-hospital.png';
import oakviewEarlyYearsServices from '../assets/img/company-logos/oakview-early-years-services.png';
import passportServiceDepartmentOfForeignAffairs from '../assets/img/company-logos/passport-service-department-of-foreign-affairs.png';
import pennypopIcx from '../assets/img/company-logos/pennypop.png';
import queensUniversity from '../assets/img/company-logos/queens-university.png';
import shawsDepartmentStores from '../assets/img/company-logos/shaws-department-stores.png';
import sseAirtricity from '../assets/img/company-logos/sse-airtricity.png';
import theConventionCentreDublin from '../assets/img/company-logos/the-convention-centre-dublin.png';
import trinityBrandCommercialServicesLtd from '../assets/img/company-logos/trinity-brand-commercial-services-ltd.png';
import universityOfLimerick from '../assets/img/company-logos/university-limerick.png';
import alphaMechanicalServices from '../assets/img/company-logos/alpha-mechanical-services.png';
import anPostFoa from '../assets/img/company-logos/anpost.jpg';
import ardmacFoa from '../assets/img/company-logos/ardmac.jpg';
import arup from '../assets/img/company-logos/arup.png';
import ashWilsonDesign from '../assets/img/company-logos/ash-wilson-design.png';
import ashenCLoud from '../assets/img/company-logos/ashen-cloud.png';
import altanticTechnologicalUniversity from '../assets/img/company-logos/atu-lsa.jpg';
import axisengME from '../assets/img/company-logos/axiseng-m-e-ltd.png';
import azureContractingLtd from '../assets/img/company-logos/azure-contracting.png';
import bsgDesign from '../assets/img/company-logos/bsg-design.png';
import builtInteriorLtd from '../assets/img/company-logos/built-interior-ltd.png';
import busEirannFoa from '../assets/img/company-logos/bus-eireann.jpg';
import byrneCustomJoinery from '../assets/img/company-logos/bryne-custom-joinery.png';
import calibroWorkspace from '../assets/img/company-logos/calibro-workspace.png';
import castleBrook from '../assets/img/company-logos/castlebrook.png';
import cbreFoa from '../assets/img/company-logos/cbre.jpg';
import cogentAssociates from '../assets/img/company-logos/cogent-associates.png';
import commaArchitects from '../assets/img/company-logos/comma-architects.png';
import corgan from '../assets/img/company-logos/corgan.png';
import crmFitOut from '../assets/img/company-logos/crm-fit-out.png';
import davidJamesRetailSolutions from '../assets/img/company-logos/david-james-retail-solutions.png';
import diarmuidReilArchitects from '../assets/img/company-logos/diarmuid-reil-architects.png';
import ddmodArchitects from '../assets/img/company-logos/dmod-architects.png';
import dmvfArchitects from '../assets/img/company-logos/dmvf-architects.png';
import dukeMccaffreyConsulting from '../assets/img/company-logos/duke-mccaffrey.jpg';
import edc from '../assets/img/company-logos/edc-engineering-design-consultants-limited.png';
import editDesignStudio from '../assets/img/company-logos/edit-design-studio.png';
import elliottGroup from '../assets/img/company-logos/elliott-group.png';
import elmGray from '../assets/img/company-logos/elm-gray.png';
import ethosEngineeringFoa from '../assets/img/company-logos/ethos-engineering.jpg';
import gdcInteriors from '../assets/img/company-logos/gdc-interiors.png';
import globoservLtd from '../assets/img/company-logos/globoserv.png';
import griffithCollegeFoa from '../assets/img/company-logos/griffith-college.png';
import hamiltonRobson from '../assets/img/company-logos/hamilton-robson.png';
import heleneBroderickInteriors from '../assets/img/company-logos/helene-broderick-interiors.png';
import henryJLyons from '../assets/img/company-logos/henry-j-lyons.png';
import iconicOffices from '../assets/img/company-logos/iconic-offices.png';
import interiorsBySisk from '../assets/img/company-logos/interiors-by-sisk.png';
import jcaArchitects from '../assets/img/company-logos/jca-architects.png';
import jenningsDesignStudioArchitects from '../assets/img/company-logos/jennings-design-studio-architects.png';
import kingstonLaffertyDesign from '../assets/img/company-logos/kingston-lafferty-design.png';
import knightFrank from '../assets/img/company-logos/knight-frank.png';
import kota from '../assets/img/company-logos/kota.png';
import kpmgFoa from '../assets/img/company-logos/kpmg.jpg';
import llSolutions from '../assets/img/company-logos/ll-solutions.png';
import lucaArchitecture from '../assets/img/company-logos/luca-architecture.png';
import mcaArchitects from '../assets/img/company-logos/mca-architects.png';
import mcmahonAssociates from '../assets/img/company-logos/mcmahon-associates.png';
import millimetreDesign from '../assets/img/company-logos/millimetre-design.png';
import molaArchitectureFoa from '../assets/img/company-logos/mola-architecture.png';
import oDonnelONiellDesign from '../assets/img/company-logos/o-donnell-o-neill-design.png';
import oakPm from '../assets/img/company-logos/oak-pm.png';
import oppermannAssociates from '../assets/img/company-logos/oppermann-associates.png';
import perkinsWill from '../assets/img/company-logos/perkins-will.png';
import pureFitoutAssociatedLtd from '../assets/img/company-logos/pure-fitout-associated.png';
import reddyArchitectureUrbanismFoa from '../assets/img/company-logos/reddy-architecture-urbanism.png';
import roccul from '../assets/img/company-logos/roccul.png';
import saulDesign from '../assets/img/company-logos/saul-design.png';
import sineadCassidyDesign from '../assets/img/company-logos/sinead-cassidy-design.png';
import stephenCarrArchitects from '../assets/img/company-logos/stephen-carr-architects.png';
import structureTone from '../assets/img/company-logos/structure-tone.png';
import tIFitouts from '../assets/img/company-logos/t-i-fitouts.png';
import tapCreative from '../assets/img/company-logos/tap-creative.png';
import theInteriorGroup from '../assets/img/company-logos/the-interiors-group.png';
import toddArchitects from '../assets/img/company-logos/todd-architects.png';
import totalFitout from '../assets/img/company-logos/total-fitout.png';
import tuDublinFoa from '../assets/img/company-logos/tu-dublin.png';
import turnerTownsend from '../assets/img/company-logos/turnet-townsend.png';
import vardisGroup from '../assets/img/company-logos/vardis-group.png';
import wilsonArchitecture from '../assets/img/company-logos/wilson-architecture.png';
import ygInteriors from '../assets/img/company-logos/yg-interiors.png';
import dcon from '../assets/img/company-logos/dcon.png';
import procore from '../assets/img/company-logos/procore.png';
import w2w from '../assets/img/company-logos/w2w.png';
import bene from '../assets/img/company-logos/bene.jpg';
import optima from '../assets/img/company-logos/optima.jpg';
import johnstonFitout from '../assets/img/company-logos/johnson-fitout.png';
import mjfInteriors from '../assets/img/company-logos/mjf.jpg';
import etag from '../assets/img/company-logos/etag.png';
import radii from '../assets/img/company-logos/radii.png';
import solutions from '../assets/img/company-logos/solutions.png';
import janJanssens from '../assets/img/company-logos/jan-janssens.png';
import sigWorkplace from '../assets/img/company-logos/sig.png';

const initialFormData = Object.freeze({
  name: '',
  description: '',
  awards: '',
  logo: '',
  schedule_1: '',
  schedule_2: '',
  schedule_3: '',
  schedule_4: '',
  schedule_5: '',
  website_url: '',
  finalists_url: '',
  judges_url: '',
  about_url: '',
  support_email: '',
  support_phone: '',
  host: '',
  host_description: '',
  start: '',
  location: '',
  dacast_url: '',
  vimeo_url: '',
  entry_guide_url: '',
  status: '',
});

const initialSeatViewData = Object.freeze({
  id: '',
});

const initialAttendeeData = Object.freeze({
  id: '',
  name: '',
  contact_number: '',
  company_name: '',
  company_position: ''
});

const initialVenueData = Object.freeze({
  venue_id: '',
  stage_length: '',
  stage_width: '',
  table_length: '',
  table_width: '',
  table_type: '',
  number_of_tables: '',
  total_seats_per_table: '',
  venue_name: '',
});

const initialResetFloorPlan = Object.freeze({
  floor_plan: '',
});

const initialTableData = Object.freeze({
  id: '',
  name: '',
});

const initialMoveAttendeeData = Object.freeze({
  attendee_ids_from: '',
  attendee_ids_to: '',
  table_number_from: '',
  table_number_to: '',
  user_id: '',
});

const AdminVenueSeatingPage = () => {
  let history = useHistory();
  const ref = useRef(null); // ref => { current: null }
  const stageRef = useRef(); // ref => { current: null }
  const [formData, setFormData] = useState(initialFormData);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const [award, setAward] = useState();

  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'userRole', 'isLoggedIn', 'awardStatus', 'isWelcome', 'isFinalist', 'token', 'userDetails']);
  const currentUser = cookies.currentUser;
  const token = cookies.token;
  const isLoggedIn = cookies.isLoggedIn;
  let updatedAt = '';

  const isWarning = 'warning';
  const isSuccess = 'success';
  const isError = 'error';
  const toastCSS = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }
  const [attendeesListLoading, setAttendeesListLoading] = useState(false);
  const [attendees, setAttendees] = useState([]);
  const [seatingArrangementLoading, setSeatingArrangementLoading] = useState(false);
  const [reloadCount, setReloadCount] = useState(1);
  const [attendee, setAttendee] = useState(initialAttendeeData);
  const [venueData, setVenueData] = useState(initialVenueData);
  const [tables, setTables] = useState([]);
  const [tablesPlaceholder, setTablesPlacholder] = useState([]);
  const [numberOfSeats, setNumberOfSeats] = useState(0);
  const [tablesLoading, setTablesLoading] = useState(true);
  const [moveAttendeeData, setMoveAttendeeData] = useState(initialMoveAttendeeData);
  const [attendeeIdsFrom, setAttendeeIdsFrom] = useState([]);
  const [attendeeIdsTo, setAttendeeIdsTo] = useState([]);
  const [tableData, setTableData] = useState(initialTableData);
  const [table, setTable] = useState(initialTableData);
  const [tableModalShow, setTableModalShow] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableId, setTableId] = useState("");
 
  const [tableTo, setTableTo] = useState([]);
  const [tableFrom, setTableFrom] = useState([]);
  const [tableToSeats, setTableToSeats] = useState(0);
  const [tableFromSeats, setTableFromSeats] = useState(0);
  const [tableFromAttendees, setTableFromAttendees] = useState(0);
  const [selectAtleast, setSelectAtleast] = useState(true);
  const [moveAttendeeShow, setMoveAttendeeShow] = useState(false);
  const [tableIds, setTableIds] = useState([]);
  const [seatingArrangementModal, setSeatingArrangementModal] = useState(false);
  const [seatingImage, setSeatingImage] = useState();
  const [imageLoading, setImageLoading] = useState();

  const toggleTableModalShow = () => {
    setTableModalShow(!tableModalShow);
  }
  

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token);
  }

  const getCheckSeat = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/seat/check?user_id=' + currentUser + '&token=' + token);
  }

  const getTablesNew = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/venue/table?user_id=' + currentUser + '&token=' + token);
  }

  const getAttendees = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/attendee?award_id=' + award_id + '&user_id=' + currentUser + '&token=' + token);
  }

  const toastAlert = ({ type, message }) => {
    if (type === 'error') {
      toast.error(message, toastCSS);
    } else if (type === 'warning') {
      toast.warning(message, toastCSS);
    } else {
      toast.success(message, toastCSS);
    }
  }

  const requestSuccess = () => {
    const message = 'Success!';
    toastAlert({ type: isSuccess, message: message });
  }

  const catchError = () => {
    const message = 'An error occured. Please try again or contact the administrator.';
    toastAlert({ type: isError, message: message });
  }

  const catchWarning = () => {
    const message = 'Something went wrong!';
    toastAlert({ type: isWarning, message: message });
  }

  const handleChangeAttendee = (e) => {
    const { name, value } = e.target;
    setAttendee({
      ...attendee,
      [name]: value,
    });
  }

  const submitSeatingImageCallback = useCallback(async () => {
    setImageLoading(() => true);
    await toJpeg(stageRef.current, { quality: 1.0 })
      .then(async (dataUrl) => {
        setSeatingImage(() => dataUrl);
        await submitSeatingImage(dataUrl);

      })
      .catch(() => {
        catchError();
      });

  }, [stageRef]);

  const toggleSeatingArrangement = e => {
    setSeatingArrangementModal(!seatingArrangementModal);
  }

  const retrieveHistory = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/history?user_id=' + currentUser +'&token='+token, {
    // const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seating/history?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveAttendeesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/attendee?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTablesSearch = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/seat/plan?award_id='+award_id+'&user_id='+currentUser+'&token='+token+'&table_numbers='+tableIds+'&query='+e , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTables = async () => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'/venue/table?award_id='+award_id+'&user_id='+currentUser+'&token='+token , {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  async function submitSeatingImage(e) {
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT + 'award/'+award_id+'?user_id=' + currentUser + '&token=' + token, {
      method: 'PUT', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
      body: JSON.stringify({
        user_id: currentUser,
        award_id: award_id,
        judge_allocation: e,
      }), // body data type must match "Content-Type" header
    })
    const json = await response.json();
    if (json.status == "Success") {
      setReloadCount(reloadCount + 1);
      setAttendeeIdsFrom([]);
      setAttendeeIdsTo([]);
      setTableTo([]);
      setTableFrom([]);
    }
  }

  const addTableNumber = (id) => {
    if (!tableIds.includes(id)) {
      setTableIds(tableIds => [...tableIds, id]);
    }
  }

  const removeTableNumber = (id) => {
    let filteredArray = tableIds.filter(item => item !== id);
    setTableIds(filteredArray);
  }

  const addAttendeeTo = (id) => {
    if (attendeeIdsTo.includes(id)) {
    } else {
      setAttendeeIdsTo(attendeeIdsTo => [...attendeeIdsTo, id]);
      setMoveAttendeeData({
        ...moveAttendeeData,
        user_id: currentUser,
        attendee_ids_to: attendeeIdsTo,
      });
      var attendee_ids_to = [...attendeeIdsTo, id];
      var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + attendee_ids_to.length;
      if (seatsNeeded >= 0) {
        setSelectAtleast(false);
      } else {
        setSelectAtleast(true);
      }
    }
  }

  const removeAttendeeTo = (id) => {
    let filteredArray = attendeeIdsTo.filter(item => item !== id);
    setAttendeeIdsTo(filteredArray);
    setMoveAttendeeData({
      ...moveAttendeeData,
      user_id: currentUser,
      attendee_ids_to: filteredArray,
    });
    var seatsNeeded = ((tableToSeats - tableTo.length) - attendeeIdsFrom.length) + filteredArray.length;
    if (seatsNeeded >= 0) {
      setSelectAtleast(false);
    } else {
      setSelectAtleast(true);
    }
  }

  const retrieveTableTo = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTable = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const retrieveTableFrom = async (e) => {
    const response = fetch(process.env.REACT_APP_API_ENDPOINT + 'venue/table/'+e+'?award_id=' + award_id + '&user_id=' + currentUser +'&token='+token, {
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, cors, *same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'omit', // include, *same-origin, omit
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": true,
        'Content-Type': 'application/json',
        "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
        "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
      },
      redirect: 'follow', // manual, *follow, error
      referrer: 'client', // no-referrer, *client
    })
      .then((response) => {
        return response.json();
      })
      .then((events) => {
        return events.data;
      });
    return response;
  };

  const handleTableFrom = (e) => {
    var table_id_from = e;
    setAttendeeIdsFrom([]);
    setMoveAttendeeData({
      ...moveAttendeeData,
      table_number_from: table_id_from,
    });

    const getTableFrom = async (table_id_from) => {
      const selectedTableFrom = await retrieveTableFrom(table_id_from);
      if(selectedTableFrom) {
        setTableFrom(selectedTableFrom.attendees);
        setTableFromSeats(selectedTableFrom.no_of_seats);
        setTableFromAttendees(selectedTableFrom.attendees.length);
      }
    };
    getTableFrom(table_id_from);

  }

  const handleChangeTable = (e) => {
    setTableData({
      ...tableData,
      [e.target.name]: e.target.value,
    });
  }

  const handleTableTo = (e) => {
    setAttendeeIdsTo([]);
    var table_id_to = e.target.value;

    if (table_id_to && table_id_to != "") {
      setMoveAttendeeData({
        ...moveAttendeeData,
        table_number_to: e.target.value,
      });
  
      const getTableTo = async (table_id_to) => {
        const selectedTableTo = await retrieveTableTo(table_id_to);
        if(selectedTableTo) {
          setTableTo(selectedTableTo.attendees);
          setMoveAttendeeShow(true);
          setTableToSeats(selectedTableTo.no_of_seats);
        }
      };
  
      getTableTo(table_id_to);
    }
  }

  const handleTable = (e) => {
    var table_id = e;
    setTableData({
      ...tableData,
      id: table_id,
    });
    setTableId(table_id);
    toggleTableModalShow();
    const getTable = async (table_id) => {
      const selectedTable = await retrieveTable(table_id);
      if(selectedTable) {
        setTable(selectedTable);
        setTableData({
          ...tableData,
          name: selectedTable.name,
        });
        setTableLoading(false);
      }
    };
    getTable(table_id);
  }

  const getTables = async () => {
    const selectedTables = await retrieveTables();
    if (selectedTables) {
      const removedTableIds = [ 67, 68 ];
      const tables = selectedTables.filter(table => table.category !== "other" && !removedTableIds.includes(table.id) && table.attendees.length > 0).map(x => x);
      const placeholder = tables.length % 8;
      let hiddenColumns = [];

      let length = tables.length / 32;

      if (length % 1 > 0) {
        length++;
      }

      const sortedTable = tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(x => x);

      let result = [];
      for (let i = length - length % 1; i > 0; i--) {
        result.push(sortedTable.splice(0, 32));
      }
      setTables(result);
      for (let i = 0; i < 8 - placeholder; i++)
        hiddenColumns = [...hiddenColumns, ''];

      setTablesPlacholder(() => hiddenColumns);
      // setTablesPlan(selectedTables.tables);
      setSeatingArrangementLoading(false);
      setTablesLoading(false);
    }
  }

  const getCompanyLogos = (companyName, id) => {
    const companyLogoOpex = [
      {
        company_name: 'Abbott Nutrition Cootehill',
        logo: abbott
      },
      {
        company_name: 'AbbVie',
        logo: abbvie
      },
      {
        company_name: 'An Post',
        logo: anpost
      },
      {
        company_name: 'BAM Facilities Management',
        logo: bam
      },
      {
        company_name: 'Breedon Group',
        logo: breedon
      },
      {
        company_name: 'Bridgewater Construction',
        logo: bridgewater
      },
      {
        company_name: 'Centric Health',
        logo: centric
      },
      {
        company_name: 'Comfort Keepers Homecare',
        logo: comfort
      },
      {
        company_name: 'Crannog Roofing',
        logo: crannog
      },
      {
        company_name: 'AllView Healthcare',
        logo: allview
      },
      {
        company_name: 'DPD Ireland',
        logo: dpd
      },
      {
        company_name: 'Elis Pest Control',
        logo: elis
      },
      {
        company_name: 'ESB',
        logo: esb
      },
      {
        company_name: 'Eurofins BioPharmaceutical Product Testing',
        logo: eurofins
      },
      {
        company_name: 'Faithful+Gould',
        logo: faithfulGould
      },
      {
        company_name: 'Fastway',
        logo: fastway
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gasNetworks
      },
      {
        company_name: 'George Best Belfast City Airport',
        logo: george
      },
      {
        company_name: 'Griffith College Faculty of Law',
        logo: griffithFaculty
      },
      {
        company_name: 'Griffith Halls of Residence',
        logo: griffithResidence
      },
      {
        company_name: 'Guildhall - Derry City and Strabane District Council',
        logo: guildhall
      },
      {
        company_name: 'Iarnród Éireann Irish Rail',
        logo: larnrod
      },
      {
        company_name: 'Leckey',
        logo: leckey
      },
      {
        company_name: 'Keltech',
        logo: keltech
      },
      {
        company_name: 'Kerrygold Park, Ornua',
        logo: kerrygold
      },
      {
        company_name: 'Kirby Group Engineering',
        logo: kirby
      },
      {
        company_name: 'LMC FM',
        logo: lmc
      },
      {
        company_name: 'LotusWorks',
        logo: lotusWorks
      },
      {
        company_name: 'Mannok',
        logo: mannok
      },
      {
        company_name: 'MIAS Pharma',
        logo: mias
      },
      {
        company_name: 'Mitie',
        logo: mitie
      },
      {
        company_name: 'O\'Donoghue Ring Collection',
        logo: donoghue
      },
      {
        company_name: 'Primark',
        logo: primark
      },
      {
        company_name: 'Rural WiFi',
        logo: ruralWifi
      },
      {
        company_name: 'South Coast Logistics',
        logo: southCoast
      },
      {
        company_name: 'The Skibbereen Food Company',
        logo: skibbereen
      },
      {
        company_name: 'Tirlán',
        logo: tirlan
      },
      {
        company_name: 'Uisce Éireann',
        logo: uisce
      },
      {
        company_name: 'Ventac',
        logo: ventac
      },
      {
        company_name: 'Viking UK/IE',
        logo: viking
      },
      {
        company_name: 'West Pharmaceutical Services',
        logo: west
      },
      {
        company_name: 'Wines Direct',
        logo: winesDirect
      },
      {
        company_name: 'Workhuman',
        logo: workhuman
      },
      {
        company_name: 'Salesforce',
        logo: salesforce
      },
      {
        company_name: 'Atlantic Technological University (ATU) Sligo',
        logo: atu
      },
      {
        company_name: 'Cheshire Ireland',
        logo: cheshire
      },
      {
        company_name: 'ISS Facility Services Ireland',
        logo: iss
      },
      {
        company_name: 'Sensori Facilities Management',
        logo: sensori
      },
      {
        company_name: 'Cushman & Wakefield',
        logo: sensori
      }
    ];
    const companyLogoLsa = [
      {
        company_name: 'Teagasc',
        logo: teagasc
      },
      {
        company_name: 'CXV Global',
        logo: cxv
      },
      {
        company_name: 'Kora Healthcare',
        logo: kora
      },
      {
        company_name: 'Metabolomic Diagnostics',
        logo: metabolomic
      },
      {
        company_name: 'Dentaqua Ltd.',
        logo: dentaqua
      },
      {
        company_name: 'Boston Scientific',
        logo: boston
      },
      {
        company_name: 'Almac',
        logo: almac
      },
      {
        company_name: 'Medtronic (Parkmore Galway)',
        logo: medtronic
      },
      {
        company_name: 'McArdle Skeath',
        logo: mcardle
      },
      {
        company_name: 'Cork University Hospital',
        logo: cuh
      },
      {
        company_name: 'University College Cork',
        logo: cuh
      },
      {
        company_name: 'Icon Plc.',
        logo: iconPlc
      },
      {
        company_name: 'ICON plc',
        logo: iconPlc
      },
      {
        company_name: 'ICON Clinical Research',
        logo: iconPlc
      },
      {
        company_name: 'MIAS Pharma',
        logo: mias
      },
      {
        company_name: 'Janssen Sciences Ireland UC',
        logo: janssen
      },
      {
        company_name: 'Syncrophi Systems',
        logo: syncrophi
      },
      {
        company_name: 'CBRE',
        logo: cbre
      },
      {
        company_name: 'Pfizer',
        logo: pfizer
      },
      {
        company_name: 'Deciphex',
        logo: deciphex
      },
      {
        company_name: 'PTC Therapeutics',
        logo: ptc
      },
      {
        company_name: 'Abbott Diagnostics',
        logo: abbotLsa
      },
      {
        company_name: 'DMF Systems',
        logo: dmf
      },
      {
        company_name: 'Wuxi Biologics',
        logo: wuxi
      },
      {
        company_name: 'Controlled Environments Company',
        logo: cec
      },
      {
        company_name: 'Arrotek Medical',
        logo: arrotek
      },
      {
        company_name: 'Forensic Science Ireland',
        logo: fsi
      },
      {
        company_name: 'MCR',
        logo: mcr
      },
      {
        company_name: 'HGF',
        logo: hgf
      },
      {
        company_name: 'Kuehne+Nagel',
        logo: kuehne
      },
      {
        company_name: 'Synergy Security Solutions',
        logo: synergy
      },
      {
        company_name: 'DHL',
        logo: dhl
      },
      {
        company_name: 'Atlantic Technological University',
        logo: atuLsa
      },
      {
        company_name: 'Eli Lilly and Company',
        logo: lilly
      },
      {
        company_name: 'TU Dublin',
        logo: tuDublin
      },
      {
        company_name: 'SymPhysis Medical',
        logo: symphysis
      },
      {
        company_name: 'Asgard Cleanroom Solutions',
        logo: asgard
      },
      {
        company_name: 'ByrneWallace LLP',
        logo: byrne
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: unispaceLifeSciences
      },
      {
        company_name: 'Abbvie',
        logo: abbvie
      },
      {
        company_name: 'Viatris',
        logo: viatris
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriver
      },
      {
        company_name: 'Actylis',
        logo: actylis
      },
    ];
    const companyLogoBeaa = [
      {
        company_name: 'Aldi Ireland',
        logo: aldi
      },
      {
        company_name: 'Alexion Athlone',
        logo: alexion
      },
      {
        company_name: 'An Post',
        logo: anpost
      },
      {
        company_name: 'Arthur Cox',
        logo: arthurCox
      },
      {
        company_name: 'Business River',
        logo: businessriver
      },
      {
        company_name: 'Clayton Hotel Charlemont',
        logo: clayton
      },
      {
        company_name: 'Dairygold Co-Operative Society Limited',
        logo: dairygold
      },
      {
        company_name: 'Dublin Bus',
        logo: dublinBus
      },
      {
        company_name: 'ebay',
        logo: ebay
      },
      {
        company_name: 'Garrett Motion',
        logo: garrett
      },
      {
        company_name: 'harmac medical products',
        logo: harmac
      },
      {
        company_name: 'John Graham Construction Ltd',
        logo: graham
      },
      {
        company_name: 'Lidl',
        logo: lidl
      },
      {
        company_name: 'Mahon Point Shopping Centre',
        logo: mahon
      },
      {
        company_name: 'Matheson',
        logo: matheson
      },
      {
        company_name: 'Matheson LLP',
        logo: matheson
      },
      {
        company_name: 'Merit Medical Ireland Ltd',
        logo: meritmedical
      },
      {
        company_name: 'Queen\'s University Belfast',
        logo: queenUniversity
      },
      {
        company_name: 'Refillz Zero Waste',
        logo: refillz
      },
      {
        company_name: 'SIG Ireland',
        logo: sig
      },
      {
        company_name: 'Swords Pavilions',
        logo: swords
      },
      {
        company_name: 'The Samuel Hotel',
        logo: samuel
      },
      {
        company_name: 'Thermo Fisher Cork',
        logo: thermo
      },
      {
        company_name: 'Wren Urban Nest',
        logo: wren
      },
      {
        company_name: 'Optien',
        logo: thermo
      },
      {
        company_name: 'Jacobs Engineer',
        logo: jacobs
      },
      {
        company_name: 'Irish Solar Energy Association',
        logo: isea
      },
      {
        company_name: 'Climate Action',
        logo: climate
      },
      {
        company_name: 'Aviva Stadium',
        logo: aviva
      },
      {
        company_name: 'Anglo Beef Processors',
        logo: abp
      },
      {
        company_name: 'Gilead Sciences Ireland',
        logo: gilead
      },
      {
        company_name: 'Analog Devices',
        logo: analog
      },
      {
        company_name: 'RCSI',
        logo: rcsi
      }
    ];
    const companyLogoIsa = [
      {
        company_name: '123.ie',
        logo: isa123
      },
      {
        company_name: 'Allianz',
        logo: allianz
      },
      {
        company_name: 'Aviva',
        logo: avivaIsa
      },
      {
        company_name: 'Electric Ireland',
        logo: electricIreland
      },
      {
        company_name: 'Football Association of Ireland',
        logo: footballAssociationOfIreland
      },
      {
        company_name: 'Just Eat',
        logo: justEat
      },
      {
        company_name: 'KPMG',
        logo: kpmg
      },
      {
        company_name: 'Legacy Communications',
        logo: legacyCommunications
      },
      {
        company_name: 'Live Nation',
        logo: liveNation
      },
      {
        company_name: 'Punchestown Racecourse & Event Venue',
        logo: punchestownRacecourseEventVenue
      },
      {
        company_name: 'PwC',
        logo: pwc
      },
      {
        company_name: 'RTÉ',
        logo: rte
      },
      {
        company_name: 'Sage',
        logo: sage
      },
      {
        company_name: 'Supermac\'s',
        logo: supermacs
      },
      {
        company_name: 'The B!G Idea',
        logo: theBigIdea
      },
      {
        company_name: 'Urban Media',
        logo: urbanMedia
      },
      {
        company_name: 'URBANMEDIA',
        logo: urbanmedia
      },
      {
        company_name: 'Badminton Ireland',
        logo: badmintonIreland
      },
      {
        company_name: 'Bord Gáis Energy',
        logo: bordGaisEnergy
      },
      {
        company_name: 'Irish Life',
        logo: irishLand
      },
      {
        company_name: 'Permanent TSB',
        logo: permanentTsb
      },
      {
        company_name: 'Vhi',
        logo: vhi
      },
      {
        company_name: 'BWG Foods (SPAR Ireland)',
        logo: bwgFoodsSparIteland
      },
      {
        company_name: 'Dublin International Film Festival',
        logo: dublinInternationalFilmFestival
      },
      {
        company_name: 'ESB Group',
        logo: esbIsa
      },
      {
        company_name: 'ForeFront Sports',
        logo: forefrontSports
      },
      {
        company_name: 'Greyhound Racing Ireland',
        logo: greyhoundRacingIreland
      },
      {
        company_name: 'Horse Racing Ireland',
        logo: horseRacingIreland
      },
      {
        company_name: 'KICK',
        logo: kick
      },
      {
        company_name: 'Pentland Brands / Canterbury',
        logo: pentlandBrandsCanterbury
      },
      {
        company_name: 'Special Olympics Ireland',
        logo: specialOlympicsIreland
      },
      {
        company_name: 'Special Olympics Ireland/eir',
        logo: specialOlympicsIrelandEir
      },
      {
        company_name: 'Teneo',
        logo: teneo
      },
      {
        company_name: 'The Camogie Assocation',
        logo: theCamogieAssocation
      },
      {
        company_name: 'TITAN Experience',
        logo: titanExperience
      },
      {
        company_name: 'Pinergy',
        logo: pinergy
      },
      {
        company_name: 'Aer Lingus',
        logo: aerLingus
      },
      {
        company_name: 'Future Proof of Media',
        logo: futureProofOfMedia
      },
      {
        company_name: 'IMAGE Media',
        logo: imageMedia
      },
      {
        company_name: 'Leader Kicking',
        logo: leaderKicking
      },
      {
        company_name: 'News Ireland',
        logo: newsIreland
      },
      {
        company_name: 'Zenith',
        logo: zenith
      },
      {
        company_name: 'Mindshare Media Ireland',
        logo: mindshareMediaIreland
      },
      {
        company_name: 'Sweartaker',
        logo: sweartaker
      },
      {
        company_name: 'BusinessRiver',
        logo: businessRiverIsa
      },
      {
        company_name: 'Mediahuis Ireland',
        logo: mediahuisIreland
      },
      {
        company_name: 'National Concert Hall',
        logo: nationalConcertHall
      },
      {
        company_name: 'The Sunday Times',
        logo: theSundayTimes
      },
      {
        company_name: 'AIG',
        logo: aig
      },
      {
        company_name: 'Glenveagh Homes',
        logo: glenveagh
      },
      {
        company_name: 'Clean Coast',
        logo: cleanCoast
      },
      {
        company_name: 'Silver Cloud Health Limited',
        logo: silverCloud
      },
      {
        company_name: 'Hot Press',
        logo: hotPress
      },
    ];
    const companyLogoPiaUk = [
      {
        company_name: 'Accord Healthcare',
        logo: accordHealthcare
      },
      {
        company_name: 'Almac Group',
        logo: almacGroup
      },
      {
        company_name: 'BioPharma Dynamics',
        logo: biopharmaDynamics
      },
      {
        company_name: 'Bristol Laboratories Ltd',
        logo: bristolLaboratoriesLtd
      },
      {
        company_name: 'Business River',
        logo: businessRiver
      },
      {
        company_name: 'CELLOMATICS BIOSCIENCES LIMITED',
        logo: cellomaticsBiosciencesLimited
      },
      {
        company_name: 'Chiesi',
        logo: chiesi
      },
      {
        company_name: 'Diaceutics PLC',
        logo: diaceuticsPlc
      },
      {
        company_name: 'Hertility Health',
        logo: hertilityHealth
      },
      {
        company_name: 'Insife',
        logo: insife
      },
      {
        company_name: 'Inspired Pharma Training Ltd',
        logo: inspiredPharmaTrainingLtd
      },
      {
        company_name: 'IPSEN BIOPHARM LTD',
        logo: ipsenBioharmLtd
      },
      {
        company_name: 'Kiwi Biosciences',
        logo: kiwiBiosciences
      },
      {
        company_name: 'Kuehne+Nagel Ltd',
        logo: kuehneNagelLtd
      },
      {
        company_name: 'Medisonal',
        logo: medisonal
      },
      {
        company_name: 'Micregen',
        logo: micregen
      },
      {
        company_name: 'Panthera Biopartners',
        logo: pantheraBiopartners
      },
      {
        company_name: 'Paradigm Shift Consulting Limited',
        logo: paradigmShiftConsultingLimited
      },
      {
        company_name: 'Pharsafer',
        logo: pharsafer
      },
      {
        company_name: 'Rosemont Pharmaceuticals',
        logo: rosemontPharmaceuticals
      },
      {
        company_name: 'Seda Pharmaceutical Development Services',
        logo: sedaPharmaceuticalDevelopmentServices
      },
      {
        company_name: 'Semarion',
        logo: semarion
      },
      {
        company_name: 'TauRx Pharmaceuticals Ltd',
        logo: taurxPharmaceuticalsLtd
      },
      {
        company_name: 'Tiro Training Ltd',
        logo: tiroTrainingLtd
      },
      {
        company_name: 'Uptake',
        logo: uptake
      },
      {
        company_name: 'Dawsongroup tcs',
        logo: dawsongroup
      },
      {
        company_name: 'SkyCell',
        logo: skycell
      },
      {
        company_name: 'Agility',
        logo: agility
      },
      {
        company_name: 'AquaClinical',
        logo: aquaclinical
      },
      {
        company_name: 'Arecor',
        logo: arecor
      },
      {
        company_name: 'AstraZeneca',
        logo: astrazeneca
      },
      {
        company_name: 'Besins Healthcare UK Limited',
        logo: besinsHealthcareUkLimited
      },
      {
        company_name: 'Besins Healthcare UK Ltd',
        logo: besinsHealthcareUkLimited
      },
      {
        company_name: 'Biocair',
        logo: biocare
      },
      {
        company_name: 'Bionow',
        logo: bionow
      },
      {
        company_name: 'Boyds Consultants',
        logo: boydsConsultans
      },
      {
        company_name: 'DeepX Health',
        logo: deepxHealth
      },
      {
        company_name: 'Dexcel Pharma',
        logo: dexcelPharma
      },
      {
        company_name: 'Emirates',
        logo: skycell
      },
      {
        company_name: 'Euroapi',
        logo: dawsongroup
      },
      {
        company_name: 'Fisher Scientific',
        logo: bionow
      },
      {
        company_name: 'Geodis',
        logo: skycell
      },
      {
        company_name: 'International Health Partners',
        logo: internationalHealthPartners
      },
      {
        company_name: 'Kingston University London',
        logo: kingstonUniversityLondon
      },
      {
        company_name: 'Kyowa Kirin',
        logo: kyowaKirin
      },
      {
        company_name: 'Micro-Bio Ireland Ltd',
        logo: microBioIrelandLtd
      },
      {
        company_name: 'PBD Biotech Ltd',
        logo: bionow
      },
      {
        company_name: 'Pharmanovia',
        logo: pharmanovia
      },
      {
        company_name: 'University College London  (UCL)',
        logo: universityCollegeLondonUcl
      },
      {
        company_name: 'University of East Anglia',
        logo: universityOfEastAnglia
      },
      {
        company_name: 'UPS',
        logo: skycell
      },
      {
        company_name: 'Cognizant',
        logo: cognizant
      },
      {
        company_name: 'Charles River Labs',
        logo: cbre
      },
      {
        company_name: 'CBRE',
        logo: cbre
      },
      {
        company_name: 'Expeditors',
        logo: skycell
      },
      {
        company_name: 'Johnson & Johnson',
        logo: skycell
      },
      {
        company_name: 'Marken',
        logo: skycell
      },
      {
        company_name: 'NVS',
        logo: dawsongroup
      },
      {
        company_name: 'Sterling Pharma Solutions',
        logo: bionow
      },
      {
        company_name: 'Vertex',
        logo: skycell
      },
      {
        company_name: 'Mitigo Group',
        logo: bionow
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: unispace
      },
      {
        company_name: 'Bulb Laboratories',
        logo: unispace
      },
      {
        company_name: 'Carter Jones',
        logo: unispace
      },
      {
        company_name: 'H2 Compliance',
        logo: h2Compliance
      },
      {
        company_name: 'Inspiros',
        logo: inspiros
      },
    ];
    const companyLogoHr = [
      {
        company_name: 'Approach People Recruitment',
        logo: approachPeopleRecruitment
      },
      {
        company_name: 'Bus Éireann',
        logo: busEirann
      },
      {
        company_name: 'BusinessRiver',
        logo: businessriverHr
      },
      {
        company_name: 'Carton House Resort',
        logo: cartonHouseResort
      },
      {
        company_name: 'Chadwicks Group',
        logo: chadwicksGroup
      },
      {
        company_name: 'Circet',
        logo: circet
      },
      {
        company_name: 'CluneTech',
        logo: circet
      },
      {
        company_name: 'Compliance and Risks',
        logo: complianceAndRisks
      },
      {
        company_name: 'Copacetic Business Solution Ltd',
        logo: copaceticBusinessSolutionLtd
      },
      {
        company_name: 'Copacetic Business Solutions Ltd',
        logo: copaceticBusinessSolutionLtd
      },
      {
        company_name: 'CPL',
        logo: cpl
      },
      {
        company_name: 'CPM Ireland',
        logo: cpmIreland
      },
      {
        company_name: 'Cyara Ireland Limited',
        logo: cyaraIrelandLimited
      },
      {
        company_name: 'DeCare',
        logo: decare
      },
      {
        company_name: 'Department of Health',
        logo: deparmentOfHealth
      },
      {
        company_name: 'Department of Transport',
        logo: deparmentOfTransport
      },
      {
        company_name: 'Digital Marketing Institute',
        logo: digitalMarketingInstitute
      },
      {
        company_name: 'DPD Ireland',
        logo: dpdIreland
      },
      {
        company_name: 'First Derivative',
        logo: firstDerivative
      },
      {
        company_name: 'Fragomen Ireland Ltd',
        logo: fragomenIrelandLtd
      },
      {
        company_name: 'Gleneagle Hotel Group',
        logo: gleneagleHotelGroup
      },
      {
        company_name: 'ICON PLC',
        logo: iconPlcHr
      },
      {
        company_name: 'Irish Rail',
        logo: irishRail
      },
      {
        company_name: 'Kerry Diocesan Youth Service',
        logo: kerryDiocesanYouthService
      },
      {
        company_name: 'Mater Misericordiae University Hospital',
        logo: materMisericordiaeUniversityHospital
      },
      {
        company_name: 'Mater Private Network',
        logo: materPrivateNetwork
      },
      {
        company_name: 'McKesson',
        logo: mckesson
      },
      {
        company_name: 'NIBRT',
        logo: nibrt
      },
      {
        company_name: 'Oriflame R&D Ltd',
        logo: oriflameRndLtd
      },
      {
        company_name: 'Permanent TSB',
        logo: permanentTsbHr
      },
      {
        company_name: 'Pobal',
        logo: pobal
      },
      {
        company_name: 'PTC Therapeutics',
        logo: ptcTherapeutics
      },
      {
        company_name: 'RCSI',
        logo: rcsiHr
      },
      {
        company_name: 'SD Worrx',
        logo: sdworx
      },
      {
        company_name: 'SD Worx',
        logo: sdworx
      },
      {
        company_name: 'TELUS International',
        logo: telusInternational
      },
      {
        company_name: 'Trigon Hotels',
        logo: trigonHotels
      },
      {
        company_name: 'Tusla',
        logo: tusla
      },
      {
        company_name: 'Tusla Child & Family Agency',
        logo: tusla
      },
      {
        company_name: 'Tusla Irelands Child and Family Agency',
        logo: tusla
      },
      {
        company_name: 'Valeo Foods Ireland',
        logo: valeoFoodsIreland
      },
      {
        company_name: 'Version 1',
        logo: version1
      },
      {
        company_name: 'VMware',
        logo: vmware
      },
      {
        company_name: 'Voltedge Management',
        logo: voltedgeManagement
      },
      {
        company_name: 'Woodie\'s',
        logo: woodies
      },
      {
        company_name: 'CluneTech',
        logo: clunetech
      },
      {
        company_name: 'Avolon',
        logo: avolon
      },
      {
        company_name: 'HSE',
        logo: hse
      },
      {
        company_name: 'Baxter Storey',
        logo: excelRecruitment
      },
      {
        company_name: 'Excel Recruitment',
        logo:  excelRecruitment
      },
      {
        company_name: 'Health and Safety Authority',
        logo:  healthAndSafetyAuthority
      },
      {
        company_name: 'Health Service Executive',
        logo:  healthServiceExecutive
      },
      {
        company_name: 'IDA Ireland',
        logo: idaireland
      },
      {
        company_name: 'Mason Hayes & Curran',
        logo: masonHayesCurran
      },
      {
        company_name: 'NCBI',
        logo: ncbi
      },
      {
        company_name: 'Orpea Residences Ireland',
        logo: excelRecruitment
      },
      {
        company_name: 'Primeline',
        logo: excelRecruitment
      },
      {
        company_name: 'Primeline Express',
        logo: primelineExpress
      },
      {
        company_name: 'Steering Point',
        logo: steeringPoint
      },
      {
        company_name: 'Dublin City University',
        logo: dublinCityUniversity
      },
      {
        company_name: 'Ryan HRS Ltd',
        logo: ryanHrsLtd
      },
      {
        company_name: 'NCI',
        logo: ncbi
      },
    ];
    const companyLogoPiaIE = [
      {
        company_name: 'Alexion Athlone',
        logo: alexionAthlone
      },
      {
        company_name: 'Alexion Pharma',
        logo: alexionPharma
      },
      {
        company_name: 'Amgen',
        logo: amgen
      },
      {
        company_name: 'APC & VLE Therapeutic',
        logo: apcVleTherapeutics
      },
      {
        company_name: 'Asgard Cleanroom Solutions',
        logo: asgardCleanroomSolutions
      },
      {
        company_name: 'Bausch Health',
        logo: bauschHealth
      },
      {
        company_name: 'Biocair',
        logo: biocair
      },
      {
        company_name: 'BioPharmaChem Ireland',
        logo: biopharmachemIreland
      },
      {
        company_name: 'Biostór Ireland',
        logo: biostorIreland
      },
      {
        company_name: 'Blynksolve',
        logo: blynksolve
      },
      {
        company_name: 'Boston Scientific',
        logo: bakerFinn
      },
      {
        company_name: 'Bristol Myers Squibb',
        logo: bristolMyersSquibb
      },
      {
        company_name: 'CBRE',
        logo: piaIrelandCbre
      },
      {
        company_name: 'Cognizant',
        logo: piaIrelandCognizant
      },
      {
        company_name: 'CPL',
        logo: piaIrelandcpl
      },
      {
        company_name: 'Eli Lilly',
        logo: eliLilly
      },
      {
        company_name: 'Eurofins BPT',
        logo: eurofinsBpt
      },
      {
        company_name: 'GetReSkilled',
        logo: getreskilled
      },
      {
        company_name: 'H2 Compliance',
        logo: piaIrelandh2Compliance
      },
      {
        company_name: 'Hanley Calibration',
        logo: hanleyCalibration
      },
      {
        company_name: 'Horizon Therapeutics now part of Amgen',
        logo: horizonTherapeiticsNowAPartAmgen
      },
      {
        company_name: 'Janssen Sciences Ireland',
        logo: janssenSciencesIreland
      },
      {
        company_name: 'Kiernan Steel',
        logo: kiernanSteel
      },
      {
        company_name: 'Koerber Pharma Software',
        logo: koerberPharmaSoftware
      },
      {
        company_name: 'LotusWorks',
        logo: piaIrelandLotusworks
      },
      {
        company_name: 'McKesson',
        logo: piaIrelandMckesson
      },
      {
        company_name: 'Merck KGaA',
        logo: merckKgaa
      },
      {
        company_name: 'Metabolomic Diagnostics',
        logo: metabolomicDiagnostics
      },
      {
        company_name: 'Micro-Bio Ireland',
        logo: microBioIreland
      },
      {
        company_name: 'MSD',
        logo: piaIrelandSkycell
      },
      {
        company_name: 'MSD Ireland',
        logo: msdIreland
      },
      {
        company_name: 'NIBRT',
        logo: piaIrelandNibrt
      },
      {
        company_name: 'Nutriband',
        logo: nutriband
      },
      {
        company_name: 'OBW Technologies',
        logo: obwTechnologies
      },
      {
        company_name: 'Pfizer Ireland Pharmaceuticals Ringaskiddy',
        logo: pfizerIrelandPharmaceuticalsRingaskiddy
      },
      {
        company_name: 'Pharma Integrity',
        logo: pharmaIntegrity
      },
      {
        company_name: 'Q1 Scientific',
        logo: q1Scientific
      },
      {
        company_name: 'SkyCell',
        logo: piaIrelandSkycell
      },
      {
        company_name: 'SSPC',
        logo: sspc
      },
      {
        company_name: 'Synergy',
        logo: piaIrelandSynergy
      },
      {
        company_name: 'Takeda Ireland',
        logo: takedaIreland
      },
      {
        company_name: 'TU Dublin',
        logo: piaIrelandTuDublin
      },
      {
        company_name: 'Unispace Life Sciences',
        logo: piaIrelandUnispaceLifeSciences
      },
      {
        company_name: 'Viatris Little Island',
        logo: viatrisLittleIsland
      },
      {
        company_name: 'WD Excellence',
        logo: wdExcellence
      },
      {
        company_name: 'West Pharmaceutical Services',
        logo: westPharceuticalServices
      },
      {
        company_name: 'Wolf Medical',
        logo: wolfMedical
      },
      {
        company_name: 'WuXi Biologics Ireland',
        logo:  wuxiBiologicsIreland
      },
      {
        company_name: 'Zoetis',
        logo:  zoetis
      },
      {
        company_name: 'Baker Finn',
        logo:  bakerFinn
      },
      {
        company_name: 'BOC Gases',
        logo:  bocGasses
      },
      {
        company_name: 'Camida',
        logo:  camida
      },
      {
        company_name: 'Ecolab',
        logo:  ecolab
      },
      {
        company_name: 'Ellab Ireland',
        logo:  ellabIreland
      },
      {
        company_name: 'Indaver',
        logo:  indaver
      },
      {
        company_name: 'Next Generation',
        logo:  nextGeneration
      },
      {
        company_name: 'Cognizant Life Science Manufacturing',
        logo:  cognizant
      },
      {
        company_name: 'KPC International',
        logo:  kpc
      },
      {
        company_name: 'Arcadis DPS Group',
        logo:  asgardCleanroomSolutions
      },
      {
        company_name: 'Baku GLS',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'Bonafi',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Ceva Logistics',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Consultancy',
        logo:  piaIrelandcpl
      },
      {
        company_name: 'Independent Consultant',
        logo:  camida
      },
      {
        company_name: 'John Paul Construction',
        logo:  asgardCleanroomSolutions
      },
      {
        company_name: 'Pinewood Healthcare',
        logo:  camida
      },
      {
        company_name: 'PlantQuest',
        logo:  blynksolve
      },
      {
        company_name: 'SCM Flex limited',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'Sterling',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'TBC',
        logo:  piaIrelandSkycell
      },
      {
        company_name: 'TOTEM',
        logo:  camida
      },
      {
        company_name: 'NOD Apiary',
        logo: piaIrelandcpl
      },
      {
        company_name: 'QuickSTAT A Kuehne+Nagel Company',
        logo: quickstat
      },
      {
        company_name: 'Kuehne+Nagel',
        logo: kuehne
      },
      {
        company_name: 'Nuvalez',
        logo: quickstat
      },
      {
        company_name: 'Source Bioscience',
        logo: quickstat
      },
      {
        company_name: 'Catalyx OpenBIO',
        logo: catalyx
      },
      {
        company_name: 'APC & VLE Therapeutics',
        logo: apcVleTherapeutics
      },
      {
        company_name: 'Controlled Environments Company (CEC)',
        logo: cec
      },
      {
        company_name: 'Actylis',
        logo: actylis
      },
      {
        company_name: 'AbbVie',
        logo: abbvie
      },
      {
        company_name: 'BioPharmaChem Skillnet',
        logo: biopharmachemIreland
      },
      {
        company_name: 'Titan Experience',
        logo: titanExperiencePia
      },
      {
        company_name: 'PMBRC, SETU',
        logo: pmbrc
      },
      {
        company_name: 'Munster Technological University',
        logo: mtu
      },
      {
        company_name: 'CareerVision',
        logo: careerVision
      },
      {
        company_name: 'University College Cork',
        logo: ucc
      },
      {
        company_name: 'Aramex',
        logo: biocair
      }
    ];
    const companyLogoIcia = [
      {
        company_name: 'Actavo',
        logo: actavo
      },
      {
        company_name: 'Alternative Heat',
        logo: alternativeHeat
      },
      {
        company_name: 'Alucraft',
        logo: alucraft
      },
      {
        company_name: 'Arcadis Ireland Limited',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Ardmac',
        logo: ardmac
      },
      {
        company_name: 'ArdmacArdmac',
        logo: ardmac
      },
      {
        company_name: 'ASEE',
        logo: asee
      },
      {
        company_name: 'Asee ltd',
        logo: asee
      },
      {
        company_name: 'ASEE Ltd.',
        logo: asee
      },
      {
        company_name: 'Ashview Consultants Ltd',
        logo: ashviewConsultantsLtd
      },
      {
        company_name: 'Axiseng M&E Ltd.',
        logo: axisengMELtd
      },
      {
        company_name: 'Ballymore',
        logo: ballymore
      },
      {
        company_name: 'BAM Ireland',
        logo: bamIreland
      },
      {
        company_name: 'Bennett (Construction) Ltd',
        logo: bennetConstructionLtd
      },
      {
        company_name: 'Boyle Construction',
        logo: boyleConstruction
      },
      {
        company_name: 'Breedon',
        logo: breedonIcia
      },
      {
        company_name: 'Cairn Homes',
        logo: cairnHomes
      },
      {
        company_name: 'CField Construction',
        logo: cfieldConstruction
      },
      {
        company_name: 'Clancy Engineering',
        logo: clancyEngineering
      },
      {
        company_name: 'Collen Construction',
        logo: collenConstruction
      },
      {
        company_name: 'Cunningham Civil & marine Ltd',
        logo: cunningmanCivilMarineLtd
      },
      {
        company_name: 'Duggan Brothers',
        logo: dugganBrothers
      },
      {
        company_name: 'Duke McCaffrey',
        logo: dukeMccaffrey
      },
      {
        company_name: 'Duke McCaffrey Consulting.',
        logo: dukeMccaffrey
      },
      {
        company_name: 'EDC - Engineering Design Consultants Limited',
        logo: edcEngineeringDesignConsultantsLimited
      },
      {
        company_name: 'Ethos Engineering',
        logo: ethosEngineering
      },
      {
        company_name: 'Extreme Balconies',
        logo: extremeBalconies
      },
      {
        company_name: 'Flynn',
        logo: flynn
      },
      {
        company_name: 'HOLLIS',
        logo: hollis
      },
      {
        company_name: 'Instrotec Ltd',
        logo: instrotecLtd
      },
      {
        company_name: 'JCPS Mechanical Ltd',
        logo: jcpsMechanicalLtd
      },
      {
        company_name: 'John Paul Construction',
        logo: johnPaulConstruction
      },
      {
        company_name: 'John Sisk & Son',
        logo: johnSiskSon
      },
      {
        company_name: 'Jones Engineering',
        logo: jonesEngineering
      },
      {
        company_name: 'JPM Contracts Ltd',
        logo: jpmContractsLtd
      },
      {
        company_name: 'Kelbuild Ltd',
        logo: kelbuildLtd
      },
      {
        company_name: 'Kiernan Steel Ltd',
        logo: kiernanSteel
      },
      {
        company_name: 'King & Moffatt Building Services',
        logo: kingMoffattBuildingServices
      },
      {
        company_name: 'Kirby Group',
        logo: kirbyGroup
      },
      {
        company_name: 'Lmd build',
        logo: lmdBuild
      },
      {
        company_name: 'lmd Build',
        logo: lmdBuild
      },
      {
        company_name: 'McCabe Group',
        logo: mccabeGroup
      },
      {
        company_name: 'McCauley Daye O\'Connell Architects',
        logo: mccauleyDateOConnellArchitects
      },
      {
        company_name: 'Meitheal Architects',
        logo: miethealArchitects
      },
      {
        company_name: 'Mitchell McDermott',
        logo: mitchellMcdermott
      },
      {
        company_name: 'MOLA Architecture',
        logo: molaArchitecture
      },
      {
        company_name: 'Murphy Ireland',
        logo: murphyIreland
      },
      {
        company_name: 'National Broadband Ireland',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'NBI National Broadband Ireland',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'ORS',
        logo: ors
      },
      {
        company_name: 'P McVey Building Systems',
        logo: pMcveyBuildingSystems
      },
      {
        company_name: 'QMAC Construction Ltd',
        logo: qmacConstructionLtd
      },
      {
        company_name: 'Reddy Architecture & Urbanism',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'Roccul ltd',
        logo: rocculLtd
      },
      {
        company_name: 'Scott Tallon Walker Architects',
        logo: scottTallonWalkerArchitects
      },
      {
        company_name: 'SES Water Management',
        logo: sesWaterManagement
      },
      {
        company_name: 'Shareridge',
        logo: shareridge
      },
      {
        company_name: 'SHAY MURTAGH PRECAST',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Stonecon Limited',
        logo: stoneconLimited
      },
      {
        company_name: 'Suir Engineering Ltd',
        logo: suirEngineeringLtd
      },
      {
        company_name: 'Timeless Sash Windows',
        logo: timelessSashWindows
      },
      {
        company_name: 'Titan Roofing Ltd',
        logo: titanRoofingLtd
      },
      {
        company_name: 'Truwood',
        logo: truwood
      },
      {
        company_name: 'Truwood Ltd',
        logo: truwood
      },
      {
        company_name: 'Vision Contracting',
        logo: visionContracting
      },
      {
        company_name: 'Walls Construction Ltd',
        logo: wallsConstruction
      },
      {
        company_name: 'Writech',
        logo: writech
      },
      {
        company_name: 'Boon Edam',
        logo: boomEdam
      },
      {
        company_name: 'Globoserv',
        logo: globoserv
      },
      {
        company_name: 'KCC Group',
        logo: kccGroup
      },
      {
        company_name: 'Williaam Cox',
        logo: williamCox
      },
      {
        company_name: 'Meinhardt Ireland',
        logo: meinhardtIreland
      },
      {
        company_name: 'Tech Refrigeration and Air Conditioning',
        logo: techRefrigeration
      },
      {
        company_name: 'Lumen Search',
        logo: lumenSearch
      },
      {
        company_name: 'MMC Ireland',
        logo: mmcIreland
      },
      {
        company_name: 'Considerate Constructors Scheme',
        logo: considerateConstructorsScheme
      },
      {
        company_name: 'Dynamic Safety Solutions',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'Korec Group',
        logo: nationalBroadbandIreland
      },
      {
        company_name: 'Belton Consulting Engineers Ltd.',
        logo: visionContracting
      },
      {
        company_name: 'Leading Edge Project',
        logo: visionContracting
      },
      {
        company_name: 'Red Rock Developments',
        logo: visionContracting
      },
      {
        company_name: 'DTA Architects',
        logo: visionContracting
      },
      {
        company_name: 'Actavo Guest DLRCC',
        logo: actavo
      },
      {
        company_name: 'Prestige windows and doors',
        logo: lmdBuild
      },
      {
        company_name: 'Po Mc namee design',
        logo: lmdBuild
      },
      {
        company_name: 'Patrick m Kerr architects',
        logo: lmdBuild
      },
      {
        company_name: 'Kenny cost estimating',
        logo: lmdBuild
      },
      {
        company_name: 'ARÓ architects',
        logo: lmdBuild
      },
      {
        company_name: 'Brian Conneely & Co Ltd.',
        logo: pMcveyBuildingSystems
      },
      {
        company_name: 'RDCC and Raddison Blue',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'Luxor Leisure',
        logo: reddyArchitectureUrbanism
      },
      {
        company_name: 'MMC Eco Homes',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'FTSQUARED - Development and Management Consultancy',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Revantage',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Arthur Cox LLP',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'SCSI',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'M7',
        logo: arcadisIrelandLimited
      },
      {
        company_name: 'Pennypop',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'ACEI',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'AON',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'McNally Kitchens',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'All Human',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'NRH',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Construction Industry Federation',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'Spark Digital',
        logo: shayMurtaghPrecast
      },
      {
        company_name: 'KOREC | Building Point UK & Ireland',
        logo: korec
      },
      {
        company_name: 'Industry Training Services',
        logo: its
      },
      {
        company_name: 'Ardale Property',
        logo: meinhardtIreland
      },
      {
        company_name: 'Brock McClure',
        logo: meinhardtIreland
      },
      {
        company_name: 'Clonmel Enterprises Ltd',
        logo: meinhardtIreland
      },
      {
        company_name: 'Marlet',
        logo: meinhardtIreland
      },
      {
        company_name: 'Vantage DC’s',
        logo: meinhardtIreland
      },
      {
        company_name: 'Robin Mandal Architect',
        logo: robinmandal
      },  
      {
        company_name: 'Property District',
        logo: propertyDistrict
      },
      {
        company_name: 'Technological University Dublin',
        logo: technologicalUniversity
      },
      {
        company_name: 'South East Technological University (SETU)',
        logo: southEastTechno
      },
      {
        company_name: 'Layher Ireland',
        logo: layherIreland
      },
      {
        company_name: 'Allied Irish Banks plc.',
        logo: alliedIrish
      },
      {
        company_name: 'Tom Phillips + Associates',
        logo: meinhardtIreland
      },
      {
        company_name: 'Maxol',
        logo: meinhardtIreland
      },
      {
        company_name: 'Ashview Consultants',
        logo: meinhardtIreland
      },
      {
        company_name: 'LMD Build',
        logo: lmdBuild
      },
      {
        company_name: 'Cunningham Contracts',
        logo: cunninghamContracts
      },
    ];
    const companyLogoNpa = [
      {
        company_name: 'An Post',
        logo: anPost
      },
      {
        company_name: 'Bord na Mona',
        logo: bordNaMona
      },
      {
        company_name: 'Bus Eireann',
        logo: busEireann
      },
      {
        company_name: 'Compass Group Ireland',
        logo: compassGroupIreland
      },
      {
        company_name: 'Dansko Foods',
        logo: danskoFoods
      },
      {
        company_name: 'Dublin Bus',
        logo: dublinBusNpa
      },
      {
        company_name: 'ESB',
        logo: esbNpa
      },
      {
        company_name: 'Greenville Procurement Partners',
        logo: greenvilleProcurementPartners
      },
      {
        company_name: 'Griffith College',
        logo: griffithCollege
      },
      {
        company_name: 'Health Service Executive',
        logo: healthServiceExecutiveNpa
      },
      {
        company_name: 'Heineken Ireland',
        logo: heinekenIreland
      },
      {
        company_name: 'HSE',
        logo: hseNpa
      },
      {
        company_name: 'HSE - Services for Older People',
        logo: hseServicesForOldPeople
      },
      {
        company_name: 'HSE Services for Older People',
        logo: hseServicesForOldPeopleCopy
      },
      {
        company_name: 'Iarnród Éireann - Irish Rail',
        logo: iarnodEirann
      },
      {
        company_name: 'Meade Farm Group',
        logo: meadeFarmGroup
      },
      {
        company_name: 'MSD',
        logo: msdNpa
      },
      {
        company_name: 'Musgrave',
        logo: musgrave
      },
      {
        company_name: 'OBW Technologies',
        logo: obwTechnologiesNpa
      },
      {
        company_name: 'Office of Government Procurement',
        logo: officeOfGovermentProcurement
      },
      {
        company_name: 'Penneys/Primark',
        logo: penneysPrimark
      },
      {
        company_name: 'Permanent TSB and Efficio',
        logo: permanentTsbAndEfficio
      },
      {
        company_name: 'Pfizer Ireland Pharmaceuticals',
        logo: pfizerIrelandPharmaceuticals
      },
      {
        company_name: 'Uisce Éireann',
        logo: uisceEireann
      },
      {
        company_name: 'University College Cork',
        logo: universityCollegeCork
      },
      {
        company_name: 'Verizon',
        logo: verizon
      },
      {
        company_name: 'West Pharmaceutical',
        logo: westPharmaceutical
      },
      {
        company_name: 'Velopi',
        logo: universityCollegeCork
      },
      {
        company_name: 'Transport Infrastructure Ireland',
        logo: transportInfrastructureIreland
      },
      {
        company_name: 'Bristol Myers Squibb',
        logo: bristolMyersSquibbNpa
      },
      {
        company_name: 'KPMG',
        logo: kpmgNpa
      },
    ];
    const companyLogoIcxia = [
      {
        company_name: 'Accountant Online',
        logo: accountOnline
      },
      {
        company_name: 'Aer Lingus',
        logo: aerLingusIcx
      },
      {
        company_name: 'AIB',
        logo: aibIcx
      },
      {
        company_name: 'Allview Healthcare',
        logo: allviewHealthcare
      },
      {
        company_name: 'Bank of Ireland',
        logo: bankOfIreland
      },
      {
        company_name: 'BUS EIREANN - IRISH BUS',
        logo: busEirannIrishBus
      },
      {
        company_name: 'BusinessRiver',
        logo: businessRiverIcx
      },
      {
        company_name: 'Calor',
        logo: calor
      },
      {
        company_name: 'Circit',
        logo: circit
      },
      {
        company_name: 'Coll-8 Logistics',
        logo: coll8Logisctics
      },
      {
        company_name: 'Diageo Ireland',
        logo: diageoIreland
      },
      {
        company_name: 'Dubarry of Ireland',
        logo: dubarryOfIreland
      },
      {
        company_name: 'Eason',
        logo: eason
      },
      {
        company_name: 'Electric Ireland',
        logo: electricIrelandIcx
      },
      {
        company_name: 'ESB Networks',
        logo: esbNetworks
      },
      {
        company_name: 'Fastway',
        logo: fastwayIcx
      },
      {
        company_name: 'Fragomen Ireland',
        logo: fragomenIrealandIcx
      },
      {
        company_name: 'Gas Networks Ireland',
        logo: gasNetworksIreland
      },
      {
        company_name: 'Irish Life',
        logo: irishLifeIcx
      },
      {
        company_name: 'Kara Connect',
        logo: karaConnect
      },
      {
        company_name: 'Kylemore Cars',
        logo: kylemoreCarsIcx
      },
      {
        company_name: 'KylemoreCars',
        logo: kylemoreCarsIcx
      },
      {
        company_name: 'Liberty Insurance',
        logo: libertyInsurance
      },
      {
        company_name: 'LOCO Scooters Ltd',
        logo: locoScootersLtd
      },
      {
        company_name: 'Magnet Plus',
        logo: magnetPlus
      },
      {
        company_name: 'Magnet+',
        logo: magnetPlus
      },
      {
        company_name: 'Musgrave',
        logo: musgraveIcx
      },
      {
        company_name: 'National Rehabilitation Hospital',
        logo: nationalRehabilitationHospital
      },
      {
        company_name: 'Oakview Early Years Services',
        logo: oakviewEarlyYearsServices
      },
      {
        company_name: 'Passport Service, Department of Foreign Affairs',
        logo: passportServiceDepartmentOfForeignAffairs
      },
      {
        company_name: 'Pennypop',
        logo: pennypopIcx
      },
      {
        company_name: 'Queen\'s University',
        logo: queensUniversity
      },
      {
        company_name: 'Shaws Department Stores',
        logo: shawsDepartmentStores
      },
      {
        company_name: 'SSE Airtricity',
        logo: sseAirtricity
      },
      {
        company_name: 'The Convention Centre Dublin',
        logo: theConventionCentreDublin
      },
      {
        company_name: 'Trinity Brand Commercial Services ltd',
        logo: trinityBrandCommercialServicesLtd
      },
      {
        company_name: 'University of Limerick',
        logo: universityOfLimerick
      },
    ];
    const companyLogoFoa = [
      {
        company_name: 'Alpha Mechanical Services',
        logo: alphaMechanicalServices
      },
      {
        company_name: 'Ardmac',
        logo: ardmacFoa
      },
      {
        company_name: 'ARUP',
        logo: arup
      },
      {
        company_name: 'OPW',
        logo: arup
      },
      {
        company_name: 'ASH WILSON DESIGN',
        logo: ashWilsonDesign
      },
      {
        company_name: 'Ashen & Cloud',
        logo: ashenCLoud
      },
      {
        company_name: 'Atlantic Technological University',
        logo: altanticTechnologicalUniversity
      },
      {
        company_name: 'AXISENG M AND E  LTD',
        logo: axisengME
      },
      {
        company_name: 'Axiseng M&E Ltd.',
        logo: axisengME
      },
      {
        company_name: 'Azure Contracting Ltd',
        logo: azureContractingLtd
      },
      {
        company_name: 'BSG Design',
        logo: bsgDesign
      },
      {
        company_name: 'Built Interior Ltd',
        logo: builtInteriorLtd
      },
      {
        company_name: 'Bus Eireann',
        logo: busEirannFoa
      },
      {
        company_name: 'Byrne Custom Joinery',
        logo: byrneCustomJoinery
      },
      {
        company_name: 'Calibro Workspace',
        logo: calibroWorkspace
      },
      {
        company_name: 'Calibro Workspace Ltd',
        logo: calibroWorkspace
      },
      {
        company_name: 'Castlebrook',
        logo: castleBrook
      },
      {
        company_name: 'CBRE',
        logo: cbreFoa
      },
      {
        company_name: 'Cogent Associates',
        logo: cogentAssociates
      },
      {
        company_name: 'COMMA Architects',
        logo: commaArchitects
      },
      {
        company_name: 'Corgan',
        logo: corgan
      },
      {
        company_name: 'Corgan IE Limited',
        logo: corgan
      },
      {
        company_name: 'CRM Fit-Out',
        logo: crmFitOut
      },
      {
        company_name: 'David James Retail Solutions',
        logo: davidJamesRetailSolutions
      },
      {
        company_name: 'Dore Optical',
        logo: davidJamesRetailSolutions
      },
      {
        company_name: 'Diarmuid Reil Architects',
        logo: diarmuidReilArchitects
      },
      {
        company_name: 'DMOD Architects',
        logo: ddmodArchitects
      },
      {
        company_name: 'DMVF Architects',
        logo: dmvfArchitects
      },
      {
        company_name: 'Duke McCaffrey Consulting',
        logo: dukeMccaffreyConsulting
      },
      {
        company_name: 'EDC',
        logo: edc
      },
      {
        company_name: 'Edit Design Studio',
        logo: editDesignStudio
      },
      {
        company_name: 'Elliott Group',
        logo: elliottGroup
      },
      {
        company_name: 'elm gray',
        logo: elmGray
      },
      {
        company_name: 'Ethos Engineering',
        logo: ethosEngineeringFoa
      },
      {
        company_name: 'Ethos Engineering Limited',
        logo: ethosEngineeringFoa
      },
      {
        company_name: 'GDC Interiors',
        logo: gdcInteriors
      },
      {
        company_name: 'Globoserv Ltd',
        logo: globoservLtd
      },
      {
        company_name: 'Griffith College',
        logo: griffithCollegeFoa
      },
      {
        company_name: 'Hamilton Robson',
        logo: hamiltonRobson
      },
      {
        company_name: 'Helene Broderick Interiors',
        logo: heleneBroderickInteriors
      },
      {
        company_name: 'Henry J Lyons',
        logo: henryJLyons
      },
      {
        company_name: 'Iconic Offices',
        logo: iconicOffices
      },
      {
        company_name: 'Interiors by Sisk',
        logo: interiorsBySisk
      },
      {
        company_name: 'JCA Architects',
        logo: jcaArchitects
      },
      {
        company_name: 'DBFL Consulting Engineers',
        logo: jcaArchitects
      },
      {
        company_name: 'Linesight',
        logo: jcaArchitects
      },
      {
        company_name: 'JENNINGS \\ DESIGN STUDIO Architects',
        logo: jenningsDesignStudioArchitects
      },
      {
        company_name: 'Kingston Lafferty Design',
        logo: kingstonLaffertyDesign
      },
      {
        company_name: 'Knight Frank',
        logo: knightFrank
      },
      {
        company_name: 'Pinebridge Construction',
        logo: knightFrank
      },
      {
        company_name: 'PTSB',
        logo: knightFrank
      },
      {
        company_name: 'Pinebridge Guest',
        logo: knightFrank
      },
      {
        company_name: 'KOTA',
        logo: kota
      },
      {
        company_name: 'KPMG Construction Advisory',
        logo: kpmgFoa
      },
      {
        company_name: 'LL Solutions',
        logo: llSolutions
      },
      {
        company_name: 'LUCA ARCHITECTURE',
        logo: lucaArchitecture
      },
      {
        company_name: 'LUCA Architecture',
        logo: lucaArchitecture
      },
      {
        company_name: 'MCA Architects',
        logo: mcaArchitects
      },
      {
        company_name: 'Lafferty',
        logo: mcaArchitects
      },
      {
        company_name: 'An Post',
        logo: mcaArchitects
      },
      {
        company_name: 'Image Now',
        logo: mcaArchitects
      },
      ///
      {
        company_name: 'McMahon Associates',
        logo: mcmahonAssociates
      },
      {
        company_name: 'Millimetre Design',
        logo: millimetreDesign
      },
            {
        company_name: 'MILLIMETRE DESIGN',
        logo: millimetreDesign
      },
      {
        company_name: 'MOLA Architecture',
        logo: molaArchitectureFoa
      },
      {
        company_name: 'MOLA Architecture Limited',
        logo: molaArchitectureFoa
      },
      {
        company_name: 'O\'Donnell O\'Neill Design',
        logo: oDonnelONiellDesign
      },
      {
        company_name: 'Oak PM',
        logo: oakPm
      },
      {
        company_name: 'Oppermann Associates',
        logo: oppermannAssociates
      },
      {
        company_name: 'Oppermann Associates Limited',
        logo: oppermannAssociates
      },
      {
        company_name: 'Louth & Meath ETB',
        logo: oppermannAssociates
      },
      {
        company_name: 'Semple McKillop',
        logo: oppermannAssociates
      },
      {
        company_name: 'Fire Protection Ireland',
        logo: oppermannAssociates
      },
      {
        company_name: 'Perkins & Will',
        logo: perkinsWill
      },
      {
        company_name: 'Perkins&Will',
        logo: perkinsWill
      },
      {
        company_name: 'Pure Fitout Associated Ltd',
        logo: pureFitoutAssociatedLtd
      },
      {
        company_name: 'D\'Olier Street Restaurant',
        logo: pureFitoutAssociatedLtd
      },
      {
        company_name: 'Reddy Architecture and Urbanism',
        logo: reddyArchitectureUrbanismFoa
      },
      {
        company_name: 'Roccul',
        logo: roccul
      },
      {
        company_name: 'Saul Design',
        logo: vardisGroup
      },
      {
        company_name: 'Sinéad Cassidy Design',
        logo: sineadCassidyDesign
      },
      {
        company_name: 'Sinead Cassidy Design',
        logo: sineadCassidyDesign
      },
      {
        company_name: 'Stephen Carr Architects',
        logo: stephenCarrArchitects
      },
      {
        company_name: 'Structure Tone',
        logo: structureTone
      },
      {
        company_name: 'T&I Fitouts',
        logo: tIFitouts
      },
      {
        company_name: 'TapCreative',
        logo: tapCreative
      },
      {
        company_name: 'The Interiors Group',
        logo: theInteriorGroup
      },
      {
        company_name: 'TODD Architects',
        logo: toddArchitects
      },
      {
        company_name: 'Glandore',
        logo: toddArchitects
      },
      {
        company_name: 'Graham Interior Fit out',
        logo: toddArchitects
      },
      {
        company_name: 'Mace',
        logo: toddArchitects
      },
      {
        company_name: 'GC Fitout',
        logo: toddArchitects
      },
      {
        company_name: 'Total Fitout',
        logo: totalFitout
      },
      {
        company_name: 'TUDublin',
        logo: tuDublinFoa
      },
      {
        company_name: 'Turner & Townsend',
        logo: turnerTownsend
      },
      {
        company_name: 'Vardis Group',
        logo: vardisGroup
      },
      {
        company_name: 'Wilson Architecture',
        logo: wilsonArchitecture
      },
      {
        company_name: 'Yg Interiors',
        logo: ygInteriors
      },
      {
        company_name: 'The Warehouse Gym',
        logo: ygInteriors
      },
      {
        company_name: 'Jones Engineering',
        logo: jonesEngineering
      },
      {
        company_name: 'DCON',
        logo: dcon
      },
      {
        company_name: 'Procore',
        logo: procore
      },
      {
        company_name: 'W2W',
        logo: w2w
      },
      {
        company_name: 'BENE',
        logo: bene
      },
      {
        company_name: 'OPTIMA',
        logo: optima
      },
      {
        company_name: 'Johnston Fitout',
        logo: johnstonFitout
      },
      {
        company_name: 'MJF Interiors',
        logo: mjfInteriors
      },
      {
        company_name: 'Etag',
        logo: etag
      },
      {
        company_name: 'Radii',
        logo: radii
      },
      {
        company_name: 'Solutions',
        logo: solutions
      },
      {
        company_name: 'Jan Janssens & Company',
        logo: janJanssens
      },
      {
        company_name: 'SIG Workplace',
        logo: sigWorkplace
      },
      {
        company_name: 'SIG Workplace',
        logo: sigWorkplace
      },
    ];

    const companyLogos = {
      '34': companyLogoOpex,
      '36': companyLogoLsa,
      '37': companyLogoPiaUk,
      '39': companyLogoIcia,
      '40': companyLogoHr,
      '41': companyLogoIcxia,
      '42': companyLogoBeaa,
      '43': companyLogoPiaIE,
      '44': companyLogoIsa,
      '46': companyLogoNpa,
      '47': companyLogoFoa
    }

    if (id == 22375) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={piaIrelandcpl}/>);
    } else if (id == 22506) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={camida}/>);
    } else if (id == 22075 || id == 22076 || id == 22077 || id == 22078 ) {
      return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={biopharmachemIreland}/>);
    }

    if (companyName && companyLogos[award_id]) {
      const image = companyLogos[award_id].filter(x => {
        if (x.company_name == companyName) {
          return x;
        }
      }).map(x => x);

      if(image.length < 1) {
        return '';
      } else {
        return (<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={image[0].logo}/>);
      }
    } else {
      return '';
    }
  }

  useEffect(() => {
    if (!(isLoggedIn !== 'true' && window.location.pathname.includes('admin'))) {
      getAward().then(response => {
        const selectedAward = response.data.data;
        if (selectedAward) {
          setAward(selectedAward);

          setFormData({
            ...formData,
            name: selectedAward.name,
            logo: selectedAward.logo,
            website_url: selectedAward.website_url,
            description: selectedAward.description,
            support_email: selectedAward.support_email,
            support_phone: selectedAward.support_phone,
            event: selectedAward.event,
            entry_guide_url: selectedAward.event.entry_guide_url,
            status: selectedAward.status
          });
          setVenueData({
            ...venueData,
            venue_name: selectedAward.venue.name,
          });
          setTablesLoading(false);
        } else {
          catchWarning();
        }
      }).catch(() => {
        catchError();
      });
    }
  }, []);

  useEffect(() => {
    getTables();
  }, [reloadCount])

  useEffect(() => {
    if (isLoggedIn !== 'true' && window.location.pathname.includes('admin')) {
      window.location.href = "http://businessriver.tv/";
    } else {
      const interval = setInterval( () => {
        getCheckSeat().then(response => {
          const result = response.data.seat_last_updated;
          if (result != updatedAt) {
            updatedAt = result;
            if (updatedAt) {
              getTables();
            }
          }
        }).catch(() => catchError());
      }, 8000);

      return () => clearInterval(interval);
    }
  }, []);

  const handleSearch = (e) => {
    const getTables = async (e) => {
      const selectedTables = await retrieveTablesSearch(e);
      if (selectedTables) {
        setTables(selectedTables.tables);
        setSeatingArrangementLoading(false);
        if (selectedTables.total_seats_per_table) {
          setNumberOfSeats(selectedTables.total_seats_per_table);
        }
        setTablesLoading(false);
      }
    };
    getTables(e);

    const getAttendees = async (e) => {
      const selectedAttendees = await retrieveAttendeesSearch(e);
      if (selectedAttendees) {
        setAttendeesListLoading(false);
        setAttendees(selectedAttendees);
      };
    };

    getAttendees(e);
  }

  return (
    <>
      {/*<section id="bis_award_tab">*/}
        <Container className={window.location.pathname.includes('venue/plan') ? 'h-100 mt-2' : 'mt-2'} fluid>
          {tablesLoading ?
            <>
              <PageSpinner />
            </>
            :
            <Row>
              <Col lg="12">
                <Card className={window.location.pathname.includes('venue/plan') ? 'border-0' : null}>
                  {!window.location.pathname.includes('venue/plan') ?
                    <div className='card-body py-2 px-4' style={{borderBottom: '1px solid #f2e7e7'}}>
                      {/*{window.location.pathname.includes('admin') ?*/}
                      {/*  <div className='text-right floor-plan-back'>*/}
                      {/*    <div className="bis-tooltip" onClick={() => history.goBack()}>*/}
                      {/*      /!* BACK *!/*/}
                      {/*      <FaArrowLeft className="text-black edit-icon" />*/}
                      {/*      /!* <span className="bis-tooltiptext">BACK</span> *!/*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*  :*/}
                      {/*  null*/}
                      {/*}*/}
                      <div className='floor-plan-font mb-0 d-flex justify-content-between align-items-center'>
                        <img src={formData.logo} style={{height: 'auto', width: '10%'}}/>
                        <div style={{flex: 2, paddingLeft: 20, paddingRight: 20, overflow: 'hidden' }}>
                          <SponsorsMarquee award_id={award_id} />
                        </div>
                        <div>
                          <span style={{fontSize: '3vw', fontWeight: '700'}}>Seating Plan</span>
                          <div className="floor-plan-font-divider"></div>
                          {venueData.venue_name && venueData.venue_name != "" ?
                            <p className="floor-plan-subfont mb-0" style={{fontSize: '1vw', marginTop: '6px'}}>{award.venue.name}</p>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                    :
                    null
                  }
                  <CardBody className='py-1'>
                      {tables.length > 0 ?
                        <>
                          {/* Descending */}
                          <Carousel cols={1} rows={1} containerStyle={{maxWidth: '100%', marginBottom: '0.5rem'}} showDots={false} hideArrow={true}  loop dotColorActive='#1ec2f3' autoplay={10000}>
                            {tables.map((x, tableIndex) => (
                              <Carousel.Item>
                                <Row>
                                  {x.map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (
                                    <>
                                      <Col className="mb-1" key={"attendee-table-"+index}>
                                        <Card className="h-100">
                                          <CardHeader className='p-1 d-flex align-items-center justify-content-between'>
                                            {/*<Row>*/}
                                            {/*  <Col lg="12">*/}
                                            <h5 className="modal-title text-uppercase text-left mb-0" style={{fontSize: '0.5vw'}}>Table {name}</h5>
                                            <span className='d-flex align-items-center'>
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={softworks}/>*/}
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={icon}/>*/}
                                              {/*<img style={{height: 'auto', width: 'auto', maxWidth: '2vw', maxHeight: '0.5vw'}} src={irishTimes}/>*/}
                                            </span>
                                            {/*  </Col>*/}
                                            {/*</Row>*/}
                                          </CardHeader>
                                          <CardBody className='py-0 px-1'>
                                            {attendees.length > 0 ?
                                              <>
                                                {attendees.map((user, user_index) => {
                                                  return (
                                                    <>
                                                      <div className="mb-0 d-flex justify-content-between">
                                                        <div
                                                          className='d-flex align-items-center mr-1'
                                                        >
                                                          <span className='attendee-box taken mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span
                                                            style={{
                                                              fontSize: '0.5vw',
                                                              textOverflow: 'ellipsis',
                                                              whiteSpace: 'nowrap',
                                                              overflow: 'hidden',
                                                              width: '5vw'
                                                            }}>
                                                            {user.first_name && user.last_name ?
                                                              user.first_name + ' ' + user.last_name
                                                              : user.first_name ?
                                                                user.first_name
                                                                : user.last_name ?
                                                                  user.last_name
                                                                  :
                                                                  'Reserved'
                                                            }
                                                          </span>
                                                        </div>
                                                        {/*<div className='text-center mr-1'*/}
                                                        {/*     style={{*/}
                                                        {/*       fontSize: '0.5vw',*/}
                                                        {/*       textOverflow: 'ellipsis',*/}
                                                        {/*       whiteSpace: 'nowrap',*/}
                                                        {/*       overflow: 'hidden',*/}
                                                        {/*       width: '7vw'*/}
                                                        {/*     }}>*/}
                                                        {/*  {user.company_name}*/}
                                                        {/*</div>*/}
                                                        <div className='d-flex align-items-center justify-content-end' style={{width: '2vw'}}>
                                                          {getCompanyLogos(user.company_name, user.id)}
                                                        </div>
                                                      </div>

                                                    </>
                                                  )
                                                })}
                                                {(function (rows, i, len) {
                                                  while (++i <= len) {
                                                    rows.push(
                                                      <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                        <div
                                                          className='d-flex align-items-center'
                                                        >
                                                          <span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span style={{
                                                            fontSize: '0.5vw',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: '5vw'
                                                          }}>Available</span>
                                                        </div>
                                                        <div className='text-right'
                                                             style={{
                                                               fontSize: '0.5vw',
                                                               textOverflow: 'ellipsis',
                                                               whiteSpace: 'nowrap',
                                                               overflow: 'hidden',
                                                               width: '2vw'
                                                             }}>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  return rows;
                                                })([], attendees.length, no_of_seats)}
                                              </>
                                              :
                                              <>
                                                {(function (rows, i, len) {
                                                  while (++i <= len) {
                                                    rows.push(
                                                      <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                        <div
                                                          className='d-flex align-items-center'
                                                        >
                                                          <span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>
                                                          <span style={{
                                                            fontSize: '0.5vw',
                                                            textOverflow: 'ellipsis',
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            width: '5vw'
                                                          }}>Available</span>
                                                        </div>
                                                        <div className='text-right'
                                                             style={{
                                                               fontSize: '0.5vw',
                                                               textOverflow: 'ellipsis',
                                                               whiteSpace: 'nowrap',
                                                               overflow: 'hidden',
                                                               width: '2vw'
                                                             }}>
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                  return rows;
                                                })([], 0, no_of_seats)}
                                              </>
                                            }
                                          </CardBody>
                                        </Card>
                                      </Col>
                                      {tables.length - 1 === tableIndex && x.length - 1 === index ?
                                        <>
                                          {tablesPlaceholder.map(() => (
                                            <Col>
                                              <Card className='border-0'>
                                                <CardHeader className='border-0 py-0 px-1'>
                                                  <h5 className="modal-title d-inline text-uppercase text-left" style={{fontSize: '0.5vw'}}> </h5>
                                                </CardHeader>
                                                <CardBody className='border-0 py-0 px-1'>
                                                  <div className="mb-0 d-flex align-items-center" style={{fontSize: '0.5vw'}}>
                                                    <div
                                                      className='d-flex align-items-center'
                                                    >
                                                      {/*<span className='attendee-box available mr-1' style={{height: '0.5vw', width: '0.5vw'}}></span>*/}
                                                      <span style={{
                                                        fontSize: '0.5vw',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        width: '5vw'
                                                      }}> </span>
                                                    </div>
                                                    <div className='text-right'
                                                         style={{
                                                           fontSize: '0.5vw',
                                                           textOverflow: 'ellipsis',
                                                           whiteSpace: 'nowrap',
                                                           overflow: 'hidden',
                                                           width: '2  vw'
                                                         }}>
                                                    </div>
                                                  </div>
                                                </CardBody>
                                              </Card>
                                            </Col>
                                          ))}
                                        </>
                                        :
                                        null
                                      }
                                    </>
                                  ))}
                                </Row>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                          {/*{tables.sort((a, b) => parseInt(a.name) - parseInt(b.name)).filter(table => table.category !== "other").map(({ id, image, name, no_of_seats, company_name, attendees }, index) => (*/}
                          {/*  <Col lg="4" className="mb-3" key={"attendee-table-"+index}>*/}
                          {/*    <Card className="h-100">*/}
                          {/*      <CardHeader>*/}
                          {/*        <Row>*/}
                          {/*          <Col lg="12">*/}
                          {/*            <h5 className="modal-title d-inline text-uppercase text-left">Table {name}</h5>*/}
                          {/*          </Col>*/}
                          {/*        </Row>*/}
                          {/*      </CardHeader>*/}
                          {/*      <CardBody>*/}
                          {/*        {attendees.length > 0 ?*/}
                          {/*          <>*/}
                          {/*            {attendees.map((user, user_index) => {*/}
                          {/*              return (*/}
                          {/*                <>*/}
                          {/*                  <div className="mb-2 d-flex justify-content-between">*/}
                          {/*                    <div className='d-flex align-items-center'>*/}
                          {/*                      <span className='attendee-box taken mr-1'></span>*/}
                          {/*                      {user.first_name && user.last_name ?*/}
                          {/*                        <span> {user.first_name} {user.last_name}</span>*/}
                          {/*                        : user.first_name ?*/}
                          {/*                          <span> {user.first_name}</span>*/}
                          {/*                          : user.last_name ?*/}
                          {/*                            <span> {user.last_name}</span>*/}
                          {/*                            :*/}
                          {/*                            <span> Reserved</span>*/}
                          {/*                      }*/}
                          {/*                    </div>*/}
                          {/*                    <div className='text-right'>*/}
                          {/*                      {user.company_name}*/}
                          {/*                    </div>*/}
                          {/*                  </div>*/}

                          {/*                </>*/}
                          {/*              )*/}
                          {/*            })}*/}
                          {/*            {(function (rows, i, len) {*/}
                          {/*              while (++i <= len) {*/}
                          {/*                rows.push(*/}
                          {/*                  <div className="mb-2">*/}
                          {/*                    <span className='attendee-box available mr-1'></span>*/}
                          {/*                    <span>Available</span>*/}
                          {/*                  </div>*/}
                          {/*                )*/}
                          {/*              }*/}
                          {/*              return rows;*/}
                          {/*            })([], attendees.length, no_of_seats)}*/}
                          {/*          </>*/}
                          {/*          :*/}
                          {/*          <>*/}
                          {/*            {(function (rows, i, len) {*/}
                          {/*              while (++i <= len) {*/}
                          {/*                rows.push(*/}
                          {/*                  <div className="attendee-container available mb-2">*/}
                          {/*                    <span>Available</span>*/}
                          {/*                  </div>*/}
                          {/*                )*/}
                          {/*              }*/}
                          {/*              return rows;*/}
                          {/*            })([], 0, no_of_seats)}*/}
                          {/*          </>*/}
                          {/*        }*/}
                          {/*      </CardBody>*/}
                          {/*    </Card>*/}
                          {/*  </Col>*/}
                          {/*))}*/}
                        </>
                        :
                        <div lg={12}>
                          <p className="mb-0 text-center">No records found</p>
                        </div>
                      }
                    {/*  </CardBody>*/}
                    {/*</Card>*/}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Container>
        
      {/*</section>*/}
    </>
  )
}

export default AdminVenueSeatingPage;
