import React, { useEffect, useState, useCallback, useRef } from 'react';
import AdminVenueSeatingPage from './AdminSeatPlanPage';
import AdminFloorPlanPage from './AdminFloorPlanPage';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';

const initialAward = Object.freeze({
  logo: '',
  venue_name: ''
});

const adminFloorSeatPlanPage = () => {
  const [award, setAward] = useState(initialAward);
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'userRole', 'isLoggedIn', 'awardStatus', 'isWelcome', 'isFinalist', 'token', 'userDetails']);
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');

  const currentUser = cookies.currentUser;
  const token = cookies.token;

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token);
  }

  useEffect(() => {
    getAward().then(response => {
      const selectedAward = response.data.data;
      if (selectedAward) {
        setAward(() => ({
          logo: selectedAward.logo,
          venue_name: selectedAward.venue.name
        }));
      }
    })
  }, []);

  return (
    // style={{maxWidth: '2732px'}}
    <Container fluid>
      <Card className='mt-3'>
        <CardBody>
          <Row>
            <Col lg='12'>
              <div className='card-body px-4 py-4' style={{borderBottom: '1px solid #f2e7e7'}}>
                {window.location.pathname.includes('admin') ?
                  <div className='text-right floor-plan-back'>
                    <div className="bis-tooltip" onClick={() => history.goBack()}>
                      {/* BACK */}
                      <FaArrowLeft className="text-black edit-icon" />
                      {/* <span className="bis-tooltiptext">BACK</span> */}
                    </div>
                  </div>
                  :
                  null
                }
                <p className='floor-plan-font mb-0 d-flex justify-content-between align-items-center'>
                  <img src={award.logo} style={{height: '200px'}}/>
                  <div style={{marginTop: '40px'}}>
                    <span>Floor Plan</span>
                    <div className="floor-plan-font-divider"></div>
                    {award.venue_name && award.venue_name != "" ?
                      <p className="floor-plan-subfont">{award.venue_name}</p>
                      :
                      null
                    }
                  </div>
                </p>
              </div>
            </Col>
            <Col lg='6'>
              <AdminFloorPlanPage/>
            </Col>
            <Col lg='6' className='d-flex align-items-center'>
              <AdminVenueSeatingPage/>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}

export default adminFloorSeatPlanPage;