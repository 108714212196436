import React from 'react';
import { FaCheck } from 'react-icons/fa';

const Iframe = (props) => {
    // const src = props.status;

    return (
        <div className="embed-container">
            <iframe src={props.src} style={{border:0}}></iframe>
        </div>
    );
};

export default Iframe;