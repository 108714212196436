import BisAdminAuthForm, { STATE_BIS_LOGIN } from 'components/BisAdminAuthForm';
import React from 'react';
import { Card, Col, Row, Container } from 'reactstrap';
import BISLogoWhite from '../assets/img/logo/Business-River-Logo-White.png';

class BisAdminAuthPage extends React.Component {
  handleAuthState = authState => {
    if (authState === STATE_BIS_LOGIN) {
      this.props.history.push('/login');
    } else {
      this.props.history.push('/register');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <>
        {this.props.authState == STATE_BIS_LOGIN ?
          <>
            <div className="VEPbackground d-flex align-items-center pt-5 pb-5">
              <Container className="d-flex justify-content-center">
                <Row
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white'
                  }}
                  className="login-registration-row">
                  <Col lg={5} className="VEPcardbackground">
                    <div className='bis-logo-white mt-5'>
                      <img src={BISLogoWhite} alt="Business River" />
                    </div>
                  </Col>
                  <Col lg={7} className="pt-3 pb-4 px-4 login-container" style={{backgroundColor: '#fff'}}>
                    <BisAdminAuthForm
                      authState={this.props.authState}
                      onChangeAuthState={this.handleAuthState}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
          :
          <>
            <div className="VEPbackground d-flex align-items-center pt-5 pb-5">
              <Container className="d-flex justify-content-center">
                <Row
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'white'
                  }}
                  className="login-registration-row">
                  <Col lg={12} className="VEPcardbackground mobile-only">
                    <div className='bis-logo-white mt-5'>
                      <img src={BISLogoWhite} alt="Business River" />
                    </div>
                  </Col>
                  <Col lg={12} className="pt-3 pb-4 px-4" style={{backgroundColor: '#fff'}}>
                    <BisAdminAuthForm
                      authState={this.props.authState}
                      onChangeAuthState={this.handleAuthState}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          </>
        }
      </>
      
    );
  }
}

export default BisAdminAuthPage;
