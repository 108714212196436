import React, { useState, useEffect } from 'react';
import BISlogoblue from 'assets/img/bis/logo2.png'
import PropTypes from 'prop-types';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { Card, Col, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCookies } from 'react-cookie';
import validator from 'validator'

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
  company_name: '',
  company_position: '',
  company_industry: 'Accounting',
  company_email: '',
  company_phone: '',
  first_name: '',
  last_name: '',
  contact_phone: '',
  contact_email: '',
  address_line_1: '',
  address_line_2: '',
  city: '',
  province: '',
  zip: '',
  country: '',
});

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
})

//class AuthForm extends React.Component {
function BisAdminAuthForm(props) {
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const artwork_id = query.get('artwork_id');
  const module = query.get('module');
  const surveyId = query.get('survey_id');
  const currentUrl = window.location.href;
  const [formDataRegister, setFormDataRegister] = useState(initialFormDataRegister)
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin)
  const [formStatus, setFormStatus] = useState("standby");
  const [cookies, setCookie, removeCookie] = useCookies(['currentUser', 'isLoggedIn','userRole','section']);
  // const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  const currentUser = cookies.currentUser;
  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  const isLoggedIn = cookies.isLoggedIn;
  const userRole = cookies.userRole;
  const section = cookies.section;

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;

  const [isLogin, setIsLogin] = useState(props.authState === STATE_BIS_LOGIN)

  const [isSignup, setIsSignup] = useState(props.authState === STATE_BIS_SIGNUP)

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  /*handleSubmit = event => {
    event.preventDefault();
  };*/

  const renderButtonText = (e) => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isSignup) {
      return 'Register';
    }
    return buttonText;
  }

  const handleChange = (e) => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      })
    }
  }

  useEffect(() => {
    if (currentUser) {
      if (userRole == "Admin") {
        history.push("/admin");
      } else {
        if (section) {
          history.push("/"+section+"/home");
        } else {
          removeCookie('token', { path: '/' });
          removeCookie('userDetails', { path: '/' });
          removeCookie('awardSettings', { path: '/' });
          removeCookie('isWelcome', { path: '/' });
          removeCookie('currentUser', { path: '/' });
          removeCookie('isLoggedIn', { path: '/' });
          removeCookie('userRole', { path: '/' });
          removeCookie('judge', { path: '/' });
          removeCookie('judge_id', { path: '/' });
          removeCookie('coordinator_id', { path: '/' });
          removeCookie('isAccept', { path: '/' });
          removeCookie('isProceed', { path: '/' });
          removeCookie('section', { path: '/' });
          removeCookie('award_id', { path: '/' });
          removeCookie('cookie_section', { path: '/' });
          removeCookie('cookie_award_id', { path: '/' });
          removeCookie('isSubmit', { path: '/' });
          // setTimeout(function(){
          //   history.push("/admin/login");
          // },500);
        }
      }
    }
  }, []);

  async function loginSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    if (validator.isEmail(formDataLogin.email)) {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'admin/login', {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "omit",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrer: "client",
        body: JSON.stringify({...formDataLogin, award_id: award_id, url: currentUrl}),
      });

      const json = await response.json();
      if (json.status == "Success") {
        // console.log(json);
        setFormStatus("standby");
        setCookie('userDetails', JSON.stringify(json.data), { path: '/' });
        setCookie('token', json.data.token, { path: '/' });
        setCookie('awardSettings', json.data.user.award_settings, { path: '/' });
        setCookie('currentUser', JSON.stringify(json.data.id), { path: '/' });
        setCookie('isLoggedIn', true, { path: '/' });
        setCookie('userRole', json.data.user.role, { path: '/' });
        setCookie('judge', json.data.user.judge, { path: '/' });
        setCookie('judge_id', json.data.user.judge_id, { path: '/' });
        setCookie('coordinator_id', json.data.user.coordinator_id, { path: '/' });
        setCookie('sponsorships_edit', json.data.user.sponsorships_edit, { path: '/' });
        setCookie('section', 'admin', { path: '/' });
        setCookie('cookie_section', 'admin', { path: '/' });
        setTimeout(function(){
          if (module === 'procurement') {
            history.push("/admin/artwork?artwork_id=" + artwork_id + "&award_id=" + award_id);
          } else if (surveyId) {
            history.push("/admin/award/feedbacks/list?award_id=" + award_id + "&survey_id=" + surveyId);
          } else {
            history.push("/admin");
          }
        }, 50000000000);
      } else {
        //console.log(json);
        if (json.errors.email) {
          toast.error(json.errors.email[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } if (json.errors.password) {
          toast.error(json.errors.password[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast.error(json.errors[0], {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setFormStatus("standby");
      }
    } else {
      toast.error("Please enter a valid email address", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setFormStatus("standby");
    } 
  }

  return (
    <div id="bis-form">
      <ToastContainer style={{ width: "370px" }} />
      {isLogin && (
        <Form onSubmit={loginSubmit} className="bis_login_form">
          {/* {showLogo && (
            <div className="text-center pb-4">
              <a href="/">
                <img
                  src={VEP}
                  className="rounded"
                  style={{ height: 60 }}
                  alt="logo"
                  onClick={onLogoClick}
                />
              </a>
            </div>
          )} */}
          <p className="text-center form_login">ADMIN LOGIN</p>
          <FormGroup>
            {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
            <Input {...usernameInputProps} name="email" placeholder="Email Address" className="mb-3" required onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
            <Input {...passwordInputProps} name="password" placeholder="Password" className="mb-3" required onChange={handleChange} />
          </FormGroup>
          {/* {isLogin && (
            <a href="#" className="forget_password">Forget Password</a>
          )} */}
          {formStatus != "standby" ?
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block>
              Logging in...
            </Button>
            :
            <Button
              size="lg"
              type="submit"
              className="border-0 mt-4"
              block
              onClick={loginSubmit}>
              {renderButtonText()}
            </Button>
          }
          <div className='text-center'>
            <a className='bis-link' href='/forgotpassword'>Forgot password?</a>
          </div>
          {/* <div className="text-center mt-1">
            <a href="" className="bis-link" onClick={goToRegister}>
              Register
            </a>
          </div> */}
        </Form>
      )}
      {children}
    </div>

  );
}


export const STATE_BIS_LOGIN = 'LOGIN';
export const STATE_BIS_SIGNUP = 'SIGNUP';

BisAdminAuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_BIS_LOGIN, STATE_BIS_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

BisAdminAuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => { },
};

export default BisAdminAuthForm;
