import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "setup_same_venue",
        "setup_same_venue_improvement",
        "graphic_material_correct",
        "graphic_material_correct_improvement",
        "presenters_easy",
        "presenters_easy_improvement",
        "good_atmosphere",
        "good_atmosphere_improvement",
        "people_show",
        "people_show_improvement",
        "awards_buzz",
        "awards_buzz_improvement",
        "shout_cheer",
        "shout_cheer_improvement",
        "people_dance",
        "people_dance_improvement",
        "matters_encountered",
        "guests_feedback",
        "suggestion_consideration",
    ];

    const [setupSameVenueImprovment, setSetupSameVenueImprovment] = useState({ required: false });
    const [graphicMaterialCorrectImprovment, setGraphicMaterialCorrectImprovment] = useState({ required: false });
    const [presentersEasyImprovment, setPresentersEasyImprovment] = useState({ required: false });
    const [goodAtmosphereImprovment, setGoodAtmosphereImprovment] = useState({ required: false });
    const [peopleShowImprovment, setPeopleShowImprovment] = useState({ required: false });
    const [awardsBuzzImprovment, setAwardsBuzzImprovment] = useState({ required: false });
    const [shoutCheerImprovment, setShoutCheerImprovment] = useState({ required: false });
    const [peopleDanceImprovment, setPeopleDanceImprovment] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "setup_same_venue": (value) => setSetupSameVenueImprovment(value),
                "graphic_material_correct": (value) => setGraphicMaterialCorrectImprovment(value),
                "presenters_easy": (value) => setPresentersEasyImprovment(value),
                "good_atmosphere": (value) => setGoodAtmosphereImprovment(value),
                "people_show": (value) => setPeopleShowImprovment(value),
                "awards_buzz": (value) => setAwardsBuzzImprovment(value),
                "shout_cheer": (value) => setShoutCheerImprovment(value),
                "people_dance": (value) => setPeopleDanceImprovment(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* setup_same_venue */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is the stage set-up that was signed off is the same as what was set up at the venue?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_same_venue_yes"
                            type='radio'
                            {...register("setup_same_venue", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_same_venue_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="setup_same_venue_no"
                            type='radio'
                            {...register("setup_same_venue", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="setup_same_venue_no">
                            No 
                        </Label>
                    </p>
                    {errors.setup_same_venue ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_same_venue.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {setupSameVenueImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("setup_same_venue_improvement", {
                            ...setupSameVenueImprovment
                        })}
                        className={`form-control ${errors.setup_same_venue_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.setup_same_venue_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.setup_same_venue_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* graphic_material_correct */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Are the graphic materials prepared for the event with correct information and as expected?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_material_correct_yes"
                            type='radio'
                            {...register("graphic_material_correct", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_material_correct_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_material_correct_no"
                            type='radio'
                            {...register("graphic_material_correct", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_material_correct_no">
                            No 
                        </Label>
                    </p>
                    {errors.graphic_material_correct ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_material_correct.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {graphicMaterialCorrectImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("graphic_material_correct_improvement", {
                            ...graphicMaterialCorrectImprovment
                        })}
                        className={`form-control ${errors.graphic_material_correct_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.graphic_material_correct_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_material_correct_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* presenters_easy */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the presenters easy to brief on the flow of the presentation?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="presenters_easy_yes"
                            type='radio'
                            {...register("presenters_easy", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="presenters_easy_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="presenters_easy_no"
                            type='radio'
                            {...register("presenters_easy", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="presenters_easy_no">
                            No 
                        </Label>
                    </p>
                    {errors.presenters_easy ? <p role="alert" className="invalid-feedback mb-0">{errors.presenters_easy.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {presentersEasyImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("presenters_easy_improvement", {
                            ...presentersEasyImprovment
                        })}
                        className={`form-control ${errors.presenters_easy_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.presenters_easy_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.presenters_easy_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* good_atmosphere */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was there a good atmosphere pre-event?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="good_atmosphere_yes"
                            type='radio'
                            {...register("good_atmosphere", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="good_atmosphere_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="good_atmosphere_no"
                            type='radio'
                            {...register("good_atmosphere", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="good_atmosphere_no">
                            No 
                        </Label>
                    </p>
                    {errors.good_atmosphere ? <p role="alert" className="invalid-feedback mb-0">{errors.good_atmosphere.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {goodAtmosphereImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("good_atmosphere_improvement", {
                            ...goodAtmosphereImprovment
                        })}
                        className={`form-control ${errors.good_atmosphere_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.good_atmosphere_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.good_atmosphere_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* people_show */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did people show up on time?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="people_show_yes"
                            type='radio'
                            {...register("people_show", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="people_show_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="people_show_no"
                            type='radio'
                            {...register("people_show", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="people_show_no">
                            No 
                        </Label>
                    </p>
                    {errors.people_show ? <p role="alert" className="invalid-feedback mb-0">{errors.people_show.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {peopleShowImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("people_show_improvement", {
                            ...peopleShowImprovment
                        })}
                        className={`form-control ${errors.people_show_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.people_show_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.people_show_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* awards_buzz */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                            Did the awards show have a good buzz?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="awards_buzz_yes"
                            type='radio'
                            {...register("awards_buzz", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="awards_buzz_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="awards_buzz_no"
                            type='radio'
                            {...register("awards_buzz", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="awards_buzz_no">
                            No 
                        </Label>
                    </p>
                    {errors.awards_buzz ? <p role="alert" className="invalid-feedback mb-0">{errors.awards_buzz.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {awardsBuzzImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("awards_buzz_improvement", {
                            ...awardsBuzzImprovment
                        })}
                        className={`form-control ${errors.awards_buzz_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.awards_buzz_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.awards_buzz_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* shout_cheer */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did people shout and cheer?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shout_cheer_yes"
                            type='radio'
                            {...register("shout_cheer", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shout_cheer_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shout_cheer_no"
                            type='radio'
                            {...register("shout_cheer", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shout_cheer_no">
                            No 
                        </Label>
                    </p>
                    {errors.shout_cheer ? <p role="alert" className="invalid-feedback mb-0">{errors.shout_cheer.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {shoutCheerImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("shout_cheer_improvement", {
                            ...shoutCheerImprovment
                        })}
                        className={`form-control ${errors.shout_cheer_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.shout_cheer_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.shout_cheer_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* people_dance */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did people stay and dance for the band?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="people_dance_yes"
                            type='radio'
                            {...register("people_dance", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="people_dance_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="people_dance_no"
                            type='radio'
                            {...register("people_dance", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="people_dance_no">
                            No 
                        </Label>
                    </p>
                    {errors.people_dance ? <p role="alert" className="invalid-feedback mb-0">{errors.people_dance.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {peopleDanceImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("people_dance_improvement", {
                            ...peopleDanceImprovment
                        })}
                        className={`form-control ${errors.people_dance_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.people_dance_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.people_dance_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered during the event day? &nbsp;
                    </Label>
                    <textarea
                        {...register("matters_encountered", {
                            required: false
                        })}
                        className={`form-control ${errors.matters_encountered ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.matters_encountered ? <p role="alert" className="invalid-feedback mb-0">{errors.matters_encountered.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is there any feedback that you received from the guests while you are at the venue? &nbsp;
                    </Label>
                    <textarea
                        {...register("guests_feedback", {
                            required: false
                        })}
                        className={`form-control ${errors.guests_feedback ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.guests_feedback ? <p role="alert" className="invalid-feedback mb-0">{errors.guests_feedback.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes during the event day? &nbsp;
                    </Label>
                    <textarea
                        {...register("suggestion_consideration", {
                            required: false
                        })}
                        className={`form-control ${errors.suggestion_consideration ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.suggestion_consideration ? <p role="alert" className="invalid-feedback mb-0">{errors.suggestion_consideration.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;