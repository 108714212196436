import React, { useState } from 'react';
import VEP from 'assets/img/logo/logo2.png';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useHistory } from "react-router-dom";

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
})

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
})

//class AuthForm extends React.Component {
function AuthForm(props) {
  let history = useHistory();
  const [formDataRegister, setFormDataRegister] = useState(initialFormDataRegister)
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin)
  const [formStatus, setFormStatus] = useState("standby")

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;


  const [isLogin, setIsLogin] = useState(props.authState === STATE_LOGIN)

  const [isSignup, setIsSignup] = useState(props.authState === STATE_SIGNUP)

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  /*handleSubmit = event => {
    event.preventDefault();
  };*/

  const renderButtonText = (e) => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isSignup) {
      return 'Register';
    }
    return buttonText;
  }

  const handleChange = (e) => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      })
    }
    //console.log(formDataLogin);
  }

  async function loginSubmit(e) {
    setFormStatus("loading")
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'login/admin', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "client",
      body: JSON.stringify(formDataLogin),
    });

    const json = await response.json();
    if (json.status == "Success") {
      //console.log(json);
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      sessionStorage.setItem("isLoggedIn", true);
      setFormStatus("standby");
      history.push("/");
    } else {
      //console.log(json);
      if (json.errors.email) {
        alert(json.errors.email[0]);
      } if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus("standby");
    }
  }

  async function signupSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'register', {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "client",
      body: JSON.stringify(formDataRegister),
    });

    const json = await response.json();
    console.log(json);
    if (json.status == "Success") {
      history.push("/");
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      setFormStatus("standby");
    } else {
      if (json.errors.email) {
        alert(json.errors.email[0]);
      } if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus("standby");
    }
  }
    return (
      <Form onSubmit={isLogin ? loginSubmit : signupSubmit}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={VEP}
              className="rounded"
              style={{ height: 60, cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          <Input {...usernameInputProps} name="email" onChange={handleChange} />
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          <Input {...passwordInputProps} name="password" onChange={handleChange} />
        </FormGroup>
        {isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            <Input {...confirmPasswordInputProps} name="password_confirmation" onChange={handleChange} />
          </FormGroup>
        )}
        <FormGroup check>
          {/*<Label check>
            <Input type="checkbox" />{' '}
            {this.isSignup ? 'Agree the terms and policy' : 'Remember me'}
          </Label>*/}
        </FormGroup>
        <hr />
        {formStatus !="standby" ?
          <>
            {isLogin ?
              <Button
                size="lg"
                className="border-0 btn-main mt-4"
                block
                type="submit"
                onClick={isLogin ? loginSubmit : signupSubmit}>
                Logging in...
              </Button>
              :
              <Button
                size="lg"
                className="border-0 btn-main mt-4"
                block
                type="submit"
                onClick={isLogin ? loginSubmit : signupSubmit}>
                Signing up...
              </Button>
            }
          </>
          :
          <Button
            size="lg"
            type="submit"
            className="border-0 btn-main mt-4"
            block
            onClick={isLogin ? loginSubmit : signupSubmit}>
            {renderButtonText()}
          </Button>
        }

        {/*<div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {isSignup ? (
              <a href="#login" onClick={changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div>*/}

        {children}
      </Form>
    );
  }


export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
