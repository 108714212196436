import React from 'react';
import PropTypes from 'utils/propTypes';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Media } from 'reactstrap';
import { AiFillCheckCircle } from 'react-icons/ai'
import Avatar from 'components/Avatar';
import { useState } from 'react';
import { useCookies } from 'react-cookie';

const Notifications = ({ reloadHeader, notificationsData, userRole}) => {
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const section = query.get('section');
  const [reloadCount, setReloadCount] = useState(1);
  const [cookies, setCookies, removeCookie] = useCookies(['currentUser', 'isLoggedIn', 'userRole', 'judge_id', 'token']);
  const currentUser = cookies.currentUser;
  const token = cookies.token;

  // const reloadNavigations = 
  const reloadNavigations = async () => {
    reloadHeader(reloadCount + 1);
    setReloadCount(reloadCount + 1);
  }

  async function markAsReadSubmit(e) {
    if (currentUser != "") {
      const response = await fetch(process.env.REACT_APP_API_ENDPOINT+'notification/'+e+'/read?user_id=' + currentUser + '&token=' + token, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'omit', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          'Content-Type': 'application/json',
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
        },
        redirect: 'follow', // manual, *follow, error
        referrer: 'client', // no-referrer, *client
        body: JSON.stringify({user_id: currentUser}), // body data type must match "Content-Type" header
      })

      const json = await response.json();

      if (json.status == "Success") {
        //alert("Award updated successfully");
        reloadNavigations();

      } else {
        
      }
    } else {
      
    }
  }

  return (
    <>
      {notificationsData && notificationsData.length > 0 ?
        <>
          {notificationsData.map(({ id, avatar, name, subject, message, customer_url, url, admin_url, created_at, read_at, description}, index) => (
            <>
              {read_at ?
                <>
                  <Media key={id} className="font-style n notification-single read text-decoration-none">
                    <Media left className="align-self-center pr-3">
                      <a href={url} className="text-decoration-none">
                        <Avatar tag={Media} object src={avatar} alt="Avatar" />
                      </a>
                    </Media>
                    <Media body middle className="align-self-center">
                      <a href={url} className="text-decoration-none header-notification">
                        <span className='font-weight-bold'>{subject ? subject : "-"}</span><br/>{message ? message : "-"}<br />
                        <small className="text-muted">{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}</small> <br />
                      </a>
                    </Media>
                  </Media>
                  {notificationsData.length != index + 1 ?
                    <hr />
                    :
                    null
                  }
                  
                </>
                :
                <>
                  <Media key={id} className="font-style n notification-single">
                    <Media left className="align-self-center pr-3">
                      <a href={url} className="text-decoration-none">
                        <Avatar tag={Media} object src={avatar} alt="Avatar" />
                      </a>
                    </Media>
                    <Media body middle className="align-self-center">
                      <a href={url} className="text-decoration-none header-notification">
                        <span className='font-weight-bold'>{subject ? subject : "-"}</span><br/>{message ? message : "-"}<br />
                        <small className="text-muted">{new Date(created_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric',  hour: '2-digit', minute:'2-digit' })}</small> <br />
                      </a>
                    </Media>
                    <Media right className="align-self-center">
                      <AiFillCheckCircle color="#1EC2F3" size="20" onClick={function (event) { markAsReadSubmit(id) }} />
                    </Media>
                  </Media>
                  {notificationsData.length != index + 1 ?
                    <hr />
                    :
                    null
                  }
                </>
              }
            </>
            
          ))}
        </>
        :
        <Media className="font-style n notification-single">
          <Media body middle className="align-self-center text-center">
            No new notifications
          </Media>
        </Media>
      }
    </>
  );
};

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      avatar: PropTypes.string,
      name: PropTypes.string,
      message: PropTypes.node,
      date: PropTypes.date,
    })
  ),
};

Notifications.defaultProps = {
  notificationsData: [],
};

export default Notifications;
