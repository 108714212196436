import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data, formStatus }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "updated_accordingly_gallery",
        "updated_accordingly_gallery_improvement",
        "post_event_matters",
        "post_event_process_improvement",
    ];

    const [updatedAccordinglyGalleryImprovement, setUpdatedAccordinglyGalleryImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "updated_accordingly_gallery": (value) => setUpdatedAccordinglyGalleryImprovement(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* updated_accordingly_gallery */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Were you updated accordingly when the gallery for the event has already been update?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="updated_accordingly_gallery_yes"
                            type='radio'
                            {...register("updated_accordingly_gallery", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="updated_accordingly_gallery_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="updated_accordingly_gallery_no"
                            type='radio'
                            {...register("updated_accordingly_gallery", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="updated_accordingly_gallery_no">
                            No 
                        </Label>
                    </p>
                    {errors.updated_accordingly_gallery ? <p role="alert" className="invalid-feedback mb-0">{errors.updated_accordingly_gallery.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {updatedAccordinglyGalleryImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("updated_accordingly_gallery_improvement", {
                            ...updatedAccordinglyGalleryImprovement
                        })}
                        className={`form-control ${errors.updated_accordingly_gallery_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.updated_accordingly_gallery_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.updated_accordingly_gallery_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_matters */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered after the event day?&nbsp;
                    </Label>
                    <textarea
                        {...register("post_event_matters", {
                            required: false
                        })}
                        className={`form-control ${errors.post_event_matters ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_matters ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_matters.message}</p> : null}
                </CardBody>
            </Card>

            {/* post_event_process_improvement */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes post event?&nbsp;
                    </Label>
                    <textarea
                        {...register("post_event_process_improvement", {
                            required: false
                        })}
                        className={`form-control ${errors.post_event_process_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.post_event_process_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.post_event_process_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit" disabled={formStatus !== 'standby'}>{formStatus === 'standby' ? 'SAVE' : 'SAVING...'}</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;