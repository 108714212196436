import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const PreEventFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "campaign_materials",
        "campaign_materials_improvement",
        "website_launch",
        "website_launch_improvement",
        "email_templates",
        "email_templates_improvement",
        "entries_campaign",
        "entries_campaign_improvement",
        "entries_difficulties",
        "entries_difficulties_improvement",
        "entries_delay",
        "entries_delay_improvement",
        "entrants_entry_issue",
        "entrants_entry_issue_improvement",
        "sl_deadline",
        "sl_deadline_improvement",
        "sl_announcement",
        "sl_announcement_improvement",
        "judge_recruit",
        "judge_recruit_improvement",
        "winners_approval",
        "winners_approval_improvement",
        "seat_allocation",
        "seat_allocation_improvement",
        "pre_event_preparation",
        "event_preparation"
    ];

    const [campaingMaterialImprovement, setCampaingMaterialImprovement] = useState({ required: false });
    const [websiteLaunchImprovement, setWebsiteLaunchImprovement] = useState({ required: false });
    const [emailTemplatesImprovement, setEmailTemplatesImprovement] = useState({ required: false });
    const [entriesCampaignImprovement, setEntriesCampaignImprovement] = useState({ required: false });
    const [entriesDifficultiesImprovement, setEntriesDifficultiesImprovement] = useState({ required: false });
    const [entriesDelayImprovement, setEntriesDelayImprovement] = useState({ required: false });
    const [entrantsEntryIssueImprovement, setEntrantsEntryIssueImprovement] = useState({ required: false });
    const [SLDeadlineImprovement, setSLDeadlineImprovement] = useState({ required: false });
    const [SLAnnouncementImprovement, setSLAnnouncementImprovement] = useState({ required: false });
    const [judgeRecruitImprovement, setJudgeRecruitImprovement] = useState({ required: false });
    const [winnersApprovalImprovement, setWinnersApprovalImprovement] = useState({ required: false });
    const [seatAllocationImprovement, setSeatAllocationImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "campaign_materials": (value) => setCampaingMaterialImprovement(value),
                "website_launch": (value) => setWebsiteLaunchImprovement(value),
                "email_templates": (value) => setEmailTemplatesImprovement(value),
                "entries_campaign": (value) => setEntriesCampaignImprovement(value),
                "entries_difficulties": (value) => setEntriesDifficultiesImprovement(value),
                "entries_delay": (value) => setEntriesDelayImprovement(value),
                "entrants_entry_issue": (value) => setEntrantsEntryIssueImprovement(value),
                "sl_deadline": (value) => setSLDeadlineImprovement(value),
                "sl_announcement": (value) => setSLAnnouncementImprovement(value),
                "judge_recruit": (value) => setJudgeRecruitImprovement(value),
                "winners_approval": (value) => setWinnersApprovalImprovement(value),
                "seat_allocation": (value) => setSeatAllocationImprovement(value)
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* campaign_materials */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Were the materials for the campaign prepared?&nbsp;
                        (e.g., Event Guide, Master Scoresheet, Sample Winning Entry, Etc.)&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="campaign_materials_yes"
                            type='radio'
                            {...register("campaign_materials", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="campaign_materials_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="campaign_materials_no"
                            type='radio'
                            {...register("campaign_materials", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="campaign_materials_no">
                            No 
                        </Label>
                    </p>
                    {errors.campaign_materials ? <p role="alert" className="invalid-feedback mb-0">{errors.campaign_materials.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {campaingMaterialImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("campaign_materials_improvement", {
                            ...campaingMaterialImprovement
                        })}
                        className={`form-control ${errors.campaign_materials_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.campaign_materials_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.campaign_materials_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* website_launch */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is the website properly prepared for the launch?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_launch_yes"
                            type='radio'
                            {...register("website_launch", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_launch_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_launch_no"
                            type='radio'
                            {...register("website_launch", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_launch_no">
                            No 
                        </Label>
                    </p>
                    {errors.website_launch ? <p role="alert" className="invalid-feedback mb-0">{errors.website_launch.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {websiteLaunchImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("website_launch_improvement", {
                            ...websiteLaunchImprovement
                        })}
                        className={`form-control ${errors.website_launch_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.website_launch_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.website_launch_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* email_templates */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Are the email templates that have been sent out any good?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="email_templates_yes"
                            type='radio'
                            {...register("email_templates", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="email_templates_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="email_templates_no"
                            type='radio'
                            {...register("email_templates", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="email_templates_no">
                            No 
                        </Label>
                    </p>
                    {errors.email_templates ? <p role="alert" className="invalid-feedback mb-0">{errors.email_templates.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {emailTemplatesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("email_templates_improvement", {
                            ...emailTemplatesImprovement
                        })}
                        className={`form-control ${errors.email_templates_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.email_templates_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.email_templates_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* entries_campaign */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Did the submission of entries campaign go well?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_campaign_yes"
                            type='radio'
                            {...register("entries_campaign", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_campaign_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_campaign_no"
                            type='radio'
                            {...register("entries_campaign", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_campaign_no">
                            No 
                        </Label>
                    </p>
                    {errors.entries_campaign ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_campaign.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entriesCampaignImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entries_campaign_improvement", {
                            ...entriesCampaignImprovement
                        })}
                        className={`form-control ${errors.entries_campaign_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entries_campaign_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_campaign_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* entries_difficulties */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Did entrants face any difficulties in submitting their entries through the BIS?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_difficulties_yes"
                            type='radio'
                            {...register("entries_difficulties", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_difficulties_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_difficulties_no"
                            type='radio'
                            {...register("entries_difficulties", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_difficulties_no">
                            No 
                        </Label>
                    </p>
                    {errors.entries_difficulties ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_difficulties.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entriesDifficultiesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entries_difficulties_improvement", {
                            ...entriesDifficultiesImprovement
                        })}
                        className={`form-control ${errors.entries_difficulties_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entries_difficulties_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_difficulties_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* entries_delay */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is there any delay in the submission of entries?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_delay_yes"
                            type='radio'
                            {...register("entries_delay", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_delay_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entries_delay_no"
                            type='radio'
                            {...register("entries_delay", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entries_delay_no">
                            No 
                        </Label>
                    </p>
                    {errors.entries_delay ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_delay.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entriesDelayImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entries_delay_improvement", {
                            ...entriesDelayImprovement
                        })}
                        className={`form-control ${errors.entries_delay_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entries_delay_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entries_delay_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* entrants_entry_issue */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Did the entrants had any issues with the submission of their entries through BIS&nbsp;
                        or some other concerns that you may experience. Another example is the chat function&nbsp;
                        last time in the entrant portal while the submission of entries is ongoing&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entrants_entry_issue_yes"
                            type='radio'
                            {...register("entrants_entry_issue", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entrants_entry_issue_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="entrants_entry_issue_no"
                            type='radio'
                            {...register("entrants_entry_issue", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="entrants_entry_issue_no">
                            No 
                        </Label>
                    </p>
                    {errors.entrants_entry_issue ? <p role="alert" className="invalid-feedback mb-0">{errors.entrants_entry_issue.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {entrantsEntryIssueImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("entrants_entry_issue_improvement", {
                            ...entrantsEntryIssueImprovement
                        })}
                        className={`form-control ${errors.entrants_entry_issue_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.entrants_entry_issue_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.entrants_entry_issue_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* sl_deadline */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was the target deadline for the shortlist announcement met?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sl_deadline_yes"
                            type='radio'
                            {...register("sl_deadline", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sl_deadline_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sl_deadline_no"
                            type='radio'
                            {...register("sl_deadline", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sl_deadline_no">
                            No 
                        </Label>
                    </p>
                    {errors.sl_deadline ? <p role="alert" className="invalid-feedback mb-0">{errors.sl_deadline.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {SLDeadlineImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sl_deadline_improvement", {
                            ...SLDeadlineImprovement
                        })}
                        className={`form-control ${errors.sl_deadline_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sl_deadline_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sl_deadline_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* sl_announcement */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is the website properly prepared for the shortlist announcement?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sl_announcement_yes"
                            type='radio'
                            {...register("sl_announcement", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sl_announcement_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sl_announcement_no"
                            type='radio'
                            {...register("sl_announcement", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sl_announcement_no">
                            No 
                        </Label>
                    </p>
                    {errors.sl_announcement ? <p role="alert" className="invalid-feedback mb-0">{errors.sl_announcement.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {SLAnnouncementImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sl_announcement_improvement", {
                            ...SLAnnouncementImprovement
                        })}
                        className={`form-control ${errors.sl_announcement_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sl_announcement_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sl_announcement_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* judge_recruit */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was there enough judges recruited for the event?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="judge_recruit_yes"
                            type='radio'
                            {...register("judge_recruit", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="judge_recruit_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="judge_recruit_no"
                            type='radio'
                            {...register("judge_recruit", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="judge_recruit_no">
                            No 
                        </Label>
                    </p>
                    {errors.judge_recruit ? <p role="alert" className="invalid-feedback mb-0">{errors.judge_recruit.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {judgeRecruitImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("judge_recruit_improvement", {
                            ...judgeRecruitImprovement
                        })}
                        className={`form-control ${errors.judge_recruit_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.judge_recruit_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.judge_recruit_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* winners_approval */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was there enough time for the winners to be signed off both internally and externally?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="winners_approval_yes"
                            type='radio'
                            {...register("winners_approval", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="winners_approval_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="winners_approval_no"
                            type='radio'
                            {...register("winners_approval", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="winners_approval_no">
                            No 
                        </Label>
                    </p>
                    {errors.winners_approval ? <p role="alert" className="invalid-feedback mb-0">{errors.winners_approval.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {winnersApprovalImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("winners_approval_improvement", {
                            ...winnersApprovalImprovement
                        })}
                        className={`form-control ${errors.winners_approval_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.winners_approval_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.winners_approval_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* seat_allocation */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was the seat allocation for entrants good?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_yes"
                            type='radio'
                            {...register("seat_allocation", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_no"
                            type='radio'
                            {...register("seat_allocation", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_allocation ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatAllocationImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_allocation_improvement", {
                            ...seatAllocationImprovement
                        })}
                        className={`form-control ${errors.seat_allocation_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_allocation_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* pre_event_preparation */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that have been encountered during the pre-event preparation&nbsp;
                    </Label>
                    <textarea
                        {...register("pre_event_preparation", {
                            required: false
                        })}
                        className={`form-control ${errors.pre_event_preparation ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.pre_event_preparation ? <p role="alert" className="invalid-feedback mb-0">{errors.pre_event_preparation.message}</p> : null}
                </CardBody>
            </Card>

            {/* event_preparation */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our preparations for the event?&nbsp;
                    </Label>
                    <textarea
                        {...register("event_preparation", {
                            required: false
                        })}
                        className={`form-control ${errors.event_preparation ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_preparation ? <p role="alert" className="invalid-feedback mb-0">{errors.event_preparation.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default PreEventFeedbackForm;