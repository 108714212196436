import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const PreEventFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "signoff_accurate",
        "signoff_accurate_improvement",
        "attendee_number_ontime",
        "attendee_number_ontime_improvement",
        "envelope_labels_sent",
        "envelope_labels_sent_improvement",
        "constant_communication",
        "constant_communication_improvement",
        "sponsor_collaterals_accurate",
        "sponsor_collaterals_accurate_improvement",
        "smooth_eperience_floorplan",
        "smooth_eperience_floorplan_improvement",
        "print_materials_accurate",
        "print_materials_accurate_improvement",
        "draft_script_sharing",
        "draft_script_sharing_improvement",
        "communication_smooth",
        "communication_smooth_improvement",
        "delivery_smoothly",
        "delivery_smoothly_improvement",
        "pre_event_preparation",
        "suggestion_preparation"
    ];

    const [signoffAccurateImprovment, setSignoffAccurateImprovment] = useState({ required: false });
    const [attendeeNumberOnTimeImprovment, setAttendeeNumberOnTimeImprovment] = useState({ required: false });
    const [envelopeLabelsSentImprovment, setEnvelopeLabelsSentImprovment] = useState({ required: false });
    const [constantCommunicationImprovment, setConstantCommunicationImprovment] = useState({ required: false });
    const [sponsorCollateralsAccurateImprovment, setSponsorCollateralsAccurateImprovment] = useState({ required: false });
    const [smoothExperienceFloorplanImprovment, setSmoothExperienceFloorplanImprovment] = useState({ required: false });
    const [printMaterialsAccurateImprovment, setPrintMaterialsAccurateImprovment] = useState({ required: false });
    const [draftScriptSharingImprovment, setDraftScriptSharingImprovment] = useState({ required: false });
    const [communicationSmoothImprovment, setCommunicationSmoothImprovment] = useState({ required: false });
    const [deliverySmoothlyImprovment, setDeliverySmoothlyImprovment] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "signoff_accurate": (value) => setSignoffAccurateImprovment(value),
                "attendee_number_ontime": (value) => setAttendeeNumberOnTimeImprovment(value),
                "envelope_labels_sent": (value) => setEnvelopeLabelsSentImprovment(value),
                "constant_communication": (value) => setConstantCommunicationImprovment(value),
                "sponsor_collaterals_accurate": (value) => setSponsorCollateralsAccurateImprovment(value),
                "smooth_eperience_floorplan": (value) => setSmoothExperienceFloorplanImprovment(value),
                "print_materials_accurate": (value) => setPrintMaterialsAccurateImprovment(value),
                "draft_script_sharing": (value) => setDraftScriptSharingImprovment(value),
                "communication_smooth": (value) => setCommunicationSmoothImprovment(value),
                "delivery_smoothly": (value) => setDeliverySmoothlyImprovment(value)
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* signoff_accurate */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the Event Information sent for sign-off accurate and complete?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="signoff_accurate_yes"
                            type='radio'
                            {...register("signoff_accurate", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="signoff_accurate_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="signoff_accurate_no"
                            type='radio'
                            {...register("signoff_accurate", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="signoff_accurate_no">
                            No 
                        </Label>
                    </p>
                    {errors.signoff_accurate ? <p role="alert" className="invalid-feedback mb-0">{errors.signoff_accurate.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {signoffAccurateImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("signoff_accurate_improvement", {
                            ...signoffAccurateImprovment
                        })}
                        className={`form-control ${errors.signoff_accurate_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.signoff_accurate_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.signoff_accurate_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* attendee_number_ontime */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the projection for attendee numbers completed on time?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="attendee_number_ontime_yes"
                            type='radio'
                            {...register("attendee_number_ontime", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="attendee_number_ontime_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="attendee_number_ontime_no"
                            type='radio'
                            {...register("attendee_number_ontime", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="attendee_number_ontime_no">
                            No 
                        </Label>
                    </p>
                    {errors.attendee_number_ontime ? <p role="alert" className="invalid-feedback mb-0">{errors.attendee_number_ontime.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {attendeeNumberOnTimeImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("attendee_number_ontime_improvement", {
                            ...attendeeNumberOnTimeImprovment
                        })}
                        className={`form-control ${errors.attendee_number_ontime_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.attendee_number_ontime_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.attendee_number_ontime_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* envelope_labels_sent */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the envelope labels and ‘And the winner is’ cards sent to the Live events Manager 2 weeks before the event date?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="envelope_labels_sent_yes"
                            type='radio'
                            {...register("envelope_labels_sent", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="envelope_labels_sent_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="envelope_labels_sent_no"
                            type='radio'
                            {...register("envelope_labels_sent", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="envelope_labels_sent_no">
                            No 
                        </Label>
                    </p>
                    {errors.envelope_labels_sent ? <p role="alert" className="invalid-feedback mb-0">{errors.envelope_labels_sent.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {envelopeLabelsSentImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("envelope_labels_sent_improvement", {
                            ...envelopeLabelsSentImprovment
                        })}
                        className={`form-control ${errors.envelope_labels_sent_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.envelope_labels_sent_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.envelope_labels_sent_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* constant_communication */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was there a constant communication from the pre-event live team when there are issues with seat allocation (e.g., Companies who booked tables of 12s, etc.?)&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="constant_communication_yes"
                            type='radio'
                            {...register("constant_communication", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="constant_communication_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="constant_communication_no"
                            type='radio'
                            {...register("constant_communication", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="constant_communication_no">
                            No 
                        </Label>
                    </p>
                    {errors.constant_communication ? <p role="alert" className="invalid-feedback mb-0">{errors.constant_communication.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {constantCommunicationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("constant_communication_improvement", {
                            ...constantCommunicationImprovment
                        })}
                        className={`form-control ${errors.constant_communication_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.constant_communication_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.constant_communication_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* sponsor_collaterals_accurate */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the summary of sponsor collaterals handed out with accurate information 2 weeks before the event date?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsor_collaterals_accurate_yes"
                            type='radio'
                            {...register("sponsor_collaterals_accurate", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsor_collaterals_accurate_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsor_collaterals_accurate_no"
                            type='radio'
                            {...register("sponsor_collaterals_accurate", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsor_collaterals_accurate_no">
                            No 
                        </Label>
                    </p>
                    {errors.sponsor_collaterals_accurate ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsor_collaterals_accurate.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {sponsorCollateralsAccurateImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sponsor_collaterals_accurate_improvement", {
                            ...sponsorCollateralsAccurateImprovment
                        })}
                        className={`form-control ${errors.sponsor_collaterals_accurate_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sponsor_collaterals_accurate_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsor_collaterals_accurate_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* smooth_eperience_floorplan */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did you have a smooth experience working with the BIS to lay out the floorplan?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="smooth_eperience_floorplan_yes"
                            type='radio'
                            {...register("smooth_eperience_floorplan", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="smooth_eperience_floorplan_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="smooth_eperience_floorplan_no"
                            type='radio'
                            {...register("smooth_eperience_floorplan", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="smooth_eperience_floorplan_no">
                            No 
                        </Label>
                    </p>
                    {errors.smooth_eperience_floorplan ? <p role="alert" className="invalid-feedback mb-0">{errors.smooth_eperience_floorplan.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {smoothExperienceFloorplanImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("smooth_eperience_floorplan_improvement", {
                            ...smoothExperienceFloorplanImprovment
                        })}
                        className={`form-control ${errors.smooth_eperience_floorplan_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.smooth_eperience_floorplan_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.smooth_eperience_floorplan_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* print_materials_accurate */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the print materials for the event day have accurate information and sent 2 days before the event date? (e.g., contact bibles, script, etc.)&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="print_materials_accurate_yes"
                            type='radio'
                            {...register("print_materials_accurate", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="print_materials_accurate_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="print_materials_accurate_no"
                            type='radio'
                            {...register("print_materials_accurate", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="print_materials_accurate_no">
                            No 
                        </Label>
                    </p>
                    {errors.print_materials_accurate ? <p role="alert" className="invalid-feedback mb-0">{errors.print_materials_accurate.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {printMaterialsAccurateImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("print_materials_accurate_improvement", {
                            ...printMaterialsAccurateImprovment
                        })}
                        className={`form-control ${errors.print_materials_accurate_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.print_materials_accurate_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.print_materials_accurate_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* draft_script_sharing */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the draft script sent to the Live Events Manager for sharing with the Host before the event day?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="draft_script_sharing_yes"
                            type='radio'
                            {...register("draft_script_sharing", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="draft_script_sharing_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="draft_script_sharing_no"
                            type='radio'
                            {...register("draft_script_sharing", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="draft_script_sharing_no">
                            No 
                        </Label>
                    </p>
                    {errors.draft_script_sharing ? <p role="alert" className="invalid-feedback mb-0">{errors.draft_script_sharing.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {draftScriptSharingImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("draft_script_sharing_improvement", {
                            ...draftScriptSharingImprovment
                        })}
                        className={`form-control ${errors.draft_script_sharing_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.draft_script_sharing_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.draft_script_sharing_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* communication_smooth */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is the transfer of communication for last-minute requests for sponsor collaterals smooth?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="communication_smooth_yes"
                            type='radio'
                            {...register("communication_smooth", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="communication_smooth_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="communication_smooth_no"
                            type='radio'
                            {...register("communication_smooth", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="communication_smooth_no">
                            No 
                        </Label>
                    </p>
                    {errors.communication_smooth ? <p role="alert" className="invalid-feedback mb-0">{errors.communication_smooth.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {communicationSmoothImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("communication_smooth_improvement", {
                            ...communicationSmoothImprovment
                        })}
                        className={`form-control ${errors.communication_smooth_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.communication_smooth_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.communication_smooth_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* delivery_smoothly */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                    Did the delivery of materials to the venue go smoothly?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delivery_smoothly_yes"
                            type='radio'
                            {...register("delivery_smoothly", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delivery_smoothly_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="delivery_smoothly_no"
                            type='radio'
                            {...register("delivery_smoothly", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="delivery_smoothly_no">
                            No 
                        </Label>
                    </p>
                    {errors.delivery_smoothly ? <p role="alert" className="invalid-feedback mb-0">{errors.delivery_smoothly.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {deliverySmoothlyImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("delivery_smoothly_improvement", {
                            ...deliverySmoothlyImprovment
                        })}
                        className={`form-control ${errors.delivery_smoothly_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.delivery_smoothly_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.delivery_smoothly_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that have been encountered during the pre-event preparation&nbsp;
                    </Label>
                    <textarea
                        {...register("pre_event_preparation", {
                            required: false
                        })}
                        className={`form-control ${errors.pre_event_preparation ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.pre_event_preparation ? <p role="alert" className="invalid-feedback mb-0">{errors.pre_event_preparation.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our preparations for the event?&nbsp;
                    </Label>
                    <textarea
                        {...register("suggestion_preparation", {
                            required: false
                        })}
                        className={`form-control ${errors.suggestion_preparation ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.suggestion_preparation ? <p role="alert" className="invalid-feedback mb-0">{errors.suggestion_preparation.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default PreEventFeedbackForm;