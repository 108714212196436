// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfigStaging = {
    apiKey: "AIzaSyC1TWDEt_INK3E5f__2pQ-4RQpj6slXJjU",
    authDomain: "bis-platform.firebaseapp.com",
    databaseURL: "https://bis-platform-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bis-platform",
    storageBucket: "bis-platform.appspot.com",
    messagingSenderId: "407701460090",
    appId: "1:407701460090:web:832447ac8a6a760d2897c3"
};

const firebaseConfigProduction = {
    apiKey: "AIzaSyDz3dLunaRlO4WQqIgJlI9F7o7a1QCyNCI",
    authDomain: "bis-administration.firebaseapp.com",
    projectId: "bis-administration",
    storageBucket: "bis-administration.appspot.com",
    messagingSenderId: "238855396315",
    appId: "1:238855396315:web:df0187ee9ba353c41225fe"
};

const firebaseConfig = process.env.REACT_APP_URL === 'https://businessriver.tv/' ? firebaseConfigProduction : firebaseConfigStaging;

firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();

export default firestore;