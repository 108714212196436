import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const EventDayFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "live_seat_plan",
        "live_seat_plan_improvement",
        "place_names",
        "place_names_improvement",
        "seat_allocation_easy_updates",
        "seat_allocation_easy_updates_improvement",
        "sorting_place_names",
        "sorting_place_names_improvement",
        "presenters_brief_flow",
        "presenters_brief_flow_improvement",
        "seat_plan_presenters",
        "seat_plan_presenters_improvement",
        "event_day_matters",
        "venue_guest_feedback",
        "event_process_improvement",
    ];

    const [liveSeatPlanImprovement, setLiveSeatPlanImprovement] = useState({ required: false });
    const [placeNamesImprovement, setPlaceNamesImprovement] = useState({ required: false });
    const [seatAllocationEasyUpdatesImprovement, setSeatAllocationEasyUpdatesImprovement] = useState({ required: false });
    const [sortingPlaceNamesImprovement, setSortingPlaceNamesImprovement] = useState({ required: false });
    const [presentersBriefFlowImprovement, setPresentersBriefFlowImprovement] = useState({ required: false });
    const [seatPlanPresentersImprovement, setSeatPlanPresentersImprovement] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "live_seat_plan": (value) => setLiveSeatPlanImprovement(value),
                "place_names": (value) => setPlaceNamesImprovement(value),
                "seat_allocation_easy_updates": (value) => setSeatAllocationEasyUpdatesImprovement(value),
                "sorting_place_names": (value) => setSortingPlaceNamesImprovement(value),
                "presenters_brief_flow": (value) => setPresentersBriefFlowImprovement(value),
                "seat_plan_presenters": (value) => setSeatPlanPresentersImprovement(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* live_seat_plan */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is the live seat plan always kept up to date with any changes received from the attendees?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_seat_plan_yes"
                            type='radio'
                            {...register("live_seat_plan", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_seat_plan_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="live_seat_plan_no"
                            type='radio'
                            {...register("live_seat_plan", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="live_seat_plan_no">
                            No 
                        </Label>
                    </p>
                    {errors.live_seat_plan ? <p role="alert" className="invalid-feedback mb-0">{errors.live_seat_plan.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {liveSeatPlanImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("live_seat_plan_improvement", {
                            ...liveSeatPlanImprovement
                        })}
                        className={`form-control ${errors.live_seat_plan_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.live_seat_plan_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.live_seat_plan_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* place_names */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Are most of the printed place names correct?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="place_names_yes"
                            type='radio'
                            {...register("place_names", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="place_names_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="place_names_no"
                            type='radio'
                            {...register("place_names", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="place_names_no">
                            No 
                        </Label>
                    </p>
                    {errors.place_names ? <p role="alert" className="invalid-feedback mb-0">{errors.place_names.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {placeNamesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("place_names_improvement", {
                            ...placeNamesImprovement
                        })}
                        className={`form-control ${errors.place_names_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.place_names_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.place_names_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* seat_allocation_easy_updates */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Are the updates in seat allocation of guests and or presenters easy to understand?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_easy_updates_yes"
                            type='radio'
                            {...register("seat_allocation_easy_updates", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_easy_updates_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_easy_updates_no"
                            type='radio'
                            {...register("seat_allocation_easy_updates", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_easy_updates_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_allocation_easy_updates ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_easy_updates.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatAllocationEasyUpdatesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_allocation_easy_updates_improvement", {
                            ...seatAllocationEasyUpdatesImprovement
                        })}
                        className={`form-control ${errors.seat_allocation_easy_updates_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_allocation_easy_updates_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_easy_updates_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* sorting_place_names */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Did you have enough time sorting the place names
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sorting_place_names_yes"
                            type='radio'
                            {...register("sorting_place_names", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sorting_place_names_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sorting_place_names_no"
                            type='radio'
                            {...register("sorting_place_names", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sorting_place_names_no">
                            No 
                        </Label>
                    </p>
                    {errors.sorting_place_names ? <p role="alert" className="invalid-feedback mb-0">{errors.sorting_place_names.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {sortingPlaceNamesImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sorting_place_names_improvement", {
                            ...sortingPlaceNamesImprovement
                        })}
                        className={`form-control ${errors.sorting_place_names_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sorting_place_names_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sorting_place_names_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* presenters_brief_flow */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Were the presenters easy to brief on the flow of the presentation?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="presenters_brief_flow_yes"
                            type='radio'
                            {...register("presenters_brief_flow", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="presenters_brief_flow_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="presenters_brief_flow_no"
                            type='radio'
                            {...register("presenters_brief_flow", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="presenters_brief_flow_no">
                            No 
                        </Label>
                    </p>
                    {errors.presenters_brief_flow ? <p role="alert" className="invalid-feedback mb-0">{errors.presenters_brief_flow.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {presentersBriefFlowImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("presenters_brief_flow_improvement", {
                            ...presentersBriefFlowImprovement
                        })}
                        className={`form-control ${errors.presenters_brief_flow_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.presenters_brief_flow_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.presenters_brief_flow_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* seat_plan_presenters */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Was the transfer of communication regarding the changes in the seat plan and presenters smooth?
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_plan_presenters_yes"
                            type='radio'
                            {...register("seat_plan_presenters", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_plan_presenters_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_plan_presenters_no"
                            type='radio'
                            {...register("seat_plan_presenters", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_plan_presenters_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_plan_presenters ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_plan_presenters.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatPlanPresentersImprovement.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_plan_presenters_improvement", {
                            ...seatPlanPresentersImprovement
                        })}
                        className={`form-control ${errors.seat_plan_presenters_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_plan_presenters_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_plan_presenters_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* event_day_matters */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that you have encountered during the event day?&nbsp;
                    </Label>
                    <textarea
                        {...register("event_day_matters", {
                            required: false
                        })}
                        className={`form-control ${errors.event_day_matters ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_day_matters ? <p role="alert" className="invalid-feedback mb-0">{errors.event_day_matters.message}</p> : null}
                </CardBody>
            </Card>

            {/* venue_guest_feedback */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Is there any feedback that you received from the guests while you are at the venue?&nbsp;
                    </Label>
                    <textarea
                        {...register("venue_guest_feedback", {
                            required: false
                        })}
                        className={`form-control ${errors.venue_guest_feedback ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.venue_guest_feedback ? <p role="alert" className="invalid-feedback mb-0">{errors.venue_guest_feedback.message}</p> : null}
                </CardBody>
            </Card>

            {/* event_process_improvement */}
            <Card className="mb-4">
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our processes during the event day?&nbsp;
                    </Label>
                    <textarea
                        {...register("event_process_improvement", {
                            required: false
                        })}
                        className={`form-control ${errors.event_process_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.event_process_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.event_process_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                    <button className="form-btn inline-block w-75 newbuttontheme text-white" onClick={previous}>PREVIOUS</button>
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default EventDayFeedbackForm;