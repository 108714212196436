import React, { useState, useEffect } from 'react';
import Marquee from "react-fast-marquee";
import axios from 'axios';

const SponsorsMarquee = ({ award_id }) => {
  const [sponsors, setSponsors] = useState([]);
  const [sponsorsLoading, setSponsorsLoading] = useState(true);

  const getSponsors = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'event/' + award_id + '/sponsor');
  }

  useEffect(() => {
    getSponsors().then(response => {
      const selectedSponsors = response.data.data;
      if (selectedSponsors) {
        setSponsors(selectedSponsors);
        setSponsorsLoading(false);
      } else {
        setSponsorsLoading(false);
      }
    }).catch(() => {
      console.log("Error getting sponsors")
      setSponsorsLoading(false);
    });
    
  }, []);

  return (
    <div className="marquee-container">
      {sponsorsLoading ?
        null
        :
        <Marquee autoFill={true}>
          {sponsors.map((sponsor, index) => (
            <div className="marquee-item" key={index}>
              {sponsor.website ? 
                <a href={sponsor.website ? sponsor.website : "" } target="_blank" rel="noopener noreferrer">
                  <img src={sponsor.logo} alt={sponsor.company_name} />
                </a>
                :
                <img src={sponsor.logo} alt={sponsor.company_name} />
              }
            </div>
          ))}
        </Marquee>
      }
    </div>
  );
};

export default SponsorsMarquee;
