import { toast } from 'react-toastify';

// Verbiages
const isSuccess = 'success';
const isWarning = 'warning';
const isError = 'error';

// Options
const toastOptions = {
  position: 'top-right',
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

// Toast
const toastAlert = (type, message, onClose) => {
  toastOptions.onClose = onClose;
  toast[type](message, toastOptions);
};

const catchError = (onClose) => {
  const message = 'Something went wrong. Please check your internet connection and try again!';
  toastAlert(isError, message, onClose);
};

const catchWarning = (onClose) => {
  const message = 'Something went wrong!';
  toastAlert(isWarning, message, onClose);
};

const CustomToast = {
  success: (message, onClose) => toastAlert(isSuccess, message, onClose),
  warning: (message, onClose) => toastAlert(isWarning, message, onClose),
  error: (message, onClose) => toastAlert(isError, message, onClose),
  defaultWarning: (onClose) => catchWarning(onClose),
  defaultError: (onClose) => catchError(onClose),
};

export default CustomToast;