import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Col, Row, Container } from 'reactstrap';
import { useCookies } from 'react-cookie';
import CustomToast from '../../utils/CustomToast';
import PostEventFeedback from './PostEventFeedback';
import axios from 'axios';

const PostEventFeedbackAlert = () => {
  const location = useLocation();
  const currentPathName = location.pathname;
  let history = useHistory();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  let query = useQuery();
  const award_id = query.get('award_id');
  const cookies = useCookies([
    'currentUser',
    'isLoggedIn',
    'userRole',
    'token',
    'userDetails',
  ]);
  const { admin_privileges } = cookies[0].userDetails ? cookies[0].userDetails.user : '';

  const user_id = cookies[0].userDetails ? cookies[0].userDetails.id : '';

  const current_user = cookies[0].currentUser;

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [noResponse, setNoResponse] = useState(false);

  const getSurveyCheck = async () => {
    return await axios.get(
      process.env.REACT_APP_API_ENDPOINT + 'post-event/survey/check',
      {
        params: {
          award_id: award_id,
          user_id: user_id,
        },
      },
    );
  };

  useEffect(() => {
    if (!admin_privileges && award_id && currentPathName.includes('/admin')) {
      getSurveyCheck()
        .then(response => {
          const data = response.data.response;
          if (currentPathName == '/admin/surveys') {
            setFeedbackOpen(data === 0);
          }

          setNoResponse(data === 0);
        })
        .catch(() => {
          CustomToast.defaultError();
        });
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col lg="12">
            {noResponse && ( //temporary only! show only to Laurence, IGI and VB Dev for testing
                <div
                  className="alert alert-warning mt-3 mb-0 d-flex w-100 justify-content-between align-items-center"
                  style={{ position: 'relative',  bottom: '-15px'}}
                  role="alert"
                  bis_skin_checked="1"
                >
                  <p className="mb-0">
                    Please fill up the Post-Event Feedback Form
                  </p>
                  <button
                    type="button"
                    className="btn newbtntheme"
                    onClick={() => history.push(`/admin/award/feedbacks/list?award_id=${award_id}`)}
                  >
                    Proceed
                  </button>
                </div>
              )}
          </Col>
        </Row>
      </Container>
      {/* <PostEventFeedback
        isOpen={feedbackOpen}
        onClose={e => setFeedbackOpen(e)}
      /> */}
    </>
  );
};

export default PostEventFeedbackAlert;
