import React, { useState, useEffect } from 'react';
import { Row, Col, Label, Form, Card, CardBody } from 'reactstrap';
import { useForm } from 'react-hook-form';

const PreEventFeedbackForm = ({ formSubmit, onPrevious, data }) => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        getValues,
        setValue
    } = useForm({
        mode: "onBlur",
    });

    const fields = [
        "materials_prepared",
        "materials_prepared_improvement",
        "website_prepared",
        "website_prepared_improvement",
        "emails_templates_sent",
        "emails_templates_sent_improvement",
        "difficulties_onboarding",
        "difficulties_onboarding_improvement",
        "shortlisting_process_thorough",
        "shortlisting_process_thorough_improvement",
        "consistent_communication",
        "consistent_communication_improvement",
        "target_deadline",
        "target_deadline_improvement",
        "website_shortlist_prepared",
        "website_shortlist_prepared_improvement",
        "sponsor_collateral",
        "sponsor_collateral_improvement",
        "graphic_render",
        "graphic_render_improvement",
        "summary_sponsor",
        "summary_sponsor_improvement",
        "floorplan_timely_manner",
        "floorplan_timely_manner_improvement",
        "enough_time_running",
        "enough_time_running_improvement",
        "proposed_floorplan",
        "proposed_floorplan_improvement",
        "seat_allocation_good",
        "seat_allocation_good_improvement",
        "communication_reminded",
        "communication_reminded_improvement",
        "collated_timely",
        "collated_timely_improvement",
        "transfer_communication",
        "transfer_communication_improvement",
        "encountered_pre_event_preparation",
        "suggestion_preparation_event",
    ];

    const [materialsPreparedImprovment, setMaterialsPreparedImprovment] = useState({ required: false });
    const [websitePreparedImprovment, setWebsitePreparedImprovment] = useState({ required: false });
    const [emailsTemplateSentImprovment, setEmailsTemplateSentImprovment] = useState({ required: false });
    const [difficultiesOnboardingImprovment, setDifficultiesOnboardingImprovment] = useState({ required: false });
    const [shortlistingProcessThoroughImprovment, setShortlistingProcessThoroughImprovment] = useState({ required: false });
    const [consistentCommunicationImprovment, setConsistentCommunicationImprovment] = useState({ required: false });
    const [targetDeadlineImprovment, setTargetDeadlineImprovment] = useState({ required: false });
    const [websiteShortlistPreparedImprovment, setWebsiteShortlistPreparedImprovment] = useState({ required: false });
    const [sponsorCollateralImprovment, setSponsorCollateralImprovment] = useState({ required: false });
    const [graphicRenderImprovment, setGraphicRenderImprovment] = useState({ required: false });
    const [summarySponsorImprovment, setSummarySponsorImprovment] = useState({ required: false });
    const [floorplanTimelyMannerImprovment, setFloorplanTimelyMannerImprovment] = useState({ required: false });
    const [enoughTimeRunningImprovment, setEnoughTimeRunningImprovment] = useState({ required: false });
    const [proposedFloorplanImprovment, setProposedFloorplanImprovment] = useState({ required: false });
    const [seatAllocationGoodImprovment, setSeatAllocationGoodImprovment] = useState({ required: false });
    const [communicationRemindedImprovment, setCommunicationRemindedImprovment] = useState({ required: false });
    const [collatedTimelyImprovment, setCollatedTimelyImprovment] = useState({ required: false });
    const [transferCommunicationImprovment, setTransferCommunicationImprovment] = useState({ required: false });

    const submit = (response) => {
        formSubmit(response);
    }

    const previous = (e) => {
        e.preventDefault();
        const data = getValues();
        onPrevious(data);
    }

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            const getValuesConditions = {
                "materials_prepared": (value) => setMaterialsPreparedImprovment(value),
                "website_prepared": (value) => setWebsitePreparedImprovment(value),
                "emails_templates_sent": (value) => setEmailsTemplateSentImprovment(value),
                "difficulties_onboarding": (value) => setDifficultiesOnboardingImprovment(value),
                "shortlisting_process_thorough": (value) => setShortlistingProcessThoroughImprovment(value),
                "consistent_communication": (value) => setConsistentCommunicationImprovment(value),
                "target_deadline": (value) => setTargetDeadlineImprovment(value),
                "website_shortlist_prepared": (value) => setWebsiteShortlistPreparedImprovment(value),
                "sponsor_collateral": (value) => setSponsorCollateralImprovment(value),
                "graphic_render": (value) => setGraphicRenderImprovment(value),
                "summary_sponsor": (value) => setSummarySponsorImprovment(value),
                "floorplan_timely_manner": (value) => setFloorplanTimelyMannerImprovment(value),
                "enough_time_running": (value) => setEnoughTimeRunningImprovment(value),
                "proposed_floorplan": (value) => setProposedFloorplanImprovment(value),
                "seat_allocation_good": (value) => setSeatAllocationGoodImprovment(value),
                "communication_reminded": (value) => setCommunicationRemindedImprovment(value),
                "collated_timely": (value) => setCollatedTimelyImprovment(value),
                "transfer_communication": (value) => setTransferCommunicationImprovment(value),
            };

            Object.keys(getValuesConditions).map(key => {
                if (getValues(key) === "No")
                    getValuesConditions[key]({ required: 'Required!' });
                else
                    getValuesConditions[key]({ required: false });
            });

        })
        return () => subscription.unsubscribe()
    }, [watch]);

    useEffect(() => {
        fields.map(field => setValue(field, data[field]));
    }, []);

    return (
        <Form onSubmit={handleSubmit(submit)}>

            {/* materials_prepared */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the sponsor materials prepared (e.g., Event Info, etc.)?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_prepared_yes"
                            type='radio'
                            {...register("materials_prepared", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_prepared_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="materials_prepared_no"
                            type='radio'
                            {...register("materials_prepared", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="materials_prepared_no">
                            No 
                        </Label>
                    </p>
                    {errors.materials_prepared ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_prepared.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {materialsPreparedImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("materials_prepared_improvement", {
                            ...materialsPreparedImprovment
                        })}
                        className={`form-control ${errors.materials_prepared_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.materials_prepared_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.materials_prepared_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* website_prepared */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                    Is the website properly prepared for the launch?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_prepared_yes"
                            type='radio'
                            {...register("website_prepared", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_prepared_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_prepared_no"
                            type='radio'
                            {...register("website_prepared", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_prepared_no">
                            No 
                        </Label>
                    </p>
                    {errors.website_prepared ? <p role="alert" className="invalid-feedback mb-0">{errors.website_prepared.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {websitePreparedImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("website_prepared_improvement", {
                            ...websitePreparedImprovment
                        })}
                        className={`form-control ${errors.website_prepared_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.website_prepared_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.website_prepared_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* emails_templates_sent */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                    Are the email templates that have been sent out any good?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="emails_templates_sent_yes"
                            type='radio'
                            {...register("emails_templates_sent", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="emails_templates_sent_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="emails_templates_sent_no"
                            type='radio'
                            {...register("emails_templates_sent", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="emails_templates_sent_no">
                            No 
                        </Label>
                    </p>
                    {errors.emails_templates_sent ? <p role="alert" className="invalid-feedback mb-0">{errors.emails_templates_sent.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {emailsTemplateSentImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("emails_templates_sent_improvement", {
                            ...emailsTemplateSentImprovment
                        })}
                        className={`form-control ${errors.emails_templates_sent_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.emails_templates_sent_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.emails_templates_sent_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* difficulties_onboarding */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did sponsors face any difficulties in onboarding through the BIS?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="difficulties_onboarding_yes"
                            type='radio'
                            {...register("difficulties_onboarding", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="difficulties_onboarding_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="difficulties_onboarding_no"
                            type='radio'
                            {...register("difficulties_onboarding", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="difficulties_onboarding_no">
                            No 
                        </Label>
                    </p>
                    {errors.difficulties_onboarding ? <p role="alert" className="invalid-feedback mb-0">{errors.difficulties_onboarding.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {difficultiesOnboardingImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("difficulties_onboarding_improvement", {
                            ...difficultiesOnboardingImprovment
                        })}
                        className={`form-control ${errors.difficulties_onboarding_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.difficulties_onboarding_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.difficulties_onboarding_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* shortlisting_process_thorough */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                    Was the shortlisting process thorough?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shortlisting_process_thorough_yes"
                            type='radio'
                            {...register("shortlisting_process_thorough", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shortlisting_process_thorough_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="shortlisting_process_thorough_no"
                            type='radio'
                            {...register("shortlisting_process_thorough", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="shortlisting_process_thorough_no">
                            No 
                        </Label>
                    </p>
                    {errors.shortlisting_process_thorough ? <p role="alert" className="invalid-feedback mb-0">{errors.shortlisting_process_thorough.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {shortlistingProcessThoroughImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("shortlisting_process_thorough_improvement", {
                            ...shortlistingProcessThoroughImprovment
                        })}
                        className={`form-control ${errors.shortlisting_process_thorough_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.shortlisting_process_thorough_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.shortlisting_process_thorough_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* consistent_communication */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Did the Judging Team and Pre-Event Live team maintain consistent communication regarding updates on the Shortlist Announcement?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="consistent_communication_yes"
                            type='radio'
                            {...register("consistent_communication", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="consistent_communication_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="consistent_communication_no"
                            type='radio'
                            {...register("consistent_communication", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="consistent_communication_no">
                            No 
                        </Label>
                    </p>
                    {errors.consistent_communication ? <p role="alert" className="invalid-feedback mb-0">{errors.consistent_communication.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {consistentCommunicationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("consistent_communication_improvement", {
                            ...consistentCommunicationImprovment
                        })}
                        className={`form-control ${errors.consistent_communication_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.consistent_communication_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.consistent_communication_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* target_deadline */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                    Was the target deadline for the shortlist announcement met?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="target_deadline_yes"
                            type='radio'
                            {...register("target_deadline", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="target_deadline_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="target_deadline_no"
                            type='radio'
                            {...register("target_deadline", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="target_deadline_no">
                            No 
                        </Label>
                    </p>
                    {errors.target_deadline ? <p role="alert" className="invalid-feedback mb-0">{errors.target_deadline.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {targetDeadlineImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("target_deadline_improvement", {
                            ...targetDeadlineImprovment
                        })}
                        className={`form-control ${errors.target_deadline_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.target_deadline_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.target_deadline_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* website_shortlist_prepared */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is the website properly prepared for the shortlist announcement?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_shortlist_prepared_yes"
                            type='radio'
                            {...register("website_shortlist_prepared", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_shortlist_prepared_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="website_shortlist_prepared_no"
                            type='radio'
                            {...register("website_shortlist_prepared", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="website_shortlist_prepared_no">
                            No 
                        </Label>
                    </p>
                    {errors.website_shortlist_prepared ? <p role="alert" className="invalid-feedback mb-0">{errors.website_shortlist_prepared.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {websiteShortlistPreparedImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("website_shortlist_prepared_improvement", {
                            ...websiteShortlistPreparedImprovment
                        })}
                        className={`form-control ${errors.website_shortlist_prepared_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.website_shortlist_prepared_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.website_shortlist_prepared_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* sponsor_collateral */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the sponsor collaterals collected on time?&nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsor_collateral_yes"
                            type='radio'
                            {...register("sponsor_collateral", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsor_collateral_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="sponsor_collateral_no"
                            type='radio'
                            {...register("sponsor_collateral", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="sponsor_collateral_no">
                            No 
                        </Label>
                    </p>
                    {errors.sponsor_collateral ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsor_collateral.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {sponsorCollateralImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("sponsor_collateral_improvement", {
                            ...sponsorCollateralImprovment
                        })}
                        className={`form-control ${errors.sponsor_collateral_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.sponsor_collateral_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.sponsor_collateral_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* graphic_render */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was there a 3D graphic render of the stage that was sent 6 weeks in advance before the event day? &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_render_yes"
                            type='radio'
                            {...register("graphic_render", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_render_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="graphic_render_no"
                            type='radio'
                            {...register("graphic_render", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="graphic_render_no">
                            No 
                        </Label>
                    </p>
                    {errors.graphic_render ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_render.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {graphicRenderImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("graphic_render_improvement", {
                            ...graphicRenderImprovment
                        })}
                        className={`form-control ${errors.graphic_render_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.graphic_render_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.graphic_render_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* summary_sponsor */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Were the summary of sponsor collaterals handed out to the live events team 2 weeks before the event date?  &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="summary_sponsor_yes"
                            type='radio'
                            {...register("summary_sponsor", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="summary_sponsor_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="summary_sponsor_no"
                            type='radio'
                            {...register("summary_sponsor", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="summary_sponsor_no">
                            No 
                        </Label>
                    </p>
                    {errors.summary_sponsor ? <p role="alert" className="invalid-feedback mb-0">{errors.summary_sponsor.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {summarySponsorImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("summary_sponsor_improvement", {
                            ...summarySponsorImprovment
                        })}
                        className={`form-control ${errors.summary_sponsor_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.summary_sponsor_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.summary_sponsor_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* floorplan_timely_manner */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the floorplan set up in the BIS in a timely manner?  &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="floorplan_timely_manner_yes"
                            type='radio'
                            {...register("floorplan_timely_manner", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="floorplan_timely_manner_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="floorplan_timely_manner_no"
                            type='radio'
                            {...register("floorplan_timely_manner", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="floorplan_timely_manner_no">
                            No 
                        </Label>
                    </p>
                    {errors.floorplan_timely_manner ? <p role="alert" className="invalid-feedback mb-0">{errors.floorplan_timely_manner.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {floorplanTimelyMannerImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("floorplan_timely_manner_improvement", {
                            ...floorplanTimelyMannerImprovment
                        })}
                        className={`form-control ${errors.floorplan_timely_manner_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.floorplan_timely_manner_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.floorplan_timely_manner_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* enough_time_running */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was there enough time for the running order of categories to be signed off?  &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_time_running_yes"
                            type='radio'
                            {...register("enough_time_running", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_time_running_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="enough_time_running_no"
                            type='radio'
                            {...register("enough_time_running", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="enough_time_running_no">
                            No 
                        </Label>
                    </p>
                    {errors.enough_time_running ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_time_running.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {enoughTimeRunningImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("enough_time_running_improvement", {
                            ...enoughTimeRunningImprovment
                        })}
                        className={`form-control ${errors.enough_time_running_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.enough_time_running_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.enough_time_running_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* proposed_floorplan */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the proposed floor plan for the event strategically designed?  &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="proposed_floorplan_yes"
                            type='radio'
                            {...register("proposed_floorplan", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="proposed_floorplan_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="proposed_floorplan_no"
                            type='radio'
                            {...register("proposed_floorplan", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="proposed_floorplan_no">
                            No 
                        </Label>
                    </p>
                    {errors.proposed_floorplan ? <p role="alert" className="invalid-feedback mb-0">{errors.proposed_floorplan.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {proposedFloorplanImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("proposed_floorplan_improvement", {
                            ...proposedFloorplanImprovment
                        })}
                        className={`form-control ${errors.proposed_floorplan_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.proposed_floorplan_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.proposed_floorplan_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* seat_allocation_good */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the seat allocation for sponsors good? &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_good_yes"
                            type='radio'
                            {...register("seat_allocation_good", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_good_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="seat_allocation_good_no"
                            type='radio'
                            {...register("seat_allocation_good", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="seat_allocation_good_no">
                            No 
                        </Label>
                    </p>
                    {errors.seat_allocation_good ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_good.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {seatAllocationGoodImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("seat_allocation_good_improvement", {
                            ...seatAllocationGoodImprovment
                        })}
                        className={`form-control ${errors.seat_allocation_good_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.seat_allocation_good_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.seat_allocation_good_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* communication_reminded */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was there constant communication with sponsors that keeps them reminded on the materials that they have to submit in the BIS? &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="communication_reminded_yes"
                            type='radio'
                            {...register("communication_reminded", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="communication_reminded_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="communication_reminded_no"
                            type='radio'
                            {...register("communication_reminded", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="communication_reminded_no">
                            No 
                        </Label>
                    </p>
                    {errors.communication_reminded ? <p role="alert" className="invalid-feedback mb-0">{errors.communication_reminded.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {communicationRemindedImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("communication_reminded_improvement", {
                            ...communicationRemindedImprovment
                        })}
                        className={`form-control ${errors.communication_reminded_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.communication_reminded_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.communication_reminded_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* collated_timely */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Was the guestlist and presenters collated on a timely manner? &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="collated_timely_yes"
                            type='radio'
                            {...register("collated_timely", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="collated_timely_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="collated_timely_no"
                            type='radio'
                            {...register("collated_timely", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="collated_timely_no">
                            No 
                        </Label>
                    </p>
                    {errors.collated_timely ? <p role="alert" className="invalid-feedback mb-0">{errors.collated_timely.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {collatedTimelyImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("collated_timely_improvement", {
                            ...collatedTimelyImprovment
                        })}
                        className={`form-control ${errors.collated_timely_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.collated_timely_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.collated_timely_improvement.message}</p> : null}
                </CardBody>
            </Card>

            {/* transfer_communication */}
            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Is the transfer of communication for last-minute requests for sponsor collaterals smooth?  &nbsp;
                        <span className="asterisk_red">*</span>
                    </Label>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="transfer_communication_yes"
                            type='radio'
                            {...register("transfer_communication", {
                                required: 'Required',
                            })}
                            value="Yes"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="transfer_communication_yes">Yes</Label>
                    </p>
                    <p className='mb-2 d-flex align-items-center'>
                        <input
                            id="transfer_communication_no"
                            type='radio'
                            {...register("transfer_communication", {
                                required: 'Required',
                            })}
                            value="No"
                            className='mr-2 cursor-pointer'
                        />
                        <Label className="font-weight-normal mb-0 cursor-pointer" htmlFor="transfer_communication_no">
                            No 
                        </Label>
                    </p>
                    {errors.transfer_communication ? <p role="alert" className="invalid-feedback mb-0">{errors.transfer_communication.message}</p> : null}

                    <Label className='my-2'>
                        Kindly state the matters that you have encountered and state a suggestion for improvement:&nbsp;
                        {transferCommunicationImprovment.required === "Required!" && (<span className="asterisk_red">*</span>)}
                    </Label>
                    <textarea
                        {...register("transfer_communication_improvement", {
                            ...transferCommunicationImprovment
                        })}
                        className={`form-control ${errors.transfer_communication_improvement ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.transfer_communication_improvement ? <p role="alert" className="invalid-feedback mb-0">{errors.transfer_communication_improvement.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other matters that have been encountered during the pre-event preparation?&nbsp;
                    </Label>
                    <textarea
                        {...register("encountered_pre_event_preparation", {
                            required: false
                        })}
                        className={`form-control ${errors.encountered_pre_event_preparation ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.encountered_pre_event_preparation ? <p role="alert" className="invalid-feedback mb-0">{errors.encountered_pre_event_preparation.message}</p> : null}
                </CardBody>
            </Card>

            <Card className='mb-4'>
                <CardBody>
                    <Label className='my-2'>
                        Any other suggestion that we can take into consideration to improve our preparations for the event? &nbsp;
                    </Label>
                    <textarea
                        {...register("suggestion_preparation_event", {
                            required: false
                        })}
                        className={`form-control ${errors.suggestion_preparation_event ? "form-invalid mb-0" : "mb-0"}`}
                    />
                    {errors.suggestion_preparation_event ? <p role="alert" className="invalid-feedback mb-0">{errors.suggestion_preparation_event.message}</p> : null}
                </CardBody>
            </Card>

            <Row>
                <Col md="6" className="text-left mt-3">
                </Col>
                <Col md="6" className="text-right mt-3">
                    <button className="form-btn inline-block w-50 newbuttontheme text-white submit-btn" type="submit">NEXT</button>
                </Col>
            </Row>
        </Form>
    )
}

export default PreEventFeedbackForm;