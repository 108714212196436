import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom'
import { useCookies } from 'react-cookie';
import { Container } from 'reactstrap';
import { FaArrowLeft } from 'react-icons/fa';
import { toJpeg } from 'html-to-image';
import axios from 'axios';
import html2pdf from 'html2pdf.js/src';
import CustomToast from '../utils/CustomToast';

const initialFormData = Object.freeze({
  name: '',
  description: '',
  awards: '',
  logo: '',
  s3_logo: '',
  schedule_1: '',
  schedule_2: '',
  schedule_3: '',
  schedule_4: '',
  schedule_5: '',
  website_url: '',
  finalists_url: '',
  judges_url: '',
  about_url: '',
  support_email: '',
  support_phone: '',
  host: '',
  host_description: '',
  start: '',
  location: '',
  dacast_url: '',
  vimeo_url: '',
  entry_guide_url: '',
  status: '',
});

const initialVenueData = Object.freeze({
  venue_id: '',
  stage_length: '',
  stage_width: '',
  table_length: '',
  table_width: '',
  table_type: '',
  number_of_tables: '',
  total_seats_per_table: '',
  venue_name: '',
});

const AdminFloorPlanPage = () => {
  let history = useHistory();
  const seatingRef = useRef(null);
  const stageRef = useRef();
  const [formData, setFormData] = useState(initialFormData);
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const award_id = query.get('award_id');
  const download = query.get('download');
  const [award, setAward] = useState();

  const [cookies, setCookies, removeCookies] = useCookies(['currentUser', 'userRole', 'isLoggedIn', 'awardStatus', 'isWelcome', 'isFinalist', 'token', 'userDetails', 'updatedAt']);
  const currentUser = cookies.currentUser;
  const token = cookies.token;
  const isLoggedIn = cookies.isLoggedIn;
  let updatedAt = '';

  const [venueData, setVenueData] = useState(initialVenueData);
  const [stagePositionX, setStagePositionX] = useState(0);
  const [stagePositionY, setStagePositionY] = useState(0);
  const [tablesPlan, setTablesPlan] = useState([]);
  const [seatingArrangementLoading, setSeatingArrangementLoading] = useState(true);
  const [seatingImg, setSeatingImg] = useState('');

  const [venueSize, setVenueSize] = useState(0);

  const getAward = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '?user_id=' + currentUser + '&token=' + token);
  }

  const getTablesNew = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/venue/table?user_id=' + currentUser + '&token=' + token);
  }

  const getCheckFloor = async () => {
    return await axios.get(process.env.REACT_APP_API_ENDPOINT + 'award/' + award_id + '/floor/check?user_id=' + currentUser + '&token=' + token);
  }

  const computedDimension = (size) => {
    return (size / venueSize) * 1200;
  }

  const reload = async () => {
    if (isLoggedIn !== 'true' && window.location.pathname.includes('admin')) {
      window.location.href = "http://businessriver.tv/";
    } else {
      await getAward().then( async response => {
        const selectedAward = response.data.data;
        if (selectedAward) {
          setAward(selectedAward);

          const toDataURL = async url => await fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
              const reader = new FileReader()
              reader.onloadend = () => resolve(reader.result)
              reader.onerror = reject
              reader.readAsDataURL(blob)
            }));

          await toDataURL(selectedAward.s3_logo)
            .then(dataUrl => {
              let count = 0
              for (const img of document.querySelectorAll("img")) {
                if (count === 0) {
                  img.src = dataUrl;
                  count += 1;
                }
              }
            })

          setFormData({
            ...formData,
            name: selectedAward.name,
            website_url: selectedAward.website_url,
            description: selectedAward.description,
            support_email: selectedAward.support_email,
            support_phone: selectedAward.support_phone,
            event: selectedAward.event,
            entry_guide_url: selectedAward.event.entry_guide_url,
            status: selectedAward.status
          });

          setStagePositionX(Number(selectedAward.stage_position_x));
          setStagePositionY(Number(selectedAward.stage_position_y));

          setVenueSize(() => selectedAward.venue.dimension_width);
          setVenueData({
            ...venueData,
            venue_id: selectedAward.venue_id,
            stage_container_length: (selectedAward.stage_length / selectedAward.venue.dimension_width * 1200).toFixed(0),
            stage_container_width: (selectedAward.stage_width / selectedAward.venue.dimension_width * 1200).toFixed(0),
            stage_length: selectedAward.stage_length,
            stage_width: selectedAward.stage_width,
            number_of_tables: selectedAward.number_of_tables,
            total_seats_per_table: selectedAward.total_seats_per_table,
            venue_name: selectedAward.venue.name,
            venue_background: selectedAward.venue.background,
            venue_height: (selectedAward.venue.dimension_length / selectedAward.venue.dimension_width * 1200).toFixed(0)
          });

          if (download === '1') {
            const formattedName = selectedAward.award_type.code + '-' + selectedAward.year;
            await generatePDF(formattedName);
          }

        } else {
          CustomToast.defaultWarning();
        }
      }).catch(() => {
        CustomToast.defaultWarning();
      });

      await getTablesNew().then(response => {
        const result = response.data.data;
        if (result) {
          setTablesPlan(() => [...result]);
          setSeatingArrangementLoading(false);

        } else {
          CustomToast.defaultWarning();
        }
      }).catch(() => {
          CustomToast.defaultError();
      });
    }
  }

  const generatePDF = async (name) => {
    
    const convertToBase64 = async () => {
      try {
        if (seatingRef.current) {
          seatingRef.current.style.backgroundColor = '#ffffff';
          const dataUrl = await toJpeg(seatingRef.current);
          setSeatingImg(dataUrl);
          if (dataUrl && dataUrl != "") {
            seatingRef.current.style.display = 'none';

            const pdfOptions = {
              margin: 0,
              filename: name + '-floor-plan.pdf',
              image: {
                type: 'jpeg',
                quality: 100
              },
              html2canvas: {
                scale: 4,
                dpi: 150,
                // letterRendering: true,
                foreignObjectRendering: false // Add this option for better rendering of complex HTML
              },
              jsPDF: { unit: "in", format: "a4", orientation: "portrait" }
            }
        
            const pages = Array.from(document.querySelectorAll('.modal-preview'));
        
            const downloadPDF = (elements, pdfOptions) => {
              let worker = html2pdf()
                .set(pdfOptions)
                .from(elements[0])
        
              if (elements.length > 1) {
                worker = worker.toPdf() // worker is now a jsPDF instance
        
                // add each element/page individually to the PDF render process
                elements.slice(1).forEach((element, index) => {
                  worker = worker
                    .get('pdf')
                    .then(pdf => {
                      pdf.addPage()
                    })
                    .from(element)
                    .toContainer()
                    .toCanvas()
                    .toPdf()
                })
              }
        
              worker = worker.save();
              worker.then(() => window.close());
            }
        
            function createPdf() {
              downloadPDF(pages, pdfOptions);
            }
        
            createPdf();
          }
        }
      } catch (error) {
        console.error('Error converting to Base64:', error);
      }
    };

    convertToBase64();
  }

  useEffect(() => {
    reload();
  }, []);

  function separateName(text) {
    const replacedText = text.replace("-", "<br>");

    return replacedText;
  }

  useEffect(() => {
    const interval = setInterval( () => {
      getCheckFloor().then(response => {
        const result = response.data.floor_last_updated;
        if (result != updatedAt) {
          updatedAt = result;
          reload();
        }
      }).catch(() => CustomToast.defaultWarning());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <section id="bis_award_tab">
        {seatingArrangementLoading ?
          null
          :
          <Container style={{maxWidth: '1200px', overflow: 'hidden'}} fluid>
            <div id='floor-plan-container' className="card modal-preview" style={{width: '100%', backgroundColor: '#fff'}} ref={stageRef}>
              {!window.location.pathname.includes('venue/plan') ?
                <div id="element-to-hide" className='card-body px-4 py-4' style={{borderBottom: '1px solid #f2e7e7'}}>
                  {window.location.pathname.includes('admin') ?
                    <div className='text-right floor-plan-back' data-html2canvas-ignore="true">
                      <div className="bis-tooltip" onClick={() => history.goBack()}>
                        <FaArrowLeft className="text-black edit-icon" />
                      </div>
                    </div>
                    :
                    null
                  }
                  <p className='floor-plan-font d-flex justify-content-between align-items-center'>
                    <img style={{height: '200px'}}/>
                    <div style={{marginTop: '40px'}}>
                      <span>Floor Plan</span>
                      <div className="floor-plan-font-divider"></div>
                      {award.venue.name && award.venue.name != "" ?
                        <p className="floor-plan-subfont">{award.venue.name}</p>
                        :
                        null
                      }
                    </div>
                  </p>
                </div>
                :
                null
              }
              {seatingImg && seatingImg != "" ?
                <img src={seatingImg} className="w-100" />
                :
                null
              }
              <div ref={seatingRef} className="seat_container card-body border-left-0 border-right-0 border-bottom-0 position-relative" style={{height: venueData.venue_height + "px", borderTop: '2px #f9f9f9 solid'}}>
                {venueData.venue_background ? 
                  <img className='venue-plan-background' crossOrigin='Anonymous' src={venueData.venue_background}/>
                  :
                  null
                }
                <div className="stage-box box m-3 react-draggable react-draggable card rounded"
                     style={{color: '#ffffff', backgroundColor: '#1EC2F3', border: '2px #1EC2F3 solid', width: venueData.stage_container_width + "px", height: venueData.stage_container_length + "px", transform: 'translate(' + stagePositionX +'px, ' + stagePositionY + 'px)'}}>
                  <div className="card-body"><h2 id="stage" className="award-subheader text-center mb-0">Stage</h2></div>
                </div>
                {tablesPlan.map((table) => (
                  <>
                    {table.category !== "other" ?
                      <div
                        className=" box m-3 table-card react-draggable d-flex align-items-center justify-content-center react-draggable"
                        style={{
                          borderRadius: '100%',
                          border: '2px #1ec2f3 solid',
                          color: '#000',
                          width: computedDimension(table.width) + "px",
                          height: computedDimension(table.length) + "px",
                          backgroundColor: '#fff',
                          transform: `translate(${table.position_x? table.position_x : 0}px, ${table.position_y? table.position_y : 0}px)`
                        }}>
                        <div className="seat-container">
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '-44px'} : [ 2, 3 ].includes(venueData.venue_id) ? {top: '-31px', right: '0px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '-36px', right: '-26px'} : [2, 3].includes(venueData.venue_id) ? {top: '-24px', right: '-18px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '-14px', right: '-43px'} :[2, 3].includes(venueData.venue_id) ? {top: '-8px', right: '-29px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '14px', right: '-43px'} : [2, 3].includes(venueData.venue_id) ? {top: '11px', right: '-29px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '37px', right: '-26px'} : [2, 3].includes(venueData.venue_id) ? {top: '27px', right: '-18px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '44px', right: '0px'} : [2, 3].includes(venueData.venue_id) ? {top: '33px', right: '0px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '33px', left: '-26px'} : [2, 3].includes(venueData.venue_id) ? {top: '28px', left: '-17px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '14px', left: '-43px'} : [2, 3].includes(venueData.venue_id) ? {top: '12px', left: '-29px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '-14px', left: '-43px'} : [2, 3].includes(venueData.venue_id) ? {top: '-9px', left: '-29px'} : {display: 'none'}}>

                          </div>
                          <div className={venueData.venue_id == 1 ? "seat" : 'seat-venue-2'} style={venueData.venue_id == 1 ? {top: '-36px', left: '-26px'} : [2, 3].includes(venueData.venue_id) ? {top: '-25px', left: '-17px'} : {display: 'none'}}>

                          </div>
                        </div>
                        <p className="text-center" style={{fontSize: '12px', maxWidth: '100px'}}><strong dangerouslySetInnerHTML={{ __html: separateName(table.name)}} /></p>
                      </div>
                      :
                      <div
                        className=" box m-3 table-card react-draggable d-flex align-items-center justify-content-center react-draggable rounded"
                        style={{
                          border: '2px #1EC2F3 solid',
                          color: '#ffffff',
                          width: computedDimension(table.width) + "px",
                          height: computedDimension(table.length) + "px",
                          backgroundColor: '#1EC2F3',
                          transform: `translate(${table.position_x? table.position_x : 0}px, ${table.position_y? table.position_y : 0}px)`
                        }}>
                        <div>
                          <p className="text-center"><strong>{table.name}</strong></p>
                        </div>
                      </div>
                    }
                  </>
                ))}
              </div>
            </div>
          </Container>
        }
      </section>
    </>
  )
}

export default AdminFloorPlanPage;
